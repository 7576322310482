import {Page} from "./page/pages"

/**
 * Used when action comes from old Espace Beneficiaire, in order to know where the user will be redirected.
 */
export const Order = {
    CANCEL_ORDER: "CANCEL_ORDER",
    EDIT_ORDER: "EDIT_ORDER",
    NEW_ORDER: "NEW_ORDER",
    VIEW_ORDER: "VIEW_ORDER",
}

export const OrderActions = {
    [Order.CANCEL_ORDER]: {
        redirectionPage: Page.ORDER_CONFIRMATION,
    },
    [Order.EDIT_ORDER]: {
        redirectionPage: Page.EDIT_ORDER_PROFILE,
    },
    [Order.NEW_ORDER]: {
        redirectionPage: Page.ORDER_PROFILE,
    },
    [Order.VIEW_ORDER]: {
        redirectionPage: Page.VIEW_ORDER,
    },
}
