/* eslint-disable */
import {differenceBy} from "lodash"
import PropTypes from "prop-types"
import React, {Component} from "react"
import {connect} from "react-redux"
import * as Events from "react-scroll"
import {removeNotification} from "../../actions/notifications/notificationsActions"
import Notifications from "./notifications"

class NotificationsContainer extends Component {
    static propTypes = {
        /**
         * Selector function which take the redux state in parameter
         */
        getNotifications: PropTypes.func.isRequired,
        /**
         * Wether
         */
        displayNotifications: PropTypes.bool,
    }

    static defaultProps = {
        displayNotifications: true,
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        const difference = differenceBy(nextProps.notifications, this.props.notifications, "id")

        difference.forEach(notification => {
            if (notification.autoDismiss) {
                setTimeout(() => this.props.removeNotification(notification), notification.duration || 5000)
            }
        })

        // If there's a new notification, we scroll to top
        if (difference.length > 0) {
            Events.animateScroll.scrollToTop()
        }
    }

    render() {
        return (
            <Notifications
                displayNotifications={this.props.displayNotifications}
                notifications={this.props.notifications}
                onDismiss={this.props.removeNotification}
            />
        )
    }
}

const mapStateToProps = (state, ownProps) => ({notifications: ownProps.getNotifications(state)})

const mapDispatchToProps = dispatch => ({
    removeNotification: notification => dispatch(removeNotification(notification)),
})

export default connect(mapStateToProps, mapDispatchToProps)(NotificationsContainer)
