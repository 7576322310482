import PropTypes from "prop-types"
import React from "react"
import {Icon} from "semantic-ui-react"
import "./register.scss"
import {useTexts} from "../../hooks/useTexts"
import ButtonSecondary from "../ButtonSecondary/buttonSecondary"

const Register = ({redirectToRegistration}) => {
    const texts = useTexts()

    return (
        <div className="register">
            <p className="register-text">
                <Icon className="fa-solid fa-caret-right" />
                <b>{texts.sharedLoginParagraph3}</b>
                {texts.sharedLoginParagraph4}
            </p>

            <ButtonSecondary className="register-button" fluid onClick={redirectToRegistration}>
                {texts.sharedLoginRegistrationButton}
            </ButtonSecondary>
        </div>
    )
}

Register.propTypes = {
    redirectToRegistration: PropTypes.func.isRequired,
}

export default Register
