import {CHANGE_ACTUAL_PAGE} from "./pageActionTypes"

/**
 * Action to change actual page.
 */
export const changeActualPage = (actualPage, clearNotifications = true) => {
    return {
        type: CHANGE_ACTUAL_PAGE,
        actualPage,
        clearNotifications,
    }
}
