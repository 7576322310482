import {RequestInfo, RequestKeys} from "../../../constants/requests"
import {REQUEST_SUCCESS, REQUEST_FAILURE, CLEAR_REQUEST} from "../../requests/requestsReducer"
import {UPDATE_NOTIFICATION_FOR_FEATURE} from "../../../actions/localStorage/features/featuresActionsType"

/**
 * Save features in the store.
 */
const features = (state = [], action = {}) => {
    if (action.requestKeyName && action.requestKeyName === RequestInfo[RequestKeys.GET_ENABLED_FEATURES].name) {
        switch (action.type) {
            case REQUEST_SUCCESS:
                return action.payload
            case CLEAR_REQUEST:
            case REQUEST_FAILURE:
                return []
            default:
                return state
        }
    } else if (action.type === UPDATE_NOTIFICATION_FOR_FEATURE) {
        let newState = state.slice()
        newState
            .filter(feature => feature.id === action.feature)
            .forEach(feature => {
                feature.properties.notification = "false"
            })
        return newState
    } else {
        return state
    }
}

export default features

/**
 * Return the features array.
 */
export const getEnabledFeatures = state => state
