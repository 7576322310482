import {
    SAVE_ORDER_CONFIRMATION,
    RESET_ORDER_CONFIRMATION,
} from "../../../actions/localStorage/orderConfirmation/orderConfirmationActionTypes"

/**
 * Reducer to save orderConfirmation data in the localstorage
 * to retrieve them after the payment
 */
const orderConfirmation = (state = {}, action = {}) => {
    switch (action.type) {
        case SAVE_ORDER_CONFIRMATION:
            return {
                beneficiaryMail: action.beneficiaryMail,
                deliveryPointLabel: action.deliveryPointLabel,
                deliveryType: action.deliveryType,
                financeurMessage: action.financeurMessage,
                orderType: action.orderType,
                paymentMode: action.paymentMode,
                totalAccountAmount: action.totalAccountAmount,
                totalAccountFees: action.totalAccountFees,
                totalTitleAmount: action.totalTitleAmount,
                totalTitleFees: action.totalTitleFees,
            }
        case RESET_ORDER_CONFIRMATION:
            return {}
        default:
            return state
    }
}

export default orderConfirmation

export const getOrderConfirmation = state => state
