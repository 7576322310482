import React from "react"

import PropTypes from "prop-types"

import Message from "../Message"

import "./alert.scss"

/**
 * An alert based on https://react.semantic-ui.com/collections/message.
 */
const Alert = ({className, type, header, content, list, onDismiss, displayIcon}) => (
    <div className={!!className ? className + " alert-component" : "alert-component"}>
        <Message
            className="alert-component-message"
            content={content}
            floating
            header={header}
            list={list}
            onDismiss={onDismiss}
            displayIcon={displayIcon}
            type={type}
        />
    </div>
)

Alert.defaultProps = {
    className: null,
    content: null,
    displayIcon: false,
    header: null,
    list: null,
    onDismiss: null,
}

Alert.propTypes = {
    /**
     * An additional className.
     */
    className: PropTypes.string,
    /**
     * Content, can be a string or some components.
     */
    content: PropTypes.any,
    /**
     * Si vrai on affiche une icone représentant le type de message en début de header
     */
    displayIcon: PropTypes.bool,
    /**
     * The header text.
     */
    header: PropTypes.string,
    /**
     * Array shorthand items for the MessageList. Mutually exclusive with content.
     */
    list: PropTypes.any,
    /**
     * A function called when clicking on the "x" icon.
     */
    onDismiss: PropTypes.func,
    /**
     * The type of Alert.
     */
    type: PropTypes.oneOf(["success", "warning", "error", "info"]).isRequired,
}

export default Alert
