import {
    SAVE_CESU_CONVERSION_CONFIRMATION,
    RESET_CESU_CONVERSION_CONFIRMATION,
} from "../../../actions/localStorage/cesuConversionConfirmation/cesuConversionConfirmationActionTypes"

const cesuConversionConfirmation = (state = {}, action = {}) => {
    switch (action.type) {
        case SAVE_CESU_CONVERSION_CONFIRMATION:
            return action.data
        case RESET_CESU_CONVERSION_CONFIRMATION:
            return {}
        default:
            return state
    }
}

export default cesuConversionConfirmation

export const getCesuConversionConfirmation = state => state
