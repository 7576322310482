import {
    SAVE_ACCOUNT_PAYMENT_CONFIRMATION,
    RESET_ACCOUNT_PAYMENT_CONFIRMATION,
} from "../../../actions/localStorage/accountPaymentConfirmation/accountPaymentConfirmationActionTypes"

const accountPaymentConfirmation = (state = {}, action = {}) => {
    switch (action.type) {
        case SAVE_ACCOUNT_PAYMENT_CONFIRMATION:
            return action.values
        case RESET_ACCOUNT_PAYMENT_CONFIRMATION:
            return {}
        default:
            return state
    }
}

export default accountPaymentConfirmation

export const getAccountPaymentConfirmation = state => state
