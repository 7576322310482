import {schema} from "normalizr";

/**
 * Preorder sessions schemas.
 */
export const preOrderSessions = new schema.Entity("preOrderSessions");
export const arrayOfPreOrderSessions = [preOrderSessions];

/**
 * Preorder session lines schemas.
 */
export const preOrderSessionLines = new schema.Entity("preOrderSessionLines");
export const arrayOfPreOrderSessionLines = [preOrderSessionLines];
