import React from "react"
import {Grid, Loader} from "semantic-ui-react"
import "./bypassLogin.scss"

/**
 * This component appears when a user is bypassing a login.
 */
const BypassLogin = () => (
    <Grid centered>
        <Grid.Column only="computer" width={1} />
        <Grid.Column width={14}>
            <div className="bypass-login-margin">
                <Loader active inline="centered">
                    Connexion en cours ...
                </Loader>
            </div>
        </Grid.Column>
        <Grid.Column only="computer" width={1} />
    </Grid>
)

export default BypassLogin
