import {
    SAVE_MILLESIME_TRADE_CHOICE,
    RESET_MILLESIME_TRADE_CHOICE,
} from "../../../actions/localStorage/millesimeTradeChoice/millesimeTradeChoiceActionTypes"

const millesimeTradeChoice = (state = {}, action = {}) => {
    switch (action.type) {
        case SAVE_MILLESIME_TRADE_CHOICE:
            return action.formValues
        case RESET_MILLESIME_TRADE_CHOICE:
            return {}
        default:
            return state
    }
}

export default millesimeTradeChoice

export const getMillesimeTradeChoice = state => state
