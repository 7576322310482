import dayjs from "dayjs"
import {isArray, isEmpty, isNull, isString, isUndefined} from "lodash"
import {codesIbanMap} from "./iban"

const datePattern = "DD/MM/YYYY"
const dateTimePattern = "DD/MM/YYYY HH:mm"
const timePattern = "HH:mm"

/**
 * Retourne un message si la valeur ne fait pas partie des options
 */
export const requiredIn = options => value => options.includes(value) ? undefined : "Ce champ est requis."

/**
 * Return a message if a value is undefined or array empty
 * Use isUndefined from lodash to avoid 0 value taken as not defined !
 */
export const required = value =>
    !isUndefined(value) && !isNull(value) && value !== "" && (isArray(value) ? !isEmpty(value) : true)
        ? undefined
        : "Ce champ est requis."

/**
 * Return a message if a value is undefined and the condition is true.
 */
export const requiredIf = (value, condition) => (condition ? required(value) : undefined)

export const requiredIfTrue = condition => value => !!condition ? required(value) : undefined

export const requiredFieldGroup = (fieldNames, errorMessage) => (_, values) => {
    for (const fieldName of fieldNames) {
        if (!isEmpty(values[fieldName])) {
            return undefined
        }
    }
    return errorMessage
}

/**
 * Return a message if a value isn't superior to zero.
 */
export const positive = value => (value && value > 0 ? undefined : "Une valeur supérieure à zéro est requise.")

/**
 * Return a message if a value isn't true.
 */
export const mustBeTrue = message => value => value === true ? undefined : message

/**
 * Return a message if a value isn't an alphanumeric value.
 */
export const alphanumeric = value => (/^[a-z0-9]+$/i.test(value) ? undefined : "Ce champ est un champ alpha-numérique.")

/**
 * Return a message if a value contains special caracters .
 */
export const notSpecialCharacters = value =>
    /^[a-zA-Z0-9ÀÁÂÃÄÅÇÈÉÊËÌÍÎÏÒÓÔÕÖÙÚÛÜÝàáâãäåçèéêëìíîïðòóôõöùúûüýÿ .',]*$/.test(value)
        ? undefined
        : "Ce champ ne doit pas contenir de caractères spéciaux."

/**
 * Retourne un message si la valeur ne correspond pas à une adresse email valide
 */
const emailPattern =
    /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*)@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:+)\])$/

export const email = (value, text) => {
    // On vérifie si c'est une string, si oui (utilisation de la fonction autre que dans la propriété "validation" d'un Field Redux),
    // on affiche le message custom, sinon celui par défaut.
    const message = isString(text) ? text : "Adresse e-mail invalide."
    return value && !emailPattern.test(value) ? message : undefined
}

export const emailPatternValidation = message => value => value && !emailPattern.test(value) ? message : undefined

export const domiserveEmail = value => {
    if (!value) {
        return undefined
    }
    const splittedEmail = value.split("@")

    return splittedEmail[1] === "domiserve.com"
        ? undefined
        : "Veuillez saisir une adresse mail au format xxx@domiserve.com"
}

/**
 * Return a message if a value has a length superior to a threshold.
 * @param l the length threshold
 */
export const maxLength = l => value =>
    value && String(value).length > l ? "Ce champ a une longueur maximale de " + l + " caractères." : undefined
export const maxLength255 = maxLength(255)
export const maxLength160 = maxLength(160)
export const maxLength100 = maxLength(100)
export const maxLength55 = maxLength(55)
export const maxLength31 = maxLength(31)
export const maxLength26 = maxLength(26)
export const maxLength11 = maxLength(11)
export const maxLength10 = maxLength(10)
export const maxLength7 = maxLength(7)
export const maxLength4 = maxLength(4)

/**
 * Return a message if a value has a length inferior to a threshold.
 * @param l the length threshold
 */
export const minLength = l => value =>
    value && String(value).length < l ? "Ce champ a une longueur minimale de " + l + " caractères." : undefined
export const minLength10 = minLength(10)
export const minLength7 = minLength(7)
export const minLength4 = minLength(4)
export const minLength10PhoneNumber = value =>
    minLength10(value) ? "Veuillez saisir un numéro de téléphone comportant 10 chiffres." : undefined

/**
 * Retourne un message si le numéro de téléphone n'est pas valide (10 caractères et commence par un préfixe autorisé).
 * @param prefixOptions la liste des préfixes autorisés
 */
const phoneFormat = prefixOptions => value =>
    value && (!prefixOptions.some(prefixe => String(value).startsWith(prefixe)) || String(value).length !== 10)
        ? `Ce numéro de téléphone doit comporter 10 chiffres et commencer par ${prefixOptions.reduce(
              (res, value, index, array) => res + (index === array.length - 1 ? " ou " : ", ") + value,
          )}.`
        : undefined

/**
 * Retourne un message si le numéro de téléphone portable n'est pas valide.
 */
export const mobilePhoneFormat = phoneFormat(["06", "07"])

/**
 * Retourne un message si le numéro de téléphone fixe n'est pas valide.
 */
export const homePhoneFormat = phoneFormat(["01", "02", "03", "04", "05", "09"])

/**
 * Return a message if a value contains a comma.
 */
export const notContainsComma = value =>
    /^[^,]+$/i.test(value) ? undefined : "Ce champ ne doit pas contenir de virgules"

/**
 * Check a password score and return a message if its not secured
 * @param {Number} score
 */
export const validatePasswordScore = score => (score < 2 ? "Le mot de passe n'est pas assez sécurisé." : undefined)

/*****************************/
/* DATE VALIDATION           */
/*****************************/

/**
 * Return a message if a value isn't a date.
 */
export const date = value =>
    value && !dayjs(value, datePattern, true).isValid() ? "Date invalide (JJ/MM/AAAA attendu)." : undefined

export const dateValidationWithErrorMessage = errorMessage => value =>
    value && !dayjs(value, datePattern, true).isValid() ? errorMessage : undefined

/**
 * Return a message if value isn't a table of dates
 */
export const dateTable = value =>
    !(value && isArray(value) && !isEmpty(value) && value.every(date => dayjs(date, datePattern, true).isValid())) &&
    !!value
        ? "Date(s) invalides"
        : undefined

/**
 * Return a message if one of dates is after maxdate
 */
export const maxDateTable = (value, maxDate) => {
    if (!value || !maxDate) {
        return undefined
    }
    return value &&
        maxDate &&
        isArray(value) &&
        !isEmpty(value) &&
        !value.some(date => dayjs(date, datePattern, true).isAfter(dayjs(maxDate, datePattern, true)))
        ? undefined
        : `Les dates doivent être avant le ${maxDate}`
}

/**
 * Return a message if one of dates is before mindate
 */
export const minDateTable = (value, minDate) => {
    if (!value || !minDate) {
        return undefined
    }
    return value &&
        minDate &&
        isArray(value) &&
        !isEmpty(value) &&
        !value.some(date => dayjs(date, datePattern, true).isBefore(dayjs(minDate, datePattern, true)))
        ? undefined
        : `Les dates doivent être après le ${minDate}`
}

/**
 * Return a message if value isn't a time
 */
export const isTime = value =>
    value && !dayjs(value, timePattern, true).isValid() ? "Veuillez saisir un horaire au format HH:mm" : undefined

export const isTimeBefore = (max, message) => value => {
    return dayjs(value, "HH:mm").isBefore(dayjs(max, "HH:mm")) ? message : undefined
}

/**
 * Return a message if a value isn't a date time.
 */
export const dateTime = value =>
    value && !dayjs(value, dateTimePattern, true).isValid() ? "Date invalide (JJ/MM/AAAA HH:mm attendu)." : undefined

/**
 * Return a message if the value date is before a specified minDate
 */
export const minDate = (value, minDate) =>
    value && dayjs(value, datePattern).isBefore(minDate, "day")
        ? "La date doit être supérieure ou égale à " + minDate.format("DD/MM/YYYY")
        : undefined

/**
 * Return the specified message if the value is not after the date param
 */
export const isAfter = (value, date, message) => {
    return value && date && (dayjs(value, datePattern).isAfter(dayjs(date, datePattern), "day") ? undefined : message)
}

/**
 * Return the specified message if the value is the same of after the date param
 */
export const isSameOrAfter = (value, date, message) => {
    return (
        value &&
        date &&
        (dayjs(value, datePattern).isSameOrAfter(dayjs(date, datePattern), "day") ? undefined : message)
    )
}

export const isTodayOrAfter = value => {
    return !!value && !dayjs(value, datePattern).isSameOrAfter(dayjs().startOf("day"))
        ? "La date doit être supérieure à la date du jour"
        : undefined
}

/**
 * Return a message if the value is between the minDate and now (inclusive)
 */
export const isBetweenDateAndNow = (value, date) => {
    const minDate = dayjs(date, "DD/MM/YYYY")
    return dayjs(value, "DD/MM/YYYY").isBetween(minDate, dayjs(), "day", "[]")
        ? undefined
        : `La date doit être comprise entre le ${minDate.format("DD/MM/YYYY")} et aujourd'hui`
}

/**
 * Return a message if the value is not before now
 */
export const isUndefinedOrBeforeNow = date => {
    return date && !dayjs(date, "DD/MM/YYYY").isBefore(dayjs(), "day")
        ? "La date doit être inférieur à aujourd'hui"
        : undefined
}

export const isDaySameOrBeforeNow = value =>
    !!value && !dayjs(value, datePattern).isSameOrBefore(dayjs(), "day")
        ? "La date ne peut pas être postérieure à aujourd'hui"
        : undefined

/**
 * Return the specified message if the date value isn't older than a certain amount of years.
 */
export const isOlder = (nbYears, message) => value => {
    return value && (dayjs(value, datePattern).isBefore(dayjs().subtract(nbYears, "years")) ? undefined : message)
}

/*****************************/
/* NUMBER VALIDATION         */
/*****************************/

/**
 * Return a message if a value isn't a number.
 */
export const number = value => (value && isNaN(Number(value)) ? "Ce champ est un champ numérique." : undefined)

/**
 * Return a message if a value is inferior to a min value.
 * @param min the min value
 * @param message the message to display on error
 */
export const min = (min, message) => value => !isNaN(Number(value)) && Number(value) < Number(min) ? message : undefined
/**
 * Return a message if a value is superior to a max value.
 * @param max the max value
 * @param message the message to display on error
 */
export const valSup = (max, message) => value => {
    return !isNaN(Number(value)) && Number(value) > Number(max) ? message : undefined
}
/**
 * Return a message if a value is superior to a max value.
 * @param max the max value
 * @param message the message to display on error
 */
export const valSupEq = (max, message) => value => {
    return isNaN(Number(value)) || Number(value) <= Number(max) ? undefined : message
}

// Rule for checkbook facial value
export const minFacialValue = min(9, "La valeur faciale doit être supérieure à 9 euros")
export const maxFacialValue = valSupEq(99, "La valeur faciale doit être inférieure ou égale à 99 euros")

/**
 * Return a message if not multiple of value
 * @param {*} number the number to check
 * @param {*} message the message to display on error
 */
export const isMultipleOf = (number, message) => value =>
    !isNaN(Number(value)) && (((value * 100) % (number * 100)) / 100 === 0 ? undefined : message)

/**
 * Return a message if the value is not equal to the number specified
 * @param {*} number the number to check
 * @param {*} message the message to display on error
 */
export const isEqualTo = (number, message) => value => Number(value) === number ? undefined : message

/*****************************/
/* BANKING VALIDATION        */
/*****************************/

/**
 * Indique si l'IBAN fait bien parti de la zone SEPA
 * @param value : iban
 * @returns {boolean}
 */
export const isIbanSepa = value => {
    if (!!value) {
        let iban = value.toUpperCase()

        const country = iban.substring(0, 2)

        return country in codesIbanMap
    }
    return false
}
/**
 * Validation d'un IBAN avec l'algorithme MOD97
 * @param value : iban
 */
export const ibanValidation = value => {
    if (!!value) {
        let iban = value.toUpperCase()

        const country = iban.substring(0, 2)

        if (country in codesIbanMap) {
            // Déplace les 4 caractères à la fin de la chaîne
            iban = iban.concat(iban.substring(0, 4))
            iban = iban.substring(4, iban.length)

            // Remplace chaque lettre par deux chiffres, en suivant A = 10, B = 11, ..., Z = 35
            for (let i = 0; i < iban.length; i++) {
                // Check if the current char is a letter
                if (iban.charAt(i).match(/[a-z]/i)) {
                    let charAlphabeticalPosition = iban.charCodeAt(i) - 64
                    iban = iban.replace(new RegExp(iban.charAt(i), "g"), charAlphabeticalPosition + 9 + "")
                }
            }

            let remainder = iban,
                block

            // On itère car la fonction MOD ne fonctionne pas sur les gros nombres
            while (remainder.length > 2) {
                block = remainder.slice(0, 9)
                remainder = (parseInt(block, 10) % 97) + remainder.slice(block.length)
            }

            return parseInt(remainder, 10) % 97 === 1
        }
    }

    return false
}

/**
 * Return a message if a value isn't a correctly parsed IBAN.
 */
export const iban = value =>
    value && !/^[a-zA-Z]{2}[0-9]{2}[a-zA-Z0-9]{4}[0-9]{7}([a-zA-Z0-9]?){0,16}$/.test(value)
        ? "Le format de l'IBAN est incorrect."
        : undefined

/**
 * Return a message if a value isn't a correctly parsed BIC Code.
 */
export const bicCode = value =>
    value && !/^([a-zA-Z]{4}[a-zA-Z]{2}[a-zA-Z0-9]{2}([a-zA-Z0-9]{3})?)$/.test(value)
        ? "Le format du code BIC est incorrect."
        : undefined
