import {
    SAVE_ACCOUNT_PAYMENT_PAJE_DECLARATION,
    RESET_ACCOUNT_PAYMENT_PAJE_DECLARATION,
} from "../../../actions/localStorage/accountPaymentPajeDeclaration/accountPaymentPajeDeclarationActionTypes"

const accountPaymentPajeDeclaration = (state = {}, action = {}) => {
    switch (action.type) {
        case SAVE_ACCOUNT_PAYMENT_PAJE_DECLARATION:
            return action.values
        case RESET_ACCOUNT_PAYMENT_PAJE_DECLARATION:
            return {}
        default:
            return state
    }
}

export default accountPaymentPajeDeclaration

export const getAccountPaymentPajeDeclaration = state => state
