import {EDIT_ORDER_TYPE, RESET_ORDER_TYPE} from "../../../actions/localStorage/orderType/orderTypeActionTypes"

const orderType = (state = "", action = {}) => {
    switch (action.type) {
        case EDIT_ORDER_TYPE:
            return action.data
        case RESET_ORDER_TYPE:
            return ""
        default:
            return state
    }
}

export default orderType

export const getOrderType = state => state
