import PropTypes from "prop-types"
import React, {Component} from "react"
import {connect} from "react-redux"
import {withRouter} from "react-router-dom"
import {registerField} from "redux-form"
import {connectedUserInfo, connectUser} from "../../shared-components/actions/sessionStorage/user/userActions"
import Login from "../../shared-components/components/Login/login"
import {BENEFICIARY_AREA} from "../../shared-components/constants/areaTypes"
import {getAuthorityErrorMessage, isFetchingAuthority} from "../../store/selectors/sessionStorage/userSelectors"

/**
 * Container for Login form
 */
class LoginFormContainer extends Component {
    onConnectClick = formValues => {
        const {login, password} = formValues
        this.props.onConnectClick(login, password).then(
            () => this.props.getConnectedUserInfo(),
            () => {
                // We register fields again because authentication failure returns a 401 response code
                // but in configureAxios, on this response code, we reset store (Token expiration)
                // Without this, form validation works only one time.
                this.props.registerField("login")
                this.props.registerField("password")
            },
        )
    }

    render() {
        return (
            <Login
                errorMessage={this.props.errorMessage}
                isFetching={this.props.isFetching}
                links={this.props.links}
                onSubmit={this.onConnectClick}
            />
        )
    }
}

const mapStateToProps = state => ({
    isFetching: isFetchingAuthority(state),
    errorMessage: getAuthorityErrorMessage(state),
})

const mapDispatchToProps = dispatch => ({
    getConnectedUserInfo: () => dispatch(connectedUserInfo(BENEFICIARY_AREA)),
    onConnectClick: (login, password) => dispatch(connectUser(login, password, BENEFICIARY_AREA)),
    registerField: name => dispatch(registerField("loginForm", name, "Field")),
})

LoginFormContainer.propTypes = {
    errorMessage: PropTypes.string,
    isFetching: PropTypes.bool.isRequired,
    links: PropTypes.node,
    onConnectClick: PropTypes.func.isRequired,
    registerField: PropTypes.func.isRequired,
}

LoginFormContainer.defaultProps = {
    errorMessage: undefined,
    links: undefined,
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LoginFormContainer))
