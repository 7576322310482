import React, {Component} from "react"
import PropTypes from "prop-types"
import CheckBrowser from "./checkBrowser"
import bowser from "bowser"

class CheckBrowserContainer extends Component {
    static propTypes = {
        userAgent: PropTypes.string.isRequired,
    }

    constructor() {
        super()

        this.state = {
            isValidBrowser: true,
        }
    }

    componentDidMount() {
        this.isValidBrowser(this.props.userAgent)
    }

    isValidBrowser = userAgent => {
        const browser = bowser.getParser(userAgent)

        // We display notification when user uses internet explorer below 11 version
        let isValid = browser.satisfies({
            windows: {
                "internet explorer": ">10",
            },
        })

        // If satisfies returned undefined, browser is valid
        if (isValid === undefined) {
            isValid = true
        }
        this.setState({isValidBrowser: isValid})
    }

    render() {
        return <CheckBrowser isValidBrowser={this.state.isValidBrowser} />
    }
}

export default CheckBrowserContainer
