import {SAVE_REGISTRATION_CONTROL, RESET_REGISTRATION_CONTROL} from "./registrationControlActionTypes"

export const saveRegistrationControl = values => ({
    type: SAVE_REGISTRATION_CONTROL,
    values,
})

export const resetRegistrationControl = {
    type: RESET_REGISTRATION_CONTROL,
}
