/**
 * This file defines application names as constant
 */

/**
 * The beneficiary area application
 */
export const BENEFICIARY_AREA = "ESPACE_BENEFICIAIRE"
/**
 * The trade area application
 */
export const TRADE_AREA = "ESPACE_ECHANGE"
/**
 * The client area application
 */
export const CLIENT_AREA = "ESPACE_CLIENT"
/**
 * The intervenant area application
 */
export const INTERVENANT_AREA = "ESPACE_INTERVENANT"
/**
 * The servidom area application
 */
export const SERVIDOM_AREA = "SERVIDOM"
/**
 * The sos fastt agencies area application
 */
export const SOS_FASTT_AGENCIES_AREA = "FASTT SOS Garde d'enfants"
/**
 * The service beneficiary area application
 */
export const SERVICE_BENEFICIARY_AREA = "EBS"
