import {get, post} from "axios"
import {keys} from "lodash"
import {BENEFICIARY_ENDPOINTS} from "../../../constants/endpoints"
import HttpMethod from "../../../constants/httpMethods"
import {RequestInfo, RequestKeys} from "../../../constants/requests"
import {getErrorMessage} from "../../../services/api"
import {REQUEST, REQUEST_FAILURE, REQUEST_SUCCESS} from "../../../store/requests/requestsReducer"
import {genericThunk} from "../../actionsUtils"

/**
 * Retrieve the registration type of contract by id
 */

export const getRegistrationParameters = contractId => dispatch => {
    const url = BENEFICIARY_ENDPOINTS.getRegistrationParameters(contractId)

    const payloadHandler = response => ({
        allowProductTypeChoice: response.data.autoriserChoixTypeProduit,
        registrationType: response.data.typeInscription,
        socialReason: response.data.raisonSociale,
        contractType: response.data.typeContrat,
    })

    return genericThunk(
        undefined,
        HttpMethod.GET,
        dispatch,
        url,
        undefined,
        RequestInfo[RequestKeys.GET_REGISTRATION_PARAMETERS].name,
        payloadHandler,
    )
}

/**
 * Retrieve sessions by contract id
 */
export const getSessionsOfContract = contractId => dispatch => {
    dispatch({
        type: REQUEST,
        requestKeyName: RequestInfo[RequestKeys.GET_SESSIONS_OF_CONTRACT].name,
    })

    const params = {
        numeroContrat: contractId,
    }

    return get(BENEFICIARY_ENDPOINTS.getSessionsOfContract(contractId), {params}).then(
        response => {
            const payload = response.data.map(session => {
                return {
                    id: session.id,
                    label: session.libelle,
                    controlInfos: session.infoControles.map(controlInfo => {
                        return {label: controlInfo.libelle, code: controlInfo.code}
                    }),
                    additionalInfos: session.infosSupplementaires.map(additionalInfo => {
                        return {label: additionalInfo.libelle, code: additionalInfo.code}
                    }),
                }
            })

            dispatch({
                type: REQUEST_SUCCESS,
                requestKeyName: RequestInfo[RequestKeys.GET_SESSIONS_OF_CONTRACT].name,
                payload,
            })

            return Promise.resolve(payload)
        },
        error => {
            const errorMessage = getErrorMessage(error)
            dispatch({
                type: REQUEST_FAILURE,
                requestKeyName: RequestInfo[RequestKeys.GET_SESSIONS_OF_CONTRACT].name,
                message: errorMessage,
            })
            return Promise.reject(errorMessage)
        },
    )
}

const constructInformationsControl = controlInfos => {
    const informationsControles = []
    keys(controlInfos)
        .filter(
            key =>
                key !== "contactMail" &&
                key !== "contactMailConfirmation" &&
                key !== "mailOffers" &&
                key !== "postalOffersRefusal",
        )
        .forEach(key => {
            informationsControles.push({
                code: key,
                valeur: controlInfos[key],
            })
        })
    return informationsControles
}

/**
 * Check control infos for registration
 */
export const checkControlInfosForRegistration = (contractId, sessionId, controlInfos) => dispatch => {
    dispatch({
        type: REQUEST,
        requestKeyName: RequestInfo[RequestKeys.CHECK_CONTROL_INFORMATIONS_FOR_BENEFICIARY_REGISTRATION].name,
    })

    // Construct control infos
    const body = {
        informationsControles: constructInformationsControl(controlInfos),
    }

    return post(BENEFICIARY_ENDPOINTS.checkControlInformationsForRegistration(contractId, sessionId), body).then(
        response => {
            const payload = {
                civility: response.data.civilite,
                lastName: response.data.nom,
                firstName: response.data.prenom,
                birthDate: response.data.dateNaissance,
                zoneAddress0: response.data.zoneAdresse0,
                zoneAddress1: response.data.zoneAdresse1,
                zoneAddress2: response.data.zoneAdresse2,
                zoneAddress3: response.data.zoneAdresse3,
                postalCodeTown: response.data.codePostalEtVille,
                contactMail: response.data.emailContact,
                mainPhoneNumber: response.data.telephonePrincipal,
                secondaryPhoneNumber: response.data.telephoneSecondaire,
            }

            dispatch({
                type: REQUEST_SUCCESS,
                requestKeyName: RequestInfo[RequestKeys.CHECK_CONTROL_INFORMATIONS_FOR_BENEFICIARY_REGISTRATION].name,
                payload,
            })

            return Promise.resolve(payload)
        },
        error => {
            const message = getErrorMessage(error)
            dispatch({
                type: REQUEST_FAILURE,
                requestKeyName: RequestInfo[RequestKeys.CHECK_CONTROL_INFORMATIONS_FOR_BENEFICIARY_REGISTRATION].name,
                message,
            })
            return Promise.reject(message)
        },
    )
}

/**
 * Check if the user is already registered
 * @param {*} contractId the contract id
 * @param {*} sessionId the optional session id
 */
export const checkIfAlreadyRegistered =
    (contractId, sessionId, controlInfos, lastname, firstname, postalCodeTown, birthDate) => dispatch => {
        const url = BENEFICIARY_ENDPOINTS.checkIfAlreadyRegistered(contractId, sessionId)

        const body = {
            prenom: firstname,
            nom: lastname,
            dateNaissance: birthDate,
            codePostalEtVille: postalCodeTown,
            verifierInfoControles: {
                informationsControles: constructInformationsControl(controlInfos),
            },
        }

        const payloadHandler = response => ({
            isAlreadyRegisteredOnThisContract: response.data.dejaInscritSurCeContrat,
            isAlreadyRegisteredOnAnotherContract: response.data.dejaInscritSurUnAutreContrat,
        })

        return genericThunk(
            body,
            HttpMethod.POST,
            dispatch,
            url,
            undefined,
            RequestInfo[RequestKeys.CHECK_IF_ALREADY_REGISTERED].name,
            payloadHandler,
        )
    }

/**
 * Make a beneficiary registration
 */
export const makeBeneficiaryRegistration = values => dispatch => {
    dispatch({
        type: REQUEST,
        requestKeyName: RequestInfo[RequestKeys.MAKE_BENEFICIARY_REGISTRATION].name,
    })

    // Construct additional infos
    const additionalInfos = []
    keys(values.registrationProfile)
        .filter(key => key.startsWith("controlField"))
        .forEach(key => {
            additionalInfos.push({
                code: key.replace("controlField-", ""),
                valeur: values.registrationProfile[key],
            })
        })

    const body = {
        civilite: values.registrationProfile.civility,
        nom: values.registrationProfile.lastName,
        prenom: values.registrationProfile.firstName,
        dateNaissance: values.registrationProfile.birthDate,
        zoneAdresse0: values.registrationProfile.zoneAddress0,
        zoneAdresse1: values.registrationProfile.zoneAddress1,
        zoneAdresse2: values.registrationProfile.zoneAddress2,
        zoneAdresse3: values.registrationProfile.zoneAddress3,
        telephonePrincipal: values.registrationProfile.mainPhoneNumber,
        telephoneSecondaire: values.registrationProfile.secondaryPhoneNumber,
        codePostalEtVille: values.registrationProfile.postalCodeTown,
        offresMail: values.registrationProfile.mailOffers
            ? values.registrationProfile.mailOffers
            : values.registrationControl.mailOffers,
        verifierInfoControles: {
            informationsControles: constructInformationsControl(values.registrationControl),
        },
        infosSupplementaires: additionalInfos,
        nouveauMotDePasse: values.password,
        nouveauMotDePasseConfirmation: values.confirmPassword,
        emailContact: values.registrationProfile.contactMail
            ? values.registrationProfile.contactMail
            : values.registrationControl.contactMail,
        confirmationEmailContact: values.registrationProfile.contactMailConfirmation
            ? values.registrationProfile.contactMailConfirmation
            : values.registrationControl.contactMailConfirmation,
        choixTypeProduit: values.registrationProfile.productChoice,
    }

    const contractId = Number(values.contractId)
    const sessionId = Number(values.sessionId)

    return post(BENEFICIARY_ENDPOINTS.makeBeneficiaryRegistration(contractId, sessionId), body).then(
        response => {
            const data = {
                registrationSuccess: response.data.inscriptionReussie,
                firstRegistration: response.data.premiereInscription,
            }

            dispatch({
                type: REQUEST_SUCCESS,
                requestKeyName: RequestInfo[RequestKeys.MAKE_BENEFICIARY_REGISTRATION].name,
            })

            return Promise.resolve(data)
        },
        error => {
            const message = getErrorMessage(error)
            dispatch({
                type: REQUEST_FAILURE,
                requestKeyName: RequestInfo[RequestKeys.MAKE_BENEFICIARY_REGISTRATION].name,
                message,
            })
            return Promise.reject(message)
        },
    )
}
