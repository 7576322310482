import {
    SAVE_ACCOUNT_PAYMENT_ORGANIZATION,
    RESET_ACCOUNT_PAYMENT_ORGANIZATION,
} from "../../../actions/localStorage/accountPaymentOrganization/accountPaymentOrganizationActionTypes"

const accountPaymentOrganization = (state = {}, action = {}) => {
    switch (action.type) {
        case SAVE_ACCOUNT_PAYMENT_ORGANIZATION:
            return action.values
        case RESET_ACCOUNT_PAYMENT_ORGANIZATION:
            return {}
        default:
            return state
    }
}

export default accountPaymentOrganization

export const getAccountPaymentOrganization = state => state
