import * as fromLocalStorage from "../../localStorage/localStorageReducer"

/**
 * Return user selected visualisation mode
 */
export const getVisualisationMode = state => fromLocalStorage.getVisualisationMode(state.localStorage)
/**
 * Return user selected contract
 */
export const getSelectedContract = state => fromLocalStorage.getSelectedContract(state.localStorage)
