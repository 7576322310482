/**
 * The logo.
 */
export const LOGO = "logo.png"

/**
 * The home header.
 */
export const HOME_HEADER = "homeHeader.jpg"

/**
 * The home footer.
 */
export const HOME_FOOTER = "homeFooter.jpg"
