import * as fromLocalStorage from "../../localStorage/localStorageReducer"
import {isEmpty, keys, sortBy} from "lodash"

export const getPreOrderSession = state => fromLocalStorage.getPreOrderSession(state.localStorage)

/**
 * Return the preOrderSessionId
 */
export const getPreOrderSessionId = state => getPreOrderSession(state).id

/**
 * Return the preOrderSession delivery values
 */
export const getPreOrderSessionDeliveryValues = state => getPreOrderSession(state).deliveryValues

/**
 * Return the preOrderSession control values
 */
export const getPreOrderSessionControlValues = state => getPreOrderSession(state).controlValues

/**
 * Return the preOrderSession checkbook check's number restrictions
 */
export const getPreOrderSessionCheckbookCheckNumberRestrictions = state =>
    getPreOrderSession(state).checkbookCheckNumberRestrictions

/**
 * Return the preOrderSession communication values
 */
export const getPreOrderSessionCommunicationValues = state => getPreOrderSession(state).communicationValues

/**
 * Return the preOrderSession participation values
 */
export const getPreOrderSessionParticipationValues = state => getPreOrderSession(state).participationValues

/**
 * Return the preOrderSession delivery choices
 */
export const getPreOrderSessionDeliveryChoices = state => {
    const deliveryValues = getPreOrderSessionDeliveryValues(state)
    const deliveryChoices = {
        HOME_DELIVERY: deliveryValues ? deliveryValues.checkbookProductDeliveryAtHome : false,
        OTHER_POINT_DELIVERY: deliveryValues ? deliveryValues.checkbookProductDeliveryAtTierceAddress : false,
        DELIVERY_POINT_DELIVERY: deliveryValues ? deliveryValues.checkbookProductDeliveryAtDeliveryPoints : false,
    }
    return deliveryChoices
}

/**
 * Return the preOrderSession delivery points for a select list
 */
export const getPreOrderSessionDeliveryPointsForSelectValues = state => {
    const deliveryValues = getPreOrderSessionDeliveryValues(state)
    const deliveryPoints = []
    keys(deliveryValues)
        .filter(key => key.startsWith("delivery-point-") && key.endsWith("-id"))
        .forEach(key => {
            const id = key.replace("delivery-point-", "").replace("-id", "")
            deliveryPoints.push({
                key: id,
                text: deliveryValues["delivery-point-" + id + "-label"],
                value: id,
            })
        })
    return deliveryPoints
}

/**
 * Return the preOrderSession delivery point by id
 */
export const getPreOrderSessionDeliveryPointById = (state, id) => {
    const deliveryValues = getPreOrderSessionDeliveryValues(state)
    const deliveryPoint = {
        label: deliveryValues["delivery-point-" + id + "-label"],
        zoneAddress0: deliveryValues["delivery-point-" + id + "-recipient"],
        zoneAddress1: deliveryValues["delivery-point-" + id + "-recipientComplement"],
        zoneAddress2: deliveryValues["delivery-point-" + id + "-numberAndStreet"],
        zoneAddress3: deliveryValues["delivery-point-" + id + "-industrialZone"],
        postalCodeTown: deliveryValues["delivery-point-" + id + "-postalCodeTown"],
    }
    return deliveryPoint
}

/**
 * Tell if the user can order account
 */
export const canOrderAccount = state => !!getPreOrderSession(state).accountProduct

/**
 * Tell if the user can order checkbooks
 */
export const canOrderCheckbooks = state => !!getPreOrderSession(state).checkbookProduct

/**
 * Get the facial value of the session if its fixed
 */
export const getFacialValue = state => getPreOrderSession(state).facialValue

/**
 * Tell if we should control amount ordered for this session
 */
export const getControlAmount = state => getPreOrderSessionControlValues(state).controlAmount

/**
 * Return the preOrderSession checkbook order values if any
 */
export const getCheckbookOrderValues = state => {
    const checkbookCheckNumberRestrictions = getPreOrderSessionCheckbookCheckNumberRestrictions(state)
    const facialValue = getFacialValue(state)
    if (checkbookCheckNumberRestrictions) {
        let checkbookOrderValues = []
        checkbookCheckNumberRestrictions.forEach(number => {
            const value = Number(number) * Number(facialValue)
            checkbookOrderValues.push({
                key: "checkbookOrderValues-" + value,
                text: value,
                value,
            })
        })

        if (!isEmpty(checkbookOrderValues)) {
            checkbookOrderValues.push({
                key: "checkbookOrderValues-0",
                text: "0",
                value: 0,
            })
        }

        checkbookOrderValues = sortBy(checkbookOrderValues, ["value"])

        return checkbookOrderValues
    } else {
        return undefined
    }
}

/**
 * Tell if we should display the introduction message
 */
export const displayIntroductionMessage = state =>
    getPreOrderSessionCommunicationValues(state).displayIntroductionMessage

/**
 * Return the introduction message
 */
export const getIntroductionMessage = state => getPreOrderSessionCommunicationValues(state).introductionMailContent

/**
 * Return the preOrderSession contract id
 */
export const getPreOrderSessionContractId = state => getPreOrderSession(state).contractId

export const getPreOrderSessionConfirmationMailContent = state =>
    getPreOrderSession(state) ? getPreOrderSession(state).communicationValues.confirmationMailContent : ""
