import {
    SAVE_CESU_CONVERSION_AMOUNT,
    RESET_CESU_CONVERSION_AMOUNT,
} from "../../../actions/localStorage/cesuConversionAmount/cesuConversionAmountActionTypes"

const cesuConversionAmount = (state = {}, action = {}) => {
    switch (action.type) {
        case SAVE_CESU_CONVERSION_AMOUNT:
            return action.formValues
        case RESET_CESU_CONVERSION_AMOUNT:
            return {}
        default:
            return state
    }
}

export default cesuConversionAmount

export const getCesuConversionAmount = state => state
