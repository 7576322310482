import {Features} from "../../../shared-components/constants/features"
import * as fromLocalStorage from "../../localStorage/localStorageReducer"
import {isArray} from "lodash"

/**
 * Get enabled features from the store.
 */
export const getEnabledFeatures = state =>
    fromLocalStorage
        .getEnabledFeatures(state.localStorage)
        .map(feature => feature.id)

/**
 * Get the feature Features.CESU_RH_MESSAGE if enabled
 */
export const getCESURHMessage = state =>
    fromLocalStorage
        .getEnabledFeatures(state.localStorage)
        .filter(feature => feature.id === Features.CESU_RH_MESSAGE)[0]

/**
 * Get the properties notification for one feature
 */
export const getNotificationWithFeature = (state, featureNames) => {
    if (isArray(featureNames)) {
        for (const featureName of featureNames) {
            if (getNotificationWithFeature(state, featureName)) {
                return true
            }
        }
        return false
    } else {
        const feature = fromLocalStorage
            .getEnabledFeatures(state.localStorage)
            .filter(f => f.id === featureNames)[0]
        // Si la features contient une notification alors convert la notif en boolean (car récupéré en String depuis l'api)
        if (feature && feature.properties && feature.properties.notification) {
            return JSON.parse(feature.properties.notification)
        }
        return false
    }
}
