import {
    SAVE_ACCOUNT_PAYMENT_CHOICE,
    RESET_ACCOUNT_PAYMENT_CHOICE,
} from "../../../actions/localStorage/accountPaymentChoice/accountPaymentChoiceActionTypes"

const accountPaymentChoice = (state = {}, action = {}) => {
    switch (action.type) {
        case SAVE_ACCOUNT_PAYMENT_CHOICE:
            return action.values
        case RESET_ACCOUNT_PAYMENT_CHOICE:
            return {}
        default:
            return state
    }
}

export default accountPaymentChoice

export const getAccountPaymentChoice = state => state
