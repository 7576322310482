import {
    CLEAR_PREORDER_SESSION,
    PREORDER_SESSION_INFORMATION_SUCCESS,
} from "../../../actions/localStorage/preOrderSession/preOrderSessionActionTypes"

const preOrderSession = (state = {}, action = {}) => {
    switch (action.type) {
        case PREORDER_SESSION_INFORMATION_SUCCESS:
            return {
                id: action.id,
                contractId: action.contractId,
                label: action.label,
                accountProduct: action.accountProduct,
                checkbookProduct: action.checkbookProduct,
                customLabelAccountProduct: action.customLabelAccountProduct,
                customLabelCheckbookProduct: action.customLabelCheckbookProduct,
                beginDate: action.beginDate,
                endDate: action.endDate,
                permanent: action.permanent,
                facialValue: action.facialValue,
                serviceType: action.serviceType,
                checkbookCheckNumberRestrictions: action.checkbookCheckNumberRestrictions,
                participationValues: action.participationValues,
                controlValues: action.controlValues,
                fields: action.fields,
                deliveryValues: action.deliveryValues,
                communicationValues: action.communicationValues,
            }
        case CLEAR_PREORDER_SESSION:
            return {id: {}}
        default:
            return state
    }
}

export default preOrderSession

export const getPreOrderSession = state => state
