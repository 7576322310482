import {
    SAVE_REGISTRATION_SESSION_CHOICE,
    RESET_REGISTRATION_SESSION_CHOICE,
    SET_CONTROL_STEP_DISPLAYING,
    SET_SESSION_STEP_DISPLAYING,
} from "../../../actions/localStorage/registrationSessionChoice/registrationSessionChoiceActionTypes"

const registrationSessionChoice = (state = {}, action = {}) => {
    switch (action.type) {
        case SAVE_REGISTRATION_SESSION_CHOICE:
            return {...state, ...action.values}
        case SET_CONTROL_STEP_DISPLAYING:
            return {...state, displayControlStep: action.displayControlStep}
        case SET_SESSION_STEP_DISPLAYING:
            return {...state, displaySessionStep: action.displaySessionStep}
        case RESET_REGISTRATION_SESSION_CHOICE:
            return {}
        default:
            return state
    }
}

export default registrationSessionChoice

export const getRegistrationSessionChoice = state => state
