import {withRouter} from "react-router-dom"
import LoginScene from "./login"
import {connect} from "react-redux"
import {DOMISERVE_CONTACT_URL} from "../../shared-components/constants/links"
import {Page} from "../../constants/page/pages"
import {PagesArchitecture} from "../../constants/page/pagesArchitecture"
import {changeActualPage} from "../../shared-components/actions/temporaries/page/pageActions"
import React, {Component} from "react"
import {
    DYN_CONF_KEY_CONTRACT_ID,
    DYN_CONF_KEY_BENEFICIARY_REGISTRATION_CONTRACT_TYPE,
    DYN_CONF_KEY_BENEFICIARY_REGISTRATION_TYPE,
} from "../../shared-components/constants/dynamicConfigurationKeys"
import {getDynamicConfigurationValue} from "../../store/selectors/localStorage/dynamicConfigurationSelectors"
import {
    saveDynamicConfiguration,
    clearDynamicConfiguration,
} from "../../shared-components/actions/localStorage/dynamicConfiguration/dynamicConfigurationActions"
import {getRegistrationParameters} from "../../shared-components/actions/entities/beneficiaries/beneficiariesRegistrationActions"
import {isFetching} from "../../store/selectors/requests/requestsSelectors"
import {RequestInfo, RequestKeys} from "../../shared-components/constants/requests"
import {resetRegistrationSessionChoice} from "../../actions/localStorage/registrationSessionChoice/registrationSessionChoiceActions"
import {resetRegistrationControl} from "../../actions/localStorage/registrationControl/registrationControlActions"
import {resetRegistrationProfile} from "../../actions/localStorage/registrationProfile/registrationProfileActions"
import {resetRegistrationConfirmation} from "../../actions/localStorage/registrationConfirmation/registrationConfirmationActions"

class LoginSceneContainer extends Component {
    componentDidMount() {
        this.props.updateActualPage()

        // If user is comming from an registration url
        if (this.props.location.pathname === PagesArchitecture[Page.REGISTRATION].route) {
            // Get search and remove '?'
            const search = this.props.location.search.substring(1)
            // Convert the string into an object for parsing
            const params = new URLSearchParams(search)

            // Read expected params
            const contractId = params.get("numeroContrat")

            // Redirect on login if the param is not present
            if (!contractId) {
                this.props.history.push(PagesArchitecture[Page.LOGIN].route)
                return
            }

            this.props.getRegistrationParameters(contractId).then(
                response => {
                    this.props.saveDynamicConfiguration(
                        DYN_CONF_KEY_BENEFICIARY_REGISTRATION_TYPE,
                        response.registrationType,
                    )

                    this.props.saveDynamicConfiguration(
                        DYN_CONF_KEY_BENEFICIARY_REGISTRATION_CONTRACT_TYPE,
                        response.contractType,
                    )

                    // If user indicate an other contract id, we save it and reset every steps in localStorage
                    if (this.props.contractId !== contractId) {
                        this.props.saveDynamicConfiguration(DYN_CONF_KEY_CONTRACT_ID, contractId)

                        this.props.resetRegistration()
                    }
                },
                () => {
                    // If there's an error and beneficiary registration type is defined it, we clear dynamic configuration
                    if (this.props.beneficiaryRegistrationType) {
                        this.props.clearDynamicConfiguration()
                    }
                },
            )
        }
    }

    onCreateAccountClick = () => {
        this.props.history.push(PagesArchitecture[Page.REGISTRATION_SESSION_CHOICE].route)
    }

    render() {
        // If pathname is registration, contract id and beneficiary registration type is set
        const displayRegistrationPart =
            this.props.location.pathname === PagesArchitecture[Page.REGISTRATION].route &&
            !!this.props.contractId &&
            !!this.props.beneficiaryRegistrationType

        return (
            <LoginScene
                displayRegistrationPart={displayRegistrationPart}
                isFetching={this.props.isFetching}
                onCreateAccountClick={this.onCreateAccountClick}
                {...this.props}
            />
        )
    }
}

const mapStateToProps = state => ({
    beneficiaryRegistrationType: getDynamicConfigurationValue(state, DYN_CONF_KEY_BENEFICIARY_REGISTRATION_TYPE),
    contractId: getDynamicConfigurationValue(state, DYN_CONF_KEY_CONTRACT_ID),
    isFetching: isFetching(state, RequestInfo[RequestKeys.GET_REGISTRATION_PARAMETERS].name),
})

const mapDispatchToProps = (dispatch, props) => ({
    clearDynamicConfiguration: () => dispatch(clearDynamicConfiguration()),
    getRegistrationParameters: contractId => dispatch(getRegistrationParameters(contractId)),
    onContactUsClick: () => {
        window.open(DOMISERVE_CONTACT_URL)
    },
    onNeedHelpClick: () => {
        props.history.push(PagesArchitecture[Page.RECOVER_LOGIN_PASSWORD].route)
    },
    resetRegistration: () => {
        dispatch(resetRegistrationSessionChoice)
        dispatch(resetRegistrationControl)
        dispatch(resetRegistrationProfile)
        dispatch(resetRegistrationConfirmation)
    },
    saveDynamicConfiguration: (key, contractId) => {
        dispatch(saveDynamicConfiguration(key, contractId))
    },
    updateActualPage: () => dispatch(changeActualPage(Page.LOGIN)),
})

LoginSceneContainer = withRouter(connect(mapStateToProps, mapDispatchToProps)(LoginSceneContainer))

export default LoginSceneContainer
