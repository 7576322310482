import PropTypes from "prop-types"
import React from "react"
import "./notificationsLayout.scss"
import sizes from "../../styles/_sizes.module.scss"

/**
 * Layout permettant d'afficher les notifications uniformément sur les différentes applications
 * La propriété top permet de définir l'espacement entre les notification et le haut de l'écran
 */
const NotificationsLayout = ({top, children}) => {
    return (
        <div className="notifications-layout" style={{top}}>
            {children}
        </div>
    )
}

NotificationsLayout.defaultProps = {
    top: sizes.spacing13,
}

NotificationsLayout.propTypes = {
    children: PropTypes.node.isRequired,
    top: PropTypes.string,
}

export default NotificationsLayout
