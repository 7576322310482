import PropTypes from "prop-types"
import React from "react"
import {Link} from "react-router-dom/cjs/react-router-dom.min"
import "./footerNew.scss"
import colors from "../../../styles/_colors.module.scss"

const Footer = ({backgroundColor, items, textColor}) => {
    return (
        <div className="footer" style={{backgroundColor}}>
            {items.map(item => (
                <Link className="footer-link" onClick={item.onClick} style={{color: textColor}} to={item.route}>
                    {item.label}
                </Link>
            ))}
        </div>
    )
}

Footer.propTypes = {
    backgroundColor: PropTypes.string,
    items: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string.isRequired,
            onClick: PropTypes.func,
            page: PropTypes.string,
            route: PropTypes.string.isRequired,
        }),
    ).isRequired,
    textColor: PropTypes.string,
}

Footer.defaultProps = {
    backgroundColor: colors.footerBackgroundColor,
    textColor: colors.footerTextColor,
}

export default Footer
