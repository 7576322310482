import {
    SAVE_ACCOUNT_PAYMENT_CESU_DECLARATION,
    RESET_ACCOUNT_PAYMENT_CESU_DECLARATION,
} from "../../../actions/localStorage/accountPaymentCesuDeclaration/accountPaymentCesuDeclarationActionTypes"

const accountPaymentCesuDeclaration = (state = {}, action = {}) => {
    switch (action.type) {
        case SAVE_ACCOUNT_PAYMENT_CESU_DECLARATION:
            return action.values
        case RESET_ACCOUNT_PAYMENT_CESU_DECLARATION:
            return {}
        default:
            return state
    }
}

export default accountPaymentCesuDeclaration

export const getAccountPaymentCesuDeclaration = state => state
