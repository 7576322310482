import {REQUEST_SUCCESS} from "../../../shared-components/store/requests/requestsReducer"
import {RequestInfo, RequestKeys} from "../../../shared-components/constants/requests"

const contracts = (state = {}, action = {}) => {
    if (
        action.type === REQUEST_SUCCESS &&
        action.requestKeyName === RequestInfo[RequestKeys.GET_BENEFICIARY_CONTRACTS].name
    ) {
        return action.payload
    }
    return state
}

export default contracts

export const getContracts = state => state
