import PropTypes from "prop-types"
import React from "react"
import {Grid} from "semantic-ui-react"
import "./lightHeader.scss"
import colors from "../../../styles/_colors.module.scss"

/**
 * Un header minimaliste avec 3 éléments
 */
const LightHeader = ({backgroundColor, centerItem, height, leftItem, rightItem, style}) => {
    return (
        <header className="light-header" style={{backgroundColor, height, minHeight: height, ...style}}>
            <Grid large>
                <Grid.Row>
                    <Grid.Column width={3}>{leftItem}</Grid.Column>
                    <Grid.Column width={10}>{centerItem}</Grid.Column>
                    <Grid.Column width={3}>{rightItem}</Grid.Column>
                </Grid.Row>
            </Grid>
        </header>
    )
}

LightHeader.propTypes = {
    backgroundColor: PropTypes.string,
    centerItem: PropTypes.node,
    height: PropTypes.string,
    leftItem: PropTypes.node,
    rightItem: PropTypes.node,
    style: PropTypes.object,
}

LightHeader.defaultProps = {
    backgroundColor: colors.white,
    centerItem: <span></span>,
    height: "auto",
    leftItem: <span></span>,
    rightItem: <span></span>,
    style: {},
}

export default LightHeader
