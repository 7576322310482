import {
    SAVE_ACCOUNT_PAYMENT_CESU_IDENTIFICATION,
    RESET_ACCOUNT_PAYMENT_CESU_IDENTIFICATION,
} from "../../../actions/localStorage/accountPaymentCesuIdentification/accountPaymentCesuIdentificationActionTypes"

const accountPaymentCesuIdentification = (state = {}, action = {}) => {
    switch (action.type) {
        case SAVE_ACCOUNT_PAYMENT_CESU_IDENTIFICATION:
            return action.values
        case RESET_ACCOUNT_PAYMENT_CESU_IDENTIFICATION:
            return {}
        default:
            return state
    }
}

export default accountPaymentCesuIdentification

export const getAccountPaymentCesuIdentification = state => state
