import React, {Component} from "react"
import {connect} from "react-redux"
import {withRouter} from "react-router-dom"
import {saveDynamicConfiguration} from "../../actions/localStorage/dynamicConfiguration/dynamicConfigurationActions"
import {DYN_CONF_KEY_CONTRACT_ID} from "../../constants/dynamicConfigurationKeys"

/**
 * Initialize the dynamic configuration.
 */
class ContractConfigurationContainer extends Component {
    componentDidMount() {
        // Store the contract ID if it is in the URL.
        if (this.props.match.params.contractId) {
            this.props.saveDynamicConfiguration(DYN_CONF_KEY_CONTRACT_ID, this.props.match.params.contractId)
        }
    }

    render() {
        return <>{this.props.children}</>
    }
}

ContractConfigurationContainer = withRouter(connect(null, {saveDynamicConfiguration})(ContractConfigurationContainer))

export default ContractConfigurationContainer
