import {get, post} from "axios"
import dayjs from "dayjs"
import {isEmpty, keys, omit} from "lodash"
import {normalize} from "normalizr"
import uuidv4 from "uuid"
import {
    BENEFICIARY_ENDPOINTS,
    PREORDER_SESSION_CREATE_UPDATE_ENDPOINT,
    PREORDER_SESSION_ENDPOINTS,
} from "../../../constants/endpoints"
import {prettyPreorderLine} from "../../../constants/orderLineStatuses"
import {OrderStatusKeys} from "../../../constants/orderStatuses"
import {RequestInfo, RequestKeys} from "../../../constants/requests"
import {getErrorMessage} from "../../../services/api"
import {fromNumberToEuros} from "../../../services/currency"
import {getPageNumber} from "../../../services/pagination"
import {buildUrlSearchParams} from "../../../services/urlSearchParams"
import {REQUEST, REQUEST_FAILURE, REQUEST_SUCCESS} from "../../../store/requests/requestsReducer"
import {
    CLEAR_PREORDER_SESSION_LINES_PAGINATION,
    CLEAR_PREORDER_SESSION_LINES_SEARCH_PARAMS,
    GET_PREORDER_SESSIONS_FAILURE,
    GET_PREORDER_SESSIONS_MATRICULE_FAILURE,
    GET_PREORDER_SESSIONS_MATRICULE_REQUEST,
    GET_PREORDER_SESSIONS_MATRICULE_SUCCESS,
    GET_PREORDER_SESSIONS_REQUEST,
    GET_PREORDER_SESSIONS_SUCCESS,
    GET_PREORDER_SESSION_LINES_FAILURE,
    GET_PREORDER_SESSION_LINES_REQUEST,
    GET_PREORDER_SESSION_LINES_SUCCESS,
    GET_PREORDER_SESSION_ORDERS_FAILURE,
    GET_PREORDER_SESSION_ORDERS_REQUEST,
    GET_PREORDER_SESSION_ORDERS_SUCCESS,
    GET_PREORDER_SESSION_STATISTICS_FAILURE,
    GET_PREORDER_SESSION_STATISTICS_REQUEST,
    GET_PREORDER_SESSION_STATISTICS_SUCCESS,
    PREORDER_SESSION_CLOSE_FAILURE,
    PREORDER_SESSION_CLOSE_REQUEST,
    PREORDER_SESSION_CLOSE_SUCCESS,
    PREORDER_SESSION_CREATE_ORDER_FAILURE,
    PREORDER_SESSION_CREATE_ORDER_REQUEST,
    PREORDER_SESSION_CREATE_ORDER_SUCCESS,
    PREORDER_SESSION_CREATE_UPDATE_FAILURE,
    PREORDER_SESSION_CREATE_UPDATE_REQUEST,
    PREORDER_SESSION_CREATE_UPDATE_SUCCESS,
    PREORDER_SESSION_INFORMATION_FAILURE,
    PREORDER_SESSION_INFORMATION_REQUEST,
    PREORDER_SESSION_INFORMATION_SUCCESS,
    PREORDER_SESSION_UPDATE_LINES_FAILURE,
    PREORDER_SESSION_UPDATE_LINES_REQUEST,
    PREORDER_SESSION_UPDATE_LINES_SUCCESS,
    PREORDER_SESSION_VALIDATE_ALL_WAITING_LINES_FAILURE,
    PREORDER_SESSION_VALIDATE_ALL_WAITING_LINES_REQUEST,
    PREORDER_SESSION_VALIDATE_ALL_WAITING_LINES_SUCCESS,
    SAVE_PREORDER_SESSION_LINES_SEARCH_PARAMS,
    VALIDATE_CONTROL_FILE_FAILURE,
    VALIDATE_CONTROL_FILE_REQUEST,
    VALIDATE_CONTROL_FILE_SUCCESS,
} from "./preOrderSessionsActionTypes"
import * as schema from "./preOrderSessionsSchema"

/**
 * Used to sort field in table
 */
const preOrderSessionLineBackEndField = {
    amount: "montant",
    creationDate: "dateCreation",
    personalCategory: "categoriePersonnel",
    endingDate: "dateFin",
    startingDate: "dateDebut",
    name: "nom",
    firstName: "prenom",
    id: "id",
    matricule: "matricule",
    startingAmount: "montantDebut",
    endingAmount: "montantFin",
    product: "produit",
    status: "statut",
}

/**
 * Map accessor to back end
 */
export const getBackEndField = accessor => preOrderSessionLineBackEndField[accessor]

/**
 * Action to retrieve all preorder sessions.
 */
export const retrievePreOrderSessions =
    (contractId, withOrders = false) =>
    dispatch => {
        dispatch({
            type: GET_PREORDER_SESSIONS_REQUEST,
        })

        const params = {
            avecCommande: withOrders,
        }

        return get(PREORDER_SESSION_ENDPOINTS.getAllPreOrderSessions(contractId), {
            params,
        }).then(
            response => {
                dispatch({
                    type: GET_PREORDER_SESSIONS_SUCCESS,
                    response: normalize(
                        response.data.map(pOS => {
                            const now = dayjs()
                            const openingDate = pOS.dateOuverture ? dayjs(pOS.dateOuverture, "DD/MM/YYYY") : null
                            const closingDate = pOS.dateFermeture ? dayjs(pOS.dateFermeture, "DD/MM/YYYY") : null
                            const cancellingDate = pOS.dateAnnulation ? dayjs(pOS.dateAnnulation, "DD/MM/YYYY") : null

                            let visible = "Oui"
                            if (
                                cancellingDate ||
                                (!now.isSame(openingDate, "day") &&
                                    (now.isBefore(openingDate) ||
                                        (!now.isSame(closingDate, "day") && now.isAfter(closingDate))))
                            ) {
                                visible = "Non"
                            }

                            return {
                                id: pOS.id,
                                wording: pOS.libelle,
                                products:
                                    (!!pOS.produitTitre ? pOS.produitTitre : "") +
                                    (!!pOS.produitTitre && !!pOS.produitCompte ? ", " : "") +
                                    (!!pOS.produitCompte ? pOS.produitCompte : ""),
                                sessionGroup: pOS.nomGroupeSession ? pOS.nomGroupeSession : "",
                                visible,
                                closedText: pOS.statut !== OrderStatusKeys.COMMANDE_ANNULEE ? "Non" : "Oui",
                                isOpened: pOS.statut !== OrderStatusKeys.COMMANDE_ANNULEE,
                                isClosed: pOS.statut === OrderStatusKeys.COMMANDE_ANNULEE,
                                isAfterOpeningDate: now.isAfter(openingDate),
                            }
                        }),
                        schema.arrayOfPreOrderSessions,
                    ),
                })

                return Promise.resolve()
            },
            error => {
                dispatch({
                    type: GET_PREORDER_SESSIONS_FAILURE,
                    message: getErrorMessage(error),
                })
                return Promise.reject()
            },
        )
    }

/**
 * Action to validate a control file
 */
export const validateControlFile = (contractNumber, fileElementId) => dispatch => {
    dispatch({
        type: VALIDATE_CONTROL_FILE_REQUEST,
    })

    const data = new FormData()
    const file = document.getElementById(fileElementId).files[0]

    data.append("controlFile", file, file.name)

    return post(PREORDER_SESSION_ENDPOINTS.validationControleFile(contractNumber), data).then(
        response => {
            const payload = {
                data: {
                    displayControlAmount: response.data.afficherControlerMontant,
                    valid: response.data.valide,
                    fields: response.data.champs,
                    filePath: response.data.cheminFichier,
                    invalidReason: response.data.raisonInvalidite,
                },
            }
            dispatch({
                type: VALIDATE_CONTROL_FILE_SUCCESS,
                ...payload.data,
            })
            return Promise.resolve(payload)
        },
        error => {
            dispatch({
                type: VALIDATE_CONTROL_FILE_FAILURE,
                message: getErrorMessage(error),
            })
            return Promise.reject()
        },
    )
}
/**
 * Action to retrieve all details about a preorder session.
 */
export const getPreOrderSessionDetails = sessionId => dispatch => {
    dispatch({
        type: REQUEST,
        requestKeyName: RequestInfo[RequestKeys.GET_PREORDER_SESSION_DETAILS].name,
    })

    return get(PREORDER_SESSION_ENDPOINTS.getPreOrderSessionDetails(sessionId)).then(
        response => {
            const data = {
                id: response.data.id,
                wording: response.data.libelle,
                sessionGroupName: response.data.nomGroupeSession,
                openingDate: response.data.dateOuverture,
                closingDate: response.data.dateFermeture,
                cancellingDate: response.data.dateAnnulation,
                permanent: response.data.estPermanente,
                titleProduct: response.data.produitTitre,
                accountProduct: response.data.produitCompte,
                status: response.data.statut,
                isOpened: response.data.statut !== OrderStatusKeys.COMMANDE_ANNULEE,
                isClosed: response.data.statut === OrderStatusKeys.COMMANDE_ANNULEE,
                nbValidatedLines: response.data.nbLignesValidees,
                nbWaitingLines: response.data.nbLignesEnAttentes,
                controlFileLinesNumber: response.data.nombreLignesFichierControle,
            }

            dispatch({
                type: REQUEST_SUCCESS,
                payload: data,
                requestKeyName: RequestInfo[RequestKeys.GET_PREORDER_SESSION_DETAILS].name,
            })

            return Promise.resolve()
        },
        error => {
            dispatch({
                type: REQUEST_FAILURE,
                message: getErrorMessage(error),
                requestKeyName: RequestInfo[RequestKeys.GET_PREORDER_SESSION_DETAILS].name,
            })
            return Promise.reject()
        },
    )
}

/**
 * Thunk to check if a preorder session has its matricule activated.
 * @param sessionId the session ID
 */
export const checkMatricule = sessionId => dispatch => {
    dispatch({
        type: GET_PREORDER_SESSIONS_MATRICULE_REQUEST,
    })

    return get(PREORDER_SESSION_ENDPOINTS.getPreOrderSessionMatricule(sessionId)).then(
        response => {
            const newRes = {
                value: response.data.valeur,
            }

            dispatch({
                type: GET_PREORDER_SESSIONS_MATRICULE_SUCCESS,
                hasMatriculeActivated: newRes.value,
            })

            return Promise.resolve(newRes)
        },
        error => {
            dispatch({
                type: GET_PREORDER_SESSIONS_MATRICULE_FAILURE,
                message: getErrorMessage(error),
            })
            return Promise.reject()
        },
    )
}

/**
 * Thunk to close a preorder session.
 * @param sessionId the session ID
 */
export const closeSession = sessionId => dispatch => {
    dispatch({
        type: PREORDER_SESSION_CLOSE_REQUEST,
    })

    return post(PREORDER_SESSION_ENDPOINTS.closePreOrderSession(sessionId)).then(
        response => {
            dispatch({
                type: PREORDER_SESSION_CLOSE_SUCCESS,
            })

            return Promise.resolve()
        },
        error => {
            dispatch({
                type: PREORDER_SESSION_CLOSE_FAILURE,
                message: getErrorMessage(error),
            })
            return Promise.reject()
        },
    )
}

/**
 * Thunk to validate all waiting lines
 * @param sessionId the session ID
 */
export const validateAllWaitingLines = sessionId => dispatch => {
    dispatch({
        type: PREORDER_SESSION_VALIDATE_ALL_WAITING_LINES_REQUEST,
    })

    return post(PREORDER_SESSION_ENDPOINTS.validatePreOrderAllWaitingLines(sessionId)).then(
        response => {
            dispatch({
                type: PREORDER_SESSION_VALIDATE_ALL_WAITING_LINES_SUCCESS,
            })

            return Promise.resolve()
        },
        error => {
            dispatch({
                type: PREORDER_SESSION_VALIDATE_ALL_WAITING_LINES_FAILURE,
                message: getErrorMessage(error),
            })
            return Promise.reject()
        },
    )
}

/**
 * Thunk to update lines
 * @param sessionId the session ID
 * @param linesId lines to update
 * @param status status to set
 */
export const updateLines = (sessionId, linesId, status) => dispatch => {
    dispatch({
        type: PREORDER_SESSION_UPDATE_LINES_REQUEST,
    })

    const body = {
        idsLignes: linesId,
        statut: status,
    }

    return post(PREORDER_SESSION_ENDPOINTS.updatePreOrderSessionLines(sessionId), body).then(
        response => {
            dispatch({
                type: PREORDER_SESSION_UPDATE_LINES_SUCCESS,
            })

            return Promise.resolve()
        },
        error => {
            dispatch({
                type: PREORDER_SESSION_UPDATE_LINES_FAILURE,
                message: getErrorMessage(error),
            })
            return Promise.reject()
        },
    )
}

/**
 * Thunk to create an order
 * @param sessionId the session ID
 * @param linesId lines for the order
 */
export const createOrderForSession = (sessionId, linesId) => dispatch => {
    dispatch({
        type: PREORDER_SESSION_CREATE_ORDER_REQUEST,
    })

    return post(PREORDER_SESSION_ENDPOINTS.createPreOrderSessionOrder(sessionId), linesId).then(
        response => {
            dispatch({
                type: PREORDER_SESSION_CREATE_ORDER_SUCCESS,
            })

            return Promise.resolve()
        },
        error => {
            dispatch({
                type: PREORDER_SESSION_CREATE_ORDER_FAILURE,
                message: getErrorMessage(error),
            })
            return Promise.reject()
        },
    )
}

/**
 * Thunk to retrieve preorder session lines with search.
 * Dispatch on request and on success or failure.
 */
export const retrievePreOrderSessionLinesWithSearch =
    (sessionId, page, sortedField, sortDirection, limit = 10, searchFormValues = {}) =>
    dispatch => {
        dispatch({
            type: GET_PREORDER_SESSION_LINES_REQUEST,
        })

        const searchParams = [
            {
                fieldName: getBackEndField("personalCategory"),
                value: searchFormValues.personalCategory,
            },
            {
                fieldName: getBackEndField("startingDate"),
                value: searchFormValues.startingDate
                    ? dayjs(searchFormValues.startingDate, "DD/MM/YYYY").format("DD/MM/YYYY HH:mm:ss")
                    : searchFormValues.startingDate,
            },
            {
                fieldName: getBackEndField("endingDate"),
                value: searchFormValues.endingDate
                    ? dayjs(searchFormValues.endingDate, "DD/MM/YYYY").endOf("day").format("DD/MM/YYYY HH:mm:ss")
                    : searchFormValues.endingDate,
            },
            {fieldName: getBackEndField("name"), value: searchFormValues.name},
            {
                fieldName: getBackEndField("firstName"),
                value: searchFormValues.firstName,
            },
            {
                fieldName: getBackEndField("startingAmount"),
                value: searchFormValues.startingAmount,
            },
            {
                fieldName: getBackEndField("endingAmount"),
                value: searchFormValues.endingAmount,
            },
            {fieldName: getBackEndField("product"), value: searchFormValues.product},
            {fieldName: getBackEndField("status"), value: searchFormValues.activeState},
        ]

        const params = {
            page,
            tri: sortedField + "," + sortDirection,
            taille: limit,
            recherche: buildUrlSearchParams(searchParams),
        }

        return get(PREORDER_SESSION_ENDPOINTS.getPreOrderSessionLines(sessionId), {
            params,
        }).then(
            response => {
                const pagination = omit(response.data, ["content"])
                dispatch({
                    type: GET_PREORDER_SESSION_LINES_SUCCESS,
                    response: normalize(
                        response.data.content.map(l => ({
                            id: l.id,
                            name: l.nom,
                            firstName: l.prenom,
                            benefId: l.benefId,
                            personalCategory: l.categoriePersonnel,
                            creationDate: l.dateCreation,
                            matricule: l.matricule,
                            amount: fromNumberToEuros(l.montant),
                            status: prettyPreorderLine(l.statut),
                            product: l.produit,
                        })),
                        schema.arrayOfPreOrderSessionLines,
                    ),
                    pagination: {
                        ...pagination,
                        number: getPageNumber(pagination.number),
                    },
                })
                return Promise.resolve()
            },
            error => {
                dispatch({
                    type: GET_PREORDER_SESSION_LINES_FAILURE,
                    message: getErrorMessage(error),
                })
                return Promise.reject()
            },
        )
    }

/**
 * Action to retrieve all statistics about a preorder session.
 */
export const getPreOrderSessionStatistics = sessionId => dispatch => {
    dispatch({
        type: GET_PREORDER_SESSION_STATISTICS_REQUEST,
        sessionId,
    })

    return get(PREORDER_SESSION_ENDPOINTS.getPreOrderSessionStatistics(sessionId)).then(
        response => {
            dispatch({
                type: GET_PREORDER_SESSION_STATISTICS_SUCCESS,
                response: response.data.map(stat => ({
                    status: stat.statut,
                    quantity: stat.volume,
                    amount: stat.montant,
                    clientAmount: stat.partFinanceur,
                    benefAmount: stat.partBeneficiaire,
                })),
                sessionId,
            })

            return Promise.resolve()
        },
        error => {
            dispatch({
                type: GET_PREORDER_SESSION_STATISTICS_FAILURE,
                message: getErrorMessage(error),
                sessionId,
            })
            return Promise.reject()
        },
    )
}

/**
 * Action to retrieve all orders linked to a preorder session.
 */
export const getPreOrderSessionOrders = sessionId => dispatch => {
    dispatch({
        type: GET_PREORDER_SESSION_ORDERS_REQUEST,
    })

    return get(PREORDER_SESSION_ENDPOINTS.getPreOrderSessionOrders(sessionId)).then(
        response => {
            const data = response.data

            dispatch({
                type: GET_PREORDER_SESSION_ORDERS_SUCCESS,
                response: {
                    titleQuantity: data.volumeTitre,
                    titleTotalAmount: data.montantTitre,
                    titleClientAmount: data.partFinanceurTitre,
                    titleBenefAmount: data.partBeneficiaireTitre,
                    accountQuantity: data.volumeCompte,
                    accountTotalAmount: data.montantCompte,
                    accountClientAmount: data.partFinanceurCompte,
                    accountBenefAmount: data.partBeneficiaireCompte,
                },
                sessionId,
            })

            return Promise.resolve()
        },
        error => {
            dispatch({
                type: GET_PREORDER_SESSION_ORDERS_FAILURE,
                message: getErrorMessage(error),
            })
            return Promise.reject()
        },
    )
}

/**
 * Action to create or update a preOrderSession
 * @params
 */
export const createOrUpdatePreOrderSession =
    (
        initValues,
        participationValues,
        controlValues,
        controlFields,
        controlFilePath,
        deliveryValues,
        communicationValues,
        contractId,
    ) =>
    dispatch => {
        dispatch({
            type: PREORDER_SESSION_CREATE_UPDATE_REQUEST,
        })

        // Extract ids from participationValues
        const ids = []
        keys(participationValues).forEach(key => {
            if (key.startsWith("participation-card") && key.endsWith("-staffCategory")) {
                const id = key.replace("participation-card-", "").replace("-staffCategory", "")
                ids.push(id)
            }
        })

        const quotas = []

        // Construct Quotas from participationValues
        ids.forEach(id => {
            const isUnique = participationValues["participation-card-" + id + "-modParticipation"] === "unique"

            const slices = []
            // If the participation card mod is not "Unique"
            // Extract sliceIds and construct TrancheTauxLigneCmd
            if (!isUnique) {
                const sliceIds = []
                const sliceForm = "participation-card-" + id + "-slice-"
                keys(participationValues).forEach(key => {
                    if (key.startsWith(sliceForm) && key.endsWith("-min")) {
                        const sliceId = key.replace(sliceForm, "").replace("-min", "")
                        sliceIds.push(sliceId)
                    }
                })

                sliceIds.forEach(sliceId => {
                    slices.push({
                        min: participationValues[sliceForm + sliceId + "-min"],
                        max: participationValues[sliceForm + sliceId + "-max"],
                        tauxParticipation: participationValues[sliceForm + sliceId + "-participation"],
                    })
                })
            }

            quotas.push({
                categoriePersonnel: participationValues["participation-card-" + id + "-staffCategory"],
                libelleNouvelleCategoriePersonnel:
                    participationValues["participation-card-" + id + "-newStaffCategoryLabel"],
                nouvelleCategoriePersonnel: participationValues["participation-card-" + id + "-newStaffCategory"],
                maxParticipation: participationValues["participation-card-" + id + "-maxParticipation"],
                maxCESU: participationValues["participation-card-" + id + "-maxPreOrder"],
                tauxParticipation: isUnique
                    ? participationValues["participation-card-" + id + "-rateParticipation"]
                    : null,
                tranches: isUnique ? [] : slices,
            })
        })

        // Construct infosControles from controlValues
        const infoControles = []
        if (controlValues && controlValues.controlFields) {
            Object.keys(controlFields).forEach(controlFieldFromList => {
                let added = false
                controlValues.controlFields.forEach(controlFieldSelected => {
                    if (controlFieldFromList === controlFieldSelected) {
                        infoControles.push({
                            code: controlFieldSelected,
                            libelle: controlFields[controlFieldSelected],
                            selectionnee: true,
                        })
                        added = true
                    }
                })
                if (!added) {
                    infoControles.push({
                        code: controlFieldFromList,
                        libelle: controlFields[controlFieldFromList],
                        selectionnee: false,
                    })
                }
            })
        }
        // Construct additionalInfos from controlValues
        const additionalInfos = []
        keys(controlValues).forEach(key => {
            if (key.startsWith("complementary-information") && key.endsWith("-code")) {
                const infoId = key.replace("complementary-information-", "").replace("-code", "")
                additionalInfos.push({
                    code: controlValues["complementary-information-" + infoId + "-code"],
                    libelle: controlValues["complementary-information-" + infoId + "-label"],
                    idControle: controlValues["complementary-information-" + infoId + "-controlId"],
                })
            }
        })

        // Construct deliveryPoints from deliveryValues
        const deliveryPoints = []
        keys(deliveryValues).forEach(key => {
            if (key.startsWith("delivery-point") && key.endsWith("-label") && deliveryValues[key] !== null) {
                const deliveryId = key.replace("delivery-point-", "").replace("-label", "")

                deliveryPoints.push({
                    id: deliveryValues["delivery-point-" + deliveryId + "-id"],
                    libelle: deliveryValues["delivery-point-" + deliveryId + "-label"],
                    codePostalEtVille: deliveryValues["delivery-point-" + deliveryId + "-postalCodeTown"],
                    destinataire: deliveryValues["delivery-point-" + deliveryId + "-recipient"],
                    complementAdresse: deliveryValues["delivery-point-" + deliveryId + "-recipientComplement"],
                    numeroEtRue: deliveryValues["delivery-point-" + deliveryId + "-numberAndStreet"],
                    zoneIndustrielle: deliveryValues["delivery-point-" + deliveryId + "-industrialZone"],
                })
            }
        })

        const body = {
            idProduitCompte: initValues.accountProduct,
            infosSupplementaires: additionalInfos,
            restrictionsValeursTitres: initValues.checkbookCheckNumberRestrictions
                ? initValues.checkbookCheckNumberRestrictions
                : [],
            idProduitTitre: initValues.checkbookProduct,
            dateFermeture: initValues.permanent ? null : initValues.endDate,
            contenuMailFermetureSession: communicationValues.closingSessionMailContent,
            contenuMailConfirmation: communicationValues.confirmationMailContent,
            idContrat: contractId,
            controlerMontant: controlValues ? controlValues.controlAmount : undefined,
            cheminFichierControle: isEmpty(controlFilePath) ? "" : controlFilePath,
            libelleProduitComptePersonnalise: initValues.customLabelAccountProduct,
            libelleProduitTitrePersonnalise: initValues.customLabelCheckbookProduct,
            jourAvantMailDeFermeture: communicationValues.daysBeforeClosingMail,
            pointLivraison: deliveryValues.checkbookProductDeliveryAtDeliveryPoints,
            pointsDeLivraison: deliveryPoints,
            afficherMessageConfirmation: communicationValues.displayConfirmationMessage,
            afficherMessageIntroduction: communicationValues.displayIntroductionMessage,
            livraisonDomicile: deliveryValues.checkbookProductDeliveryAtHome,
            id: initValues.id,
            valeurFacialeImposee: initValues.facialValue,
            infoControles,
            contenuMailIntroduction: communicationValues.introductionMailContent,
            libelle: initValues.label,
            nouveauLibelleGroupeSession: participationValues.newSessionGroupLabel,
            dateOuverture: initValues.beginDate,
            contenuMailOuvertureSession: communicationValues.openingSessionMailContent,
            autreAdresse: deliveryValues.checkbookProductDeliveryAtTierceAddress,
            permanent: initValues.permanent,
            quotas,
            idRefPrestationLimitation: initValues.serviceType,
            idGroupeSession: participationValues.sessionGroup,
            envoyerMailFermetureAvantDateFermeture: communicationValues.sendMailBeforeClosingDate,
            envoyerMailOuverture: communicationValues.sendOpeningMail,
            demanderInfosSupplementaires: controlValues ? controlValues.askAdditionalInfo : undefined,
            infoControleIdentificatrice: controlValues ? controlValues.controlFieldIdentifier : undefined,
        }

        return post(PREORDER_SESSION_CREATE_UPDATE_ENDPOINT, body).then(
            response => {
                const payload = {
                    controlFileLinesInsertedNumber: response.data.lignesFichierControleInserees,
                }

                dispatch({
                    type: PREORDER_SESSION_CREATE_UPDATE_SUCCESS,
                })

                return Promise.resolve(payload)
            },
            error => {
                dispatch({
                    type: PREORDER_SESSION_CREATE_UPDATE_FAILURE,
                    message: getErrorMessage(error),
                })
                return Promise.reject()
            },
        )
    }

/**
 * Retrieve information of session
 * @param  {[number]} sessionId the preorder session id
 * @param  {[boolean]} duplicate true if you want information for duplicate, false otherwise
 */
export const getInformation = (sessionId, duplicate) => dispatch => {
    dispatch({
        type: PREORDER_SESSION_INFORMATION_REQUEST,
    })

    return get(PREORDER_SESSION_ENDPOINTS.getPreOrderSessionInformation(sessionId)).then(
        response => {
            let participationValues = {
                preOrderParticipationBy: response.data.idGroupeSession ? "sessionGroup" : "session",
                sessionGroup: response.data.idGroupeSession,
            }

            response.data.quotas.forEach(quota => {
                const id = uuidv4()
                const form = "participation-card-" + id + "-"
                participationValues[form + "staffCategory"] = quota.categoriePersonnel
                participationValues[form + "maxParticipation"] = quota.maxParticipation
                participationValues[form + "maxPreOrder"] = quota.maxCESU
                if (quota.tranches.length === 0) {
                    participationValues[form + "modParticipation"] = "unique"
                    participationValues[form + "rateParticipation"] = quota.tauxParticipation
                } else {
                    participationValues[form + "modParticipation"] = "slice"

                    quota.tranches.forEach(slice => {
                        const sliceId = uuidv4()
                        participationValues[form + "slice-" + sliceId + "-min"] = slice.min
                        participationValues[form + "slice-" + sliceId + "-max"] = slice.max
                        participationValues[form + "slice-" + sliceId + "-participation"] = slice.tauxParticipation
                    })
                }
            })

            let controlValues = {
                askAdditionalInfo: response.data.demanderInfosSupplementaires,
                controlAmount: response.data.controlerMontant,
                controlAmountAvailable: response.data.controlerMontantDisponible,
                controlFieldIdentifier: response.data.infoControleIdentificatrice,
            }

            let fields = {}
            if (response.data.infoControles) {
                controlValues.controlFields = response.data.infoControles
                    .filter(infoControle => infoControle.selectionnee)
                    .map(infoControle => infoControle.code)
                response.data.infoControles.forEach(controlInfo => {
                    fields[controlInfo.code] = controlInfo.libelle
                })
            }

            response.data.infosSupplementaires.forEach(additionalInfo => {
                const id = uuidv4()
                const form = "complementary-information-" + id + "-"
                controlValues[form + "code"] = additionalInfo.code
                controlValues[form + "label"] = additionalInfo.libelle
                controlValues[form + "controlId"] = additionalInfo.idControle
            })

            let deliveryValues = {
                checkbookProductDeliveryAtHome: response.data.livraisonDomicile,
                checkbookProductDeliveryAtTierceAddress: response.data.autreAdresse,
                checkbookProductDeliveryAtDeliveryPoints: response.data.pointLivraison,
            }

            if (response.data.pointsDeLivraison) {
                response.data.pointsDeLivraison.forEach(deliveryPoint => {
                    const form = "delivery-point-" + deliveryPoint.id + "-"
                    deliveryValues[form + "id"] = !duplicate ? deliveryPoint.id : undefined
                    deliveryValues[form + "label"] = deliveryPoint.libelle
                    deliveryValues[form + "postalCodeTown"] = deliveryPoint.codePostalEtVille
                    deliveryValues[form + "recipient"] = deliveryPoint.destinataire
                    deliveryValues[form + "recipientComplement"] = deliveryPoint.complementAdresse
                    deliveryValues[form + "numberAndStreet"] = deliveryPoint.numeroEtRue
                    deliveryValues[form + "industrialZone"] = deliveryPoint.zoneIndustrielle
                })
            }

            const communicationValues = {
                confirmationMailContent: response.data.contenuMailConfirmation
                    ? response.data.contenuMailConfirmation
                    : "",
                introductionMailContent: response.data.contenuMailIntroduction
                    ? response.data.contenuMailIntroduction
                    : "",
                openingSessionMailContent: response.data.contenuMailOuvertureSession
                    ? response.data.contenuMailOuvertureSession
                    : "",
                closingSessionMailContent: response.data.contenuMailFermetureSession
                    ? response.data.contenuMailFermetureSession
                    : "",
                daysBeforeClosingMail: response.data.jourAvantMailDeFermeture,
                displayConfirmationMessage: response.data.afficherMessageConfirmation,
                displayIntroductionMessage: response.data.afficherMessageIntroduction,
                sendMailBeforeClosingDate: response.data.envoyerMailFermetureAvantDateFermeture,
                sendOpeningMail: response.data.envoyerMailOuverture,
            }

            dispatch({
                type: PREORDER_SESSION_INFORMATION_SUCCESS,
                id: !duplicate ? response.data.id : undefined,
                contractId: response.data.idContrat,
                label: response.data.libelle,
                accountProduct: response.data.idProduitCompte,
                checkbookProduct: response.data.idProduitTitre,
                customLabelAccountProduct: response.data.libelleProduitComptePersonnalise,
                customLabelCheckbookProduct: response.data.libelleProduitTitrePersonnalise,
                beginDate: response.data.dateOuverture,
                endDate: response.data.dateFermeture,
                permanent: response.data.permanent,
                facialValue: response.data.valeurFacialeImposee
                    ? response.data.valeurFacialeImposee.toString()
                    : undefined,
                serviceType: response.data.idRefPrestationLimitation,
                checkbookCheckNumberRestrictions: response.data.restrictionsValeursTitres.map(r => r.toString()),
                participationValues,
                controlValues: !duplicate ? controlValues : null,
                fields,
                deliveryValues,
                communicationValues,
            })

            return Promise.resolve({
                displayIntroductionMessage: communicationValues.displayIntroductionMessage,
                introductionMessage: communicationValues.introductionMailContent,
            })
        },
        error => {
            dispatch({
                type: PREORDER_SESSION_INFORMATION_FAILURE,
                message: getErrorMessage(error),
            })
            return Promise.reject()
        },
    )
}

/**
 * Thunk launched in order to get an overview of the orders of authenticated beneficiary that are linked to a session.
 * @param preOrderSessionId
 */
export const getBeneficiaryOrderOverviewFromSession = preOrderSessionId => dispatch => {
    dispatch({
        type: REQUEST,
        requestKeyName: RequestInfo[RequestKeys.GET_BENEFICIARY_ORDER_OVERVIEW_FROM_SESSION].name,
    })

    return get(BENEFICIARY_ENDPOINTS.getOrderOverviewFromSession(preOrderSessionId)).then(
        response => {
            const payload = {
                beneficiaryPart: response.data.partBeneficiaire,
                commandType: response.data.typeCommande,
                fundedPart: response.data.partFinancee,
                millesime: response.data.millesime,
                orderDate: response.data.dateCommande,
                orderAccountAmount: response.data.montantCompteCommande,
                orderCause: response.data.motifCommande,
                orderTitleAmount: response.data.montantTitreCommande,
                orderTotalAmount: response.data.montantTotalCommande,
                titleFacialValue: response.data.valeurFacialeTitre,
                titleNumber: response.data.nombreTitres,
            }

            dispatch({
                type: REQUEST_SUCCESS,
                requestKeyName: RequestInfo[RequestKeys.GET_BENEFICIARY_ORDER_OVERVIEW_FROM_SESSION].name,
                payload,
            })

            return Promise.resolve()
        },
        error => {
            dispatch({
                type: REQUEST_FAILURE,
                requestKeyName: RequestInfo[RequestKeys.GET_BENEFICIARY_ORDER_OVERVIEW_FROM_SESSION].name,
                message: getErrorMessage(error),
            })

            return Promise.reject()
        },
    )
}

/**
 * Thunk launched in order to get informations of a potential beneficiary order linked to the session.
 * @param preOrderSessionId
 */
export const getBeneficiaryPreOrderInformationsFromSession = preOrderSessionId => dispatch => {
    dispatch({
        type: REQUEST,
        requestKeyName: RequestInfo[RequestKeys.GET_BENEFICIARY_ORDER_INFORMATIONS_FROM_SESSION].name,
    })

    return get(BENEFICIARY_ENDPOINTS.getOrderInformationsFromSession(preOrderSessionId)).then(
        response => {
            const payload = {
                preOrderAmounts: {
                    totalAmount: response.data.montantsPreCommande.montantTotal,
                    checkbookAmount: response.data.montantsPreCommande.montantTitre,
                    facialValue: response.data.montantsPreCommande.valeurFaciale,
                    accountAmount: response.data.montantsPreCommande.montantCompte,
                    preOrderCause: response.data.montantsPreCommande.motifPreCommande,
                    generalConditions: response.data.montantsPreCommande.conditionsGenerales,
                },
                preOrderDelivery: {
                    deliveryType: response.data.livraisonPreCommande.typeLivraison,
                    deliveryPointId: response.data.livraisonPreCommande.pointLivraisonId,
                    zoneAddress0: response.data.livraisonPreCommande.zoneAdresse0,
                    zoneAddress1: response.data.livraisonPreCommande.zoneAdresse1,
                    zoneAddress2: response.data.livraisonPreCommande.zoneAdresse2,
                    zoneAddress3: response.data.livraisonPreCommande.zoneAdresse3,
                    postalCodeTown: response.data.livraisonPreCommande.codePostalEtVille,
                },
                preOrderPayment: {
                    paymentMode: response.data.modePaiementPreCommande.modePaiement,
                },
            }

            dispatch({
                type: REQUEST_SUCCESS,
                requestKeyName: RequestInfo[RequestKeys.GET_BENEFICIARY_ORDER_INFORMATIONS_FROM_SESSION].name,
                payload,
            })

            return Promise.resolve(payload)
        },
        error => {
            const message = getErrorMessage(error)
            dispatch({
                type: REQUEST_FAILURE,
                requestKeyName: RequestInfo[RequestKeys.GET_BENEFICIARY_ORDER_INFORMATIONS_FROM_SESSION].name,
                message,
            })

            return Promise.reject(message)
        },
    )
}

/**
 * Action to save values of params for preorder session lines search
 * @returns {{type: string}}
 */
export const savePreOrderSessionLinesSearchParams = formValues => {
    return {
        type: SAVE_PREORDER_SESSION_LINES_SEARCH_PARAMS,
        formValues,
    }
}

/**
 * Action to clear all values of params for preorder session lines search
 * @returns {{type: string}}
 */
export const clearPreOrderSessionLinesSearchParams = () => {
    return {
        type: CLEAR_PREORDER_SESSION_LINES_SEARCH_PARAMS,
    }
}

/**
 * Action to clear pagination for preorder session lines table
 * @returns {{type: string}}
 */
export const clearPreOrderSessionLinesPagination = () => {
    return {
        type: CLEAR_PREORDER_SESSION_LINES_PAGINATION,
    }
}
