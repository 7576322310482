/* eslint-disable */
import PropTypes from "prop-types"
import React from "react"
import {Form, Input, Label} from "semantic-ui-react"
import "./semanticFormField.scss"

/**
 * Encapsulate a semantic form field into a component to use it in redux-form.
 */
const SemanticFormField = ({
    as: As = Input,
    autoFocus,
    checked,
    disabled,
    fieldClassName,
    htmlLabel,
    inline,
    input,
    label,
    meta: {touched, error, warning},
    placeholder,
    pointing,
    transparent,
    type,
    width,
    ...props
}) => {
    const handleChange = (e, {value}) => {
        return input.onChange(value)
    }

    input.disabled = disabled
    input["data-testid"] = input.name

    return (
        <Form.Field
            className={
                (fieldClassName ? fieldClassName : "") +
                (!!input.value ? " semantic-form-field-filled" : "") +
                (!!props.size ? ` ${props.size}` : "")
            }
            error={!!(touched && error)}
            inline={inline ? true : false}
            width={width}
        >
            {htmlLabel}
            <As
                {...props}
                {...input}
                autoFocus={autoFocus}
                checked={checked !== undefined ? checked : typeof input.value === "boolean" ? input.value : undefined}
                className={(props.className ? props.className : "") + (transparent ? " transparent" : "")}
                error={!!(touched && error)}
                label={label}
                onChange={handleChange}
                placeholder={placeholder}
                type={type}
                value={typeof input.value !== "boolean" ? input.value : undefined}
            />
            {touched &&
                ((error && (
                    <Label basic className="semantic-form-field-label error" color="red" pointing={pointing}>
                        {error}
                    </Label>
                )) ||
                    (warning && (
                        <Label basic className="semantic-form-field-label warning" color="orange" pointing={pointing}>
                            {warning}
                        </Label>
                    )))}
        </Form.Field>
    )
}

SemanticFormField.defaultProps = {
    pointing: true,
    transparent: false,
}

SemanticFormField.propTypes = {
    as: PropTypes.any,
    autoFocus: PropTypes.bool,
    dataTestId: PropTypes.string,
    disabled: PropTypes.bool,
    fieldClassName: PropTypes.string,
    htmlLabel: PropTypes.element,
    inline: PropTypes.bool,
    input: PropTypes.object,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    meta: PropTypes.object,
    placeholder: PropTypes.string,
    pointing: PropTypes.oneOf(["below", "left", "right", true, false]),
    type: PropTypes.string,
    transparent: PropTypes.bool,
    value: PropTypes.any,
    width: PropTypes.number,
}

export default SemanticFormField
