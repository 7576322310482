import {EDIT_PAYMENT, RESET_PAYMENT} from "./orderPaymentActionTypes"

export const editPayment = formValues => {
    return {
        type: EDIT_PAYMENT,
        data: formValues,
    }
}

export const resetPayment = {
    type: RESET_PAYMENT,
}
