import {SAVE_REGISTRATION_CONFIRMATION, RESET_REGISTRATION_CONFIRMATION} from "./registrationConfirmationActionTypes"

export const saveRegistrationConfirmation = values => ({
    type: SAVE_REGISTRATION_CONFIRMATION,
    values,
})

export const resetRegistrationConfirmation = {
    type: RESET_REGISTRATION_CONFIRMATION,
}
