import {get} from "axios"
import {FEATURES_ENDPOINTS} from "../../../constants/endpoints"
import {REQUEST, REQUEST_SUCCESS, REQUEST_FAILURE} from "../../../store/requests/requestsReducer"
import {getErrorMessage} from "../../../services/api"
import {RequestInfo, RequestKeys} from "../../../constants/requests"
import {UPDATE_NOTIFICATION_FOR_FEATURE} from "./featuresActionsType"

/**
 * Get enabled features for the connected beneficiary and an option contractId
 */
export const getBeneficiaryEnabledFeatures = contractId => dispatch => {
    dispatch({
        type: REQUEST,
        requestKeyName: RequestInfo[RequestKeys.GET_ENABLED_FEATURES].name,
    })

    const params = {
        numeroContrat: contractId,
    }

    return get(FEATURES_ENDPOINTS.getBeneficiaryEnabledFeatures, {params}).then(
        response => {
            dispatch({
                type: REQUEST_SUCCESS,
                requestKeyName: RequestInfo[RequestKeys.GET_ENABLED_FEATURES].name,
                payload: response.data.map(feature => ({
                    id: feature.id,
                    properties: feature.proprietes,
                })),
            })

            return Promise.resolve()
        },
        error => {
            dispatch({
                type: REQUEST_FAILURE,
                requestKeyName: RequestInfo[RequestKeys.GET_ENABLED_FEATURES].name,
                message: getErrorMessage(error),
            })

            return Promise.reject()
        },
    )
}

export const updateNotificationForFeature = feature => ({
    type: UPDATE_NOTIFICATION_FOR_FEATURE,
    feature,
})
