import {
    SAVE_CESU_CONVERSION_CHOICE,
    RESET_CESU_CONVERSION_CHOICE,
} from "../../../actions/localStorage/cesuConversionChoice/cesuConversionChoiceActionTypes"

const cesuConversionChoice = (state = {}, action = {}) => {
    switch (action.type) {
        case SAVE_CESU_CONVERSION_CHOICE:
            return {
                productChoice: action.productChoice,
            }
        case RESET_CESU_CONVERSION_CHOICE:
            return {}
        default:
            return state
    }
}

export default cesuConversionChoice

export const getProductChoice = state => state.productChoice
