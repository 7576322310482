import {
    SAVE_CHECK_PAYMENT_CHOICE,
    RESET_CHECK_PAYMENT_CHOICE,
} from "../../../actions/localStorage/checkPaymentChoice/checkPaymentChoiceActionTypes"

const checkPaymentChoice = (state = {}, action = {}) => {
    switch (action.type) {
        case SAVE_CHECK_PAYMENT_CHOICE:
            return action.values
        case RESET_CHECK_PAYMENT_CHOICE:
            return {}
        default:
            return state
    }
}

export default checkPaymentChoice

export const getCheckPaymentChoice = state => state
