import React, {useState} from "react"
import {Field} from "redux-form"
import {Input, Popup, Icon} from "semantic-ui-react"
import {PropTypes} from "prop-types"
import SemanticFormField from "../SemanticFormField/semanticFormField"

/**
 * A form password component.
 */
const PasswordField = ({withFaIcons, ...props}, {texts}) => {
    const [type, setType] = useState("password")

    return (
        <Field
            as={Input}
            component={SemanticFormField}
            icon={
                <Popup
                    content={
                        type === "password" ? texts.passwordFieldPasswordTypePopup : texts.passwordFieldTextTypePopup
                    }
                    trigger={
                        withFaIcons ? (
                            <Icon
                                circular={true}
                                className={type === "password" ? "fa-solid fa-eye" : "fa-solid fa-eye-slash"}
                                link={true}
                                onClick={() => setType(type === "password" ? "text" : "password")}
                            />
                        ) : (
                            <Icon
                                circular={true}
                                link={true}
                                name={type === "password" ? "eye" : "eye slash"}
                                onClick={() => setType(type === "password" ? "text" : "password")}
                            />
                        )
                    }
                />
            }
            type={type}
            {...props}
        />
    )
}

PasswordField.defaultProps = {
    withFaIcons: false,
}

PasswordField.propTypes = {
    withFaIcons: PropTypes.bool, // TODO A supprimer quand les packages Font Awesome Pro seront integrés dans tous les espaces
}

PasswordField.contextTypes = {texts: PropTypes.object}

export default PasswordField
