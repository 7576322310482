/* eslint-disable */
import PropTypes from "prop-types"
import React, {Component} from "react"
import {connect} from "react-redux"
import {Redirect, Route} from "react-router-dom"

/**
 * Route that tests for permissions and user connection.
 * If the connected user has a permission in the list provided in props, it will render a
 * default route. Otherwise, it will redirect the user to the unauthorized page.
 *
 * If no permission is specified, check only user connection.
 */
class PermissionRoute extends Component {
    render() {
        let {
            features,
            loginRoute,
            page,
            pagesArchitecture,
            permissions,
            token,
            unauthorizedRoute,
            userPermissions,
            userType,
            unauthorizedClientType,
            ...rest
        } = this.props
        // If the user has no authentication token
        if (!token) {
            return <Redirect to={loginRoute} />
        }

        // A specific case when a page need to be display depending on the client type (PETIT_CLIENT, GRAND_CLIENT)
        if (unauthorizedClientType) {
            for (const uCT of unauthorizedClientType) {
                if (uCT === userType) {
                    return <Redirect to={unauthorizedRoute} />
                }
            }
        }

        // Feature flag check
        if (
            pagesArchitecture &&
            page &&
            pagesArchitecture[page].featureFlag &&
            features &&
            !features.includes(pagesArchitecture[page].featureFlag)
        ) {
            return <Redirect to={unauthorizedRoute} />
        }

        // At this point user is authenticated and this route does not need specific permissions
        // Render the page
        if (!permissions) {
            return <Route {...rest} />
        }

        // Check permissions
        // If the user has anyone needed, render the page
        for (const p of permissions) {
            if (userPermissions.includes(p)) {
                return <Route {...rest} />
            }
        }

        // The user does not have any of the required permissions
        // Redirect to unauthorized route
        return <Redirect to={unauthorizedRoute} />
    }
}

PermissionRoute.propTypes = {
    /**
     * Enabled features, needed only is the page isFeatureFlagged
     */
    features: PropTypes.arrayOf(PropTypes.string),
    permissions: PropTypes.arrayOf(PropTypes.string),
    loginRoute: PropTypes.string.isRequired,
    /**
     * Page of the route
     */
    page: PropTypes.string,
    pagesArchitecture: PropTypes.object,
    unauthorizedRoute: PropTypes.string.isRequired,
    unauthorizedClientType: PropTypes.arrayOf(PropTypes.string),
}

const mapStateToProps = (state, props) => ({
    /**
     * Enabled features, needed only is the page isFeatureFlagged
     */
    features: props.getEnabledFeatures ? props.getEnabledFeatures(state) : [],
    userPermissions: props.getUserPermissions(state),
    token: props.getUserToken(state),
    userType: props.getUserClientType ? props.getUserClientType(state) : null,
})

export default connect(mapStateToProps, null)(PermissionRoute)
