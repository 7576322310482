import {EDIT_PAYMENT, RESET_PAYMENT} from "../../../actions/localStorage/orderPayment/orderPaymentActionTypes"

const orderPayment = (state = {}, action = {}) => {
    switch (action.type) {
        case EDIT_PAYMENT:
            return action.data
        case RESET_PAYMENT:
            return {}
        default:
            return state
    }
}

export default orderPayment

export const getOrderPayment = state => state
