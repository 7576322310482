import {EDIT_DELIVERY, RESET_DELIVERY} from "./orderDeliveryActionTypes"

export const editDelivery = formValues => {
    return {
        type: EDIT_DELIVERY,
        data: formValues,
    }
}

export const resetDelivery = {
    type: RESET_DELIVERY,
}
