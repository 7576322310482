import React, {Component} from "react"
import {connect} from "react-redux"
import {Redirect, Route} from "react-router-dom"
import PropTypes from "prop-types"

/**
 * Route to test for user connection.
 * If a user is connected, redirect him to the home page.
 * Otherwise, let him pass.
 */
class NotConnectedRoute extends Component {
    static propTypes = {
        connectedUserRoute: PropTypes.string.isRequired,
        isUserConnected: PropTypes.bool.isRequired,
    }

    render() {
        const {connectedUserRoute, isUserConnected, ...props} = this.props

        if (isUserConnected) {
            return <Redirect to={connectedUserRoute} />
        }

        return <Route {...props} />
    }
}

export default connect(
    (state, props) => ({
        isUserConnected: props.isUserConnected(state),
    }),
    null,
)(NotConnectedRoute)
