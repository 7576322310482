import PropTypes from "prop-types"
import React, {useMemo} from "react"
import {useIsResponsive} from "../../hooks/useIsResponsive"
import {useTexts} from "../../hooks/useTexts"
import HeaderLink from "../HeaderLink/headerLink"
import HeaderMenuPopup from "../HeaderMenuPopup/headerMenuPopup"

const HeaderMenu = ({isConnected, menuLinks, onConnectClick, textColor, username}) => {
    const isResponsive = useIsResponsive()
    const texts = useTexts()

    // En mode responsive non connecté, on ajoute un lien de connexion dans le menu en première position, s'il est défini
    const links = useMemo(
        () =>
            isResponsive && !isConnected && onConnectClick
                ? [
                      {
                          label: texts.headerLogin,
                          onClick: onConnectClick,
                      },
                      ...menuLinks,
                  ]
                : menuLinks,
        [isResponsive, menuLinks, onConnectClick, texts, isConnected],
    )

    // Dans certains cas, on n'affiche pas le menu
    const displayNoMenuInDesktop = useMemo(
        () => !isResponsive && !isConnected && !onConnectClick,
        [isResponsive, onConnectClick, isConnected],
    )
    const displayNoMenuInResponsive = useMemo(
        () => isResponsive && !isConnected && links.length === 0 && !onConnectClick,
        [isResponsive, links.length, onConnectClick, isConnected],
    )

    // Dans certains cas, on affiche seulement un lien, sans popup
    const displaySimpleLinkInDesktop = useMemo(
        () => !isResponsive && ((isConnected && links.length === 0) || (!isConnected && onConnectClick)),
        [isResponsive, links.length, onConnectClick, isConnected],
    )
    const displaySimpleLinkInResponsive = false

    return displayNoMenuInDesktop || displayNoMenuInResponsive ? (
        // eslint-disable-next-line react/jsx-no-useless-fragment
        <></>
    ) : displaySimpleLinkInDesktop || displaySimpleLinkInResponsive ? (
        <HeaderLink
            icon="fa-solid fa-circle-user"
            label={isConnected && username !== "undefined" ? username : texts.headerLogin}
            onClick={isConnected ? null : onConnectClick}
            textColor={textColor}
        />
    ) : (
        <HeaderMenuPopup isConnected={isConnected} menuLinks={links} textColor={textColor} username={username} />
    )
}

HeaderMenu.propTypes = {
    isConnected: PropTypes.bool,
    menuLinks: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            onClick: PropTypes.func,
        }),
    ),
    onConnectClick: PropTypes.func,
    textColor: PropTypes.string.isRequired,
    username: PropTypes.string,
}

HeaderMenu.defaultProps = {
    isConnected: false,
    menuLinks: [],
    username: undefined,
    onConnectClick: undefined,
}

export default HeaderMenu
