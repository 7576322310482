import {SAVE_REGISTRATION_PROFILE, RESET_REGISTRATION_PROFILE} from "./registrationProfileActionTypes"

export const saveRegistrationProfile = values => ({
    type: SAVE_REGISTRATION_PROFILE,
    values,
})

export const resetRegistrationProfile = {
    type: RESET_REGISTRATION_PROFILE,
}
