import {
    SAVE_REGISTRATION_PROFILE,
    RESET_REGISTRATION_PROFILE,
} from "../../../actions/localStorage/registrationProfile/registrationProfileActionTypes"

const registrationProfile = (state = {}, action = {}) => {
    switch (action.type) {
        case SAVE_REGISTRATION_PROFILE:
            return action.values
        case RESET_REGISTRATION_PROFILE:
            return {}
        default:
            return state
    }
}

export default registrationProfile

export const getRegistrationProfile = state => state
