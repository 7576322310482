import {
    SET_ID_ORDER_DETAILS,
    CLEAR_ORDER_DETAILS_ID,
} from "../../../actions/temporaries/orderDetails/orderDetailsActionTypes"

const orderDetails = (state = {}, action = {}) => {
    switch (action.type) {
        case SET_ID_ORDER_DETAILS:
            return {
                id: action.id,
            }
        case CLEAR_ORDER_DETAILS_ID:
            return {}
        default:
            return state
    }
}

export default orderDetails

export const getId = state => state.id
