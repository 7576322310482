import React, {useMemo} from "react"

import PropTypes from "prop-types"
import {Icon, Message as SemanticMessage} from "semantic-ui-react"

import {getInfoMessageIcon} from "../../utils/iconUtil"

import "./message.scss"

const Message = ({className, type, displayIcon, header, list, ...props}) => {
    const iconName = useMemo(() => (displayIcon ? getInfoMessageIcon(type) : undefined), [displayIcon, type])

    const typeMessageProps = useMemo(() => {
        switch (type) {
            case "success":
                return {list, positive: true}
            case "warning":
                return {list, visible: true, warning: true}
            case "error":
                return {list, negative: true}
            case "info":
                return {info: true}
        }
    }, [type, list])

    const builtHeader = useMemo(
        () =>
            iconName ? (
                <div class="header header-container">
                    <Icon className="information" name={iconName} size="large" /> {header}
                </div>
            ) : (
                header
            ),
        [iconName, header],
    )

    return (
        <SemanticMessage
            className={iconName ? `${className || ""} icon-message` : className}
            header={builtHeader}
            {...typeMessageProps}
            {...props}
        />
    )
}

Message.defaultProps = {
    className: null,
    content: null,
    displayIcon: false,
    header: null,
    list: null,
    onDismiss: null,
}

Message.propTypes = {
    className: PropTypes.string,
    content: PropTypes.any,
    displayIcon: PropTypes.bool,
    header: PropTypes.any,
    list: PropTypes.any,
    onDismiss: PropTypes.func,
    type: PropTypes.oneOf(["success", "warning", "error", "info"]).isRequired,
}

export default Message
