import {
    SAVE_REGISTRATION_SESSION_CHOICE,
    RESET_REGISTRATION_SESSION_CHOICE,
    SET_CONTROL_STEP_DISPLAYING,
    SET_SESSION_STEP_DISPLAYING,
} from "./registrationSessionChoiceActionTypes"

export const saveRegistrationSessionChoice = values => ({
    type: SAVE_REGISTRATION_SESSION_CHOICE,
    values,
})

export const setControlStepDisplaying = displayControlStep => ({
    type: SET_CONTROL_STEP_DISPLAYING,
    displayControlStep,
})

export const setSessionStepDisplaying = displaySessionStep => ({
    type: SET_SESSION_STEP_DISPLAYING,
    displaySessionStep,
})

export const resetRegistrationSessionChoice = {
    type: RESET_REGISTRATION_SESSION_CHOICE,
}
