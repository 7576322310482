import {EDIT_CONTENT, RESET_CONTENT} from "./orderContentActionTypes"

export const editContent = formValues => {
    return {
        type: EDIT_CONTENT,
        data: formValues,
    }
}

export const resetContent = {
    type: RESET_CONTENT,
}
