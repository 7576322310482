import {EDIT_DELIVERY, RESET_DELIVERY} from "../../../actions/localStorage/orderDelivery/orderDeliveryActionTypes"

const orderDelivery = (state = {}, action = {}) => {
    switch (action.type) {
        case EDIT_DELIVERY:
            return action.data
        case RESET_DELIVERY:
            return {}
        default:
            return state
    }
}

export default orderDelivery

export const getOrderDelivery = state => state
