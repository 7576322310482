import {
    SAVE_VISUALISATION,
    RESET_VISUALISATION,
} from "../../../actions/localStorage/visualisation/visualisationActionTypes"

const visualisation = (state = {}, action = {}) => {
    switch (action.type) {
        case SAVE_VISUALISATION:
            return {
                visualisationMode: action.visualisationMode,
                selectedContract: action.selectedContract,
            }
        case RESET_VISUALISATION:
            return {}
        default:
            return state
    }
}

export default visualisation

/**
 * Return user selected visualisation mode
 */
export const getVisualisationMode = state => state.visualisationMode
/**
 * Return user selected contract
 */
export const getSelectedContract = state => state.selectedContract
