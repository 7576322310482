import {Order} from "../constants/orderActionsEnum"
import {PaymentModes} from "../constants/paymentModes"
import {BeneficiaryDeliveryTypes} from "../shared-components/constants/beneficiaryDeliveryTypes"
import {CESU_URSSAF_URL, PAJ_EMPLOI_URSSAF} from "../shared-components/constants/links"
import {ProductTypes} from "../shared-components/constants/productTypes"
import {SupportTypes} from "../shared-components/constants/supportTypes"
import {TradeFeeTypes} from "../shared-components/constants/tradeFeeType"
import {sharedGlobalTexts} from "../shared-components/styles/globalTexts"

export const defaultTexts = {
    // Insert shared global texts
    ...sharedGlobalTexts,

    // ACCOUNT STATEMENT PAGE
    accountStatementTitle: "Relevé des transactions",
    accountStatementDate: "Relevé du Compte arrêté au ",
    accountStatementNoAccount: "Vous n'avez aucun compte CESU.",
    accountStatementAccountSelectionParagraph: "Visualisez le relevé du Compte CESU",
    accountStatementWritingTableParagraph1: "Plan du mois de ",
    accountStatementWritingTableSeparator: " - ",
    accountStatementSocialAccountSelectionParagraph: "Je souhaite voir le relevé de mon Plan d'aide",
    accountStatementSocialNoAccount: "Aucun plan d'aide actif rattaché.",
    accountStatementSocialTitle: "Choix du Plan d'aide",
    accountStatementSelectedAccountAmount: selectedAccountAmount => "SOLDE TOTAL : " + selectedAccountAmount + " €",

    // ACCOUNT PAYMENT AMOUNT
    accountPaymentAmountFormPanelTitle: "Montant à payer",
    accountPaymentAmountParagraph1: intervenantLabel => "Je souhaite effectuer un paiement à " + intervenantLabel,
    accountPaymentAmountParagraph2: "Pour un montant de",
    accountPaymentAmountParagraph3:
        "Si vous souhaitez effectuer un réglement inférieur au montant net déclaré, il vous appartient de régler le reliquat par un autre moyen de paiement.",
    accountPaymentAmountPopup: "Le montant ne peut pas être supérieur au montant net déclaré",
    accountPaymentAmountFormPanelAccountTitle: "Compte CESU à débiter",
    accountPaymentAmountFormPanelAccountSelectAccount: "Veuillez selectionner un compte.",
    accountPaymentAmountFooterParagraph1: "Reste à compléter :",
    accountPaymentAmountCreditCardFormPanelTitle: "Rechargement par Carte Bancaire",
    accountPaymentAmountCreditCardParagraph1:
        "Afin de compléter le paiement, si la somme disponible sur votre (vos) compte(s) CESU est insuffisante, vous " +
        "pouvez recharger votre compte avec votre Carte Bancaire. Pour effectuer ce rechargement, il vous suffit de cliquer sur le bouton valider.",
    accountPaymentAmountCreditCardAmount1: "Des frais de rechargement à hauteur de ",
    accountPaymentAmountCreditCardAmount2: " vous seront demandés.",
    accountPaymentAmountCreditCardAmount3: "Le montant débité sur votre carte sera donc de ",
    accountPaymentAmountConfirmation: url =>
        "<p>Voulez-vous vraiment effectuer ce paiement ?</p>" +
        "<p>La confirmation de votre ordre de paiement vaut prise de connaissance et acceptation des <a href='" +
        url +
        "'>Conditions générales d’utilisation</a></p>",
    accountPaymentAmountWithholdingPaymentAmount: amount => "Le montant que vous avez déclaré est de " + amount + " €",
    accountPaymentAmountOverviewFormPanelTitle1: "Vous souhaitez effectuer un paiement",
    accountPaymentAmountOverviewFormPanelTitle2: "Prestataire - Délai de paiement",
    accountPaymentAmountOverviewFormPanelTitle3: "Récapitulatif des heures saisies",
    accountPaymentAmountOverviewFormPanelTitle3Amount: "Récapitulatif du montant saisi",
    accountPaymentAmountOverviewFormPanelTitle4: "Vous souhaitez envoyer le commentaire suivant à l'intervenant",
    accountPaymentAmountOverviewTo: "à ",
    accountPaymentAmountOverviewPlan: "à partir de votre Plan d'aide ",
    accountPaymentAmountOverviewDelayParagraph1:
        "Le prestataire a besoin d'être adhérent à Domiserve pour pouvoir recevoir le paiement. S'il n'est pas adhérent la paiement sera mis en attente.",
    accountPaymentAmountOverviewDelayParagraph2:
        "Le délai des paiements est variable en fonction des choix faits par les prestataires dans leurs contrats.",
    accountPaymentAmountOverviewDelayParagraph3:
        "En effet, ceux-ci recevront leur paiement dans la période de temps spécifié dans leur contrat.",
    accountPaymentAmountOverviewComplementTitle: "Paiement Complémentaire par Carte Bancaire",
    accountPaymentAmountOverviewComplementField1: "Rechargement par Carte Bancaire",
    accountPaymentAmountOverviewComplementField2: "Frais de rechargement Carte Bancaire",
    accountPaymentAmountOverviewComplementField3: "Montant à débiter sur votre Carte Bancaire",
    accountPaymentSocialAmountError: "Vous ne disposez pas d'autant de droit disponible sur ce mois",
    accountPaymentSocialAmountSuperiorToAmountDeclared: "Le montant ne peut pas être supérieur au montant net déclaré",
    accountPaymentSocialTotal: "Total",
    accountPaymentSocialAmountToDebit: "Montant à débiter",
    accountPaymentSocialCreditCardAmount1: "Je souhaite effectuer un complément de paiement par Carte Bancaire de ",
    accountPaymentSocialComplementCheckboxLabel: "Je souhaite effectuer un complément de paiement par CB",
    accountPaymentSocialComplementFormPanelTitle: "Complément de paiement par Carte Bancaire",

    accountPaymentButtonCancelPayment: "Abandonner le paiement",
    accountPaymentButtonDeclaration: "Déclarer",
    accountPaymentButtonDeclarationHomeReturn: "Retour à l'accueil",
    accountPaymentButtonDeclarationPayIntervenant: "Payer mon intervenant",
    accountPaymentButtonDeclarationReceipt: "Télécharger un justificatif",
    accountPaymentButtonEditIntervenant: "Modifier l'intervenant",
    accountPaymentButtonValidate: "Valider",
    accountPaymentEmployerCesuPopup:
        "Le numéro CESU est la référence employeur qui figure sur l'ensemble des documents personnalisés adressés par le Cesu. Il est composé de 14 caractères et " +
        ' commence par "08", "09", "X" ou "Z".',
    accountPaymentEmployeeCesuPopup:
        "Le numéro CESU salarié est un numéro sur 14 caractères composé que de chiffres. Exemple : 00000004446675.",
    accountPaymentEmployerPajePopup:
        "Le numéro Pajemploi est la référence employeur qui figure sur l'ensemble des documents personnalisés adressés par Pajemploi. Il est composé de 14 caractères.",
    accountPaymentEmployeePajePopup:
        "Le numéro Pajemploi est la référence employé qui figure sur l'ensemble des documents personnalisés adressés par Pajemploi. Il est composé de 14 caractères.",

    // ACCOUNT PAYMENT CHOICE
    accountPaymentChoiceButtonReturn: "Retour",
    accountPaymentChoiceButtonReturnToList: "Retour à la liste",
    accountPaymentChoiceNoAccountTitle: "Aucun compte",
    accountPaymentChoiceNoAccountParagraph:
        "Vous n'avez actuellement aucun compte CESU ouvert. Vous ne pouvez pas effectuer de paiement à un intervenant.",
    accountPaymentChoiceWithholdingFormPanelTitle: "Paiement et déclaration",
    accountPaymentChoiceWithholdingFormPanelParagraph1:
        "En tant que particulier employeur, il est obligatoire de déclarer les heures effectuées par votre salarié auprès des organismes compétents, " +
        "afin de vous acquitter des cotisations sociales.",
    accountPaymentChoiceWithholdingFormPanelParagraph2:
        "En déclarant votre salarié, vous contribuez au financement de la protection sociale et respectez les droits de votre salarié.",
    accountPaymentChoiceWithholdingFormPanelParagraph3:
        "Domiserve vous permet d'effectuer votre déclaration et de régler le salaire net de votre intervenant sur un seul et même espace.",
    accountPaymentChoiceWithholdingFormPanelIntervernantDeclaration: "Je souhaite déclarer et payer mon intervenant",
    accountPaymentChoiceWithholdingOrganizationTitle: "Choix de l'organisme",
    accountPaymentChoiceWithholdingOrganizationParagraph:
        "Auprès de quel organisme souhaitez-vous effectuer votre déclaration ?",
    accountPaymentChoiceWithholdingOrganizationCESU: "Cesu",
    accountPaymentChoiceWithholdingOrganizationPAJE: "Pajemploi (uniquement pour la garde d'enfant de moins de 6 ans)",

    // ACCOUNT CESU PAYMENT IDENTIFICATION
    accountPaymentCesuIdentificationIntroduction1:
        "Avant de commencer votre déclaration, assurez-vous d'avoir en votre possession toutes les informations concernant votre salarié (Numéro CESU ou numéro de sécurité sociale et date de naissance).",
    accountPaymentCesuIdentificationEmployerTitle: "Identification Employeur",
    accountPaymentCesuIdentificationEmployerParagraph:
        "Renseignez votre numéro CESU Employeur et votre date de naissance.",
    accountPaymentCesuIdentificationEmployerParagraph2: "Vous n'êtes pas encore inscrit au Cesu ? Rendez-vous sur ",
    accountPaymentCesuIdentificationUrssafCesuUrl: "cesu.urssaf.fr",
    accountPaymentCesuIdentificationAutorisationTitle: "Autorisation",
    accountPaymentCesuIdentificationAutorisationCheckLabelPart1: "Je donne",
    accountPaymentCesuIdentificationAutorisationCheckLabelMandate: " mandat ",
    accountPaymentCesuIdentificationAutorisationCheckLabelPart2:
        "à Domiserve pour transmettre ma déclaration CESU de manière concomitante au paiement du salaire net de mon Intervenant",
    accountPaymentCesuIdentificationEmployeeTitle: "Identification Salarié",
    accountPaymentCesuIdentificationEmployeeCESUParagraph:
        "Renseignez le numéro CESU de votre salarié ainsi que sa date de naissance.",
    accountPaymentCesuIdentificationEmployeeCESUParagraph2:
        "Attention, le N° CESU salarié et le code NAN sont deux informations différentes.",
    accountPaymentCesuIdentificationEmployeeNirParagraph:
        "Renseignez le numéro de sécurité sociale de votre salarié ainsi que sa date de naissance",
    accountPaymentCesuIdentificationNumberTitle:
        "Connaissez-vous le numéro CESU de votre salarié (fourni par le Cesu) ?",
    accountPaymentCesuIdentificationNumberCESULabel: "Je connais le numéro CESU de mon salarié",
    accountPaymentCesuIdentificationNumberNIRLabel: "Je connais le numéro de sécurité sociale de mon salarié",

    accountPaymentCesuIdentificationErrorEmployerAccountWrongStatus:
        "L’état de votre compte ne nous permet pas de continuer. Veuillez régulariser votre situation sur le site du CESU www.cesu.urssaf.fr",
    accountPaymentCesuIdentificationErrorEmployerAccountUnregistered:
        "Si vous n’êtes pas encore inscrit au Cesu, rendez-vous ",
    accountPaymentCesuIdentificationErrorEmployeeAccountUnregistered:
        "Votre salarié n’est pas encore inscrit au Cesu pour pouvoir effectuer une déclaration sur notre site.",

    // ACCOUNT CESU PAYMENT IDENTIFICATION
    accountPaymentPajeIdentificationIntroduction1:
        "Avant de commencer votre déclaration, assurez-vous d'avoir en votre possession toutes les informations concernant votre salarié (Numéro Pajemploi ou numéro de sécurité sociale et date de naissance).",
    accountPaymentPajeIdentificationEmployerTitle: "Identification Employeur",
    accountPaymentPajeIdentificationEmployerParagraph:
        "Renseignez votre numéro Pajemploi Employeur et votre date de naissance.",
    accountPaymentPajeIdentificationEmployerParagraph2: "Vous n'êtes pas encore inscrit à Pajemploi ? Rendez-vous sur ",
    accountPaymentPajeIdentificationUrssafPajeUrl: "pajemploi.urssaf.fr",
    accountPaymentPajeIdentificationAutorisationTitle: "Autorisation",
    accountPaymentPajeIdentificationAutorisationCheckLabelPart1: "Je donne",
    accountPaymentPajeIdentificationAutorisationCheckLabelMandate: " mandat ",
    accountPaymentPajeIdentificationAutorisationCheckLabelPart2:
        "à Domiserve pour transmettre ma déclaration Pajemploi de manière concomitante au paiement du salaire net de mon Intervenant",
    accountPaymentPajeIdentificationEmployeeTitle: "Identification Salarié",
    accountPaymentPajeIdentificationEmployeePAJEParagraph:
        "Renseignez le numéro Pajemploi de votre salarié ainsi que sa date de naissance.",
    accountPaymentPajeIdentificationEmployeePAJEParagraph2:
        "Attention, le N° PAJEMPLOI salarié et le code NAN sont deux informations différentes.",
    accountPaymentPajeIdentificationEmployeeNirParagraph:
        "Renseignez le numéro de sécurité sociale de votre salarié ainsi que sa date de naissance",
    accountPaymentPajeIdentificationNumberTitle: "Connaissez-vous le numéro Pajemploi de votre salarié ?",
    accountPaymentPajeIdentificationNumberPAJELabel: "Je connais le numéro Pajemploi de mon salarié",
    accountPaymentPajeIdentificationNumberNIRLabel: "Je connais le numéro de sécurité sociale de mon salarié",

    accountPaymentPajeIdentificationErrorEmployerAccountWrongStatus:
        "L’état de votre compte ne nous permet pas de continuer. Veuillez régulariser votre situation sur le site du Pajemploi www.pajemploi.urssaf.fr",
    accountPaymentPajeIdentificationErrorEmployerAccountUnregistered:
        "Si vous n’êtes pas encore inscrit au Pajemploi, rendez-vous ",
    accountPaymentPajeIdentificationErrorEmployeeAccountUnregistered:
        "Votre salarié n’est pas encore inscrit au Pajemploi pour pouvoir effectuer une déclaration sur notre site.",
    accountPaymentPajeIdenticationEmployerPajeNumberPlaceHolder: "N° Pajemploi employeur",
    accountPaymentPajeIdenticationEmployeePajeNumberPlaceHolder: "N° Pajemploi salarié",
    accountPaymentPajeIdenticationEmployeeNirPlaceHolder: "N° de sécurité sociale",

    // ACCOUNT PAYMENT CESU DECLARATION
    accountPaymentCesuDeclarationPeriodFormPanelTitle: "Période de la prestation",
    accountPaymentCesuDeclarationPeriodFormPanelParagraph1: "Je souhaite déclarer et payer mon salarié : ",
    accountPaymentCesuDeclarationPeriodFormPanelPeriodLabel: "Période *",
    accountPaymentCesuDeclarationPeriodFormPanelPeriodInfo: "Mois durant lequel la prestation a été effectuée.",
    accountPaymentCesuDeclarationPeriodFormPanelFromLabel: "Du *",
    accountPaymentCesuDeclarationPeriodFormPanelToLabel: "Au *",
    accountPaymentCesuDeclarationPeriodFormPanelPaymentDateLabel: "Date de versement *",
    accountPaymentCesuDeclarationPeriodFormPanelPaymentDateInfo: "Date indiquée sur le bulletin de salaire.",

    accountPaymentCesuDeclarationSalaryFormPanelTitle: "Salaire",
    accountPaymentCesuDeclarationSalaryFormPanelHoursWorkedLabel: "Total des heures effectuées *",
    accountPaymentCesuDeclarationSalaryFormPanelTotalHourlyWageLabel: "Salaire horaire net *",
    accountPaymentCesuDeclarationSalaryFormPanelTotalHourlyWageInfo:
        "Le salaire horaire correspond, a minima, au montant du SMIC horaire net en vigueur.",
    accountPaymentCesuDeclarationSalaryFormPanelPaidLeaveHoursLabel: "Nombre d'heures congés payés",
    accountPaymentCesuDeclarationSalaryFormPanelPaidLeaveHoursInfo:
        "A compléter si le salaire horaire net ne comporte pas la majoration de 10% au titre des congés payés.",

    accountPaymentCesuDeclarationPaidLeaveFormPanelTitle: "Congés payés",
    accountPaymentCesuDeclarationPaidLeaveFormPanelInfo:
        "Ces informations sont reprises sur le bulletin de salaire et ne sont à renseigner qu’en cas de gestion spécifique des congés.",
    accountPaymentCesuDeclarationPaidLeaveFormPanelFromLabel: "Du",
    accountPaymentCesuDeclarationPaidLeaveFormPanelToLabel: "Au",
    accountPaymentCesuDeclarationPaidLeaveFormPanelNumberOfPaidLeaveDays: "Nombre de jours de congés",
    accountPaymentCesuDeclarationPaidLeaveAddButton: "Ajouter une période de congés",

    accountPaymentCesuDeclarationSalaryComplementTitle: "Compléments de salaire",
    accountPaymentCesuDeclarationSalaryOvertimeHours1: "Heures majorées à 25 %",
    accountPaymentCesuDeclarationSalaryOvertimeHours2: "Heures majorées à 50 %",
    accountPaymentCesuDeclarationSalaryComplementCategoryHEUR: "Heures spécifiques",
    accountPaymentCesuDeclarationSalaryComplementCategoryAVAN: "Avantages en nature",
    accountPaymentCesuDeclarationSalaryComplementCategoryPART: "Participation salariée",
    accountPaymentCesuDeclarationSalaryComplementCategoryPRIM: "Prime",
    accountPaymentCesuDeclarationSalaryComplementCategoryTRAN: "Transport",
    accountPaymentCesuDeclarationSalaryComplementCategoryFINC: "Fin de contrat",

    accountPaymentCesuDeclarationSalaryComplementCategoryHEURHourlyRate: "Salaire horaire",
    accountPaymentCesuDeclarationSalaryComplementCategoryHEURNumberOfHours: "Nombre d'heures",

    accountPaymentCesuDeclarationSalaryComplementCategoryHEURSubNUIT: "Montant présence de nuit",
    accountPaymentCesuDeclarationSalaryComplementCategoryHEURSubGARD: "Montant garde-malade de nuit",
    accountPaymentCesuDeclarationSalaryComplementCategoryHEURSubRESP: "Montant présence responsable",
    accountPaymentCesuDeclarationSalaryComplementCategoryAVANSubNOUR: "Nourriture",
    accountPaymentCesuDeclarationSalaryComplementCategoryAVANSubLOGE: "Logement",
    accountPaymentCesuDeclarationSalaryComplementCategoryAVANSubAUTR: "Autres",
    accountPaymentCesuDeclarationSalaryComplementCategoryPARTSubPART: "Participation salariée",
    accountPaymentCesuDeclarationSalaryComplementCategoryPRIMSubPRIM: "Prime",
    accountPaymentCesuDeclarationSalaryComplementCategoryTRANSubKILO: "Indemnités kilométriques",
    accountPaymentCesuDeclarationSalaryComplementCategoryTRANSubFRAI: "Frais de transport",
    accountPaymentCesuDeclarationSalaryComplementCategoryFINCSubLICE: "Indemnité de licenciement",
    accountPaymentCesuDeclarationSalaryComplementCategoryFINCSubRETR: "Indemnité départ volontaire retraite",
    accountPaymentCesuDeclarationTotalAmount: " Montant total net déclaré : ",

    accountPaymentCesuDeclarationConfirmationNotificationSuccessHeader:
        "Votre déclaration a été effectuée avec succès !",
    accountPaymentCesuDeclarationConfirmationNotificationSuccessContent: "Le numéro CESU de votre salarié est : ",
    accountPaymentCesuDeclarationConfirmationFormPanelTitle: "Déclaration",
    accountPaymentCesuDeclarationConfirmationFormPanelIntervenantInfo1: "Votre déclaration concernant ",
    accountPaymentCesuDeclarationConfirmationFormPanelIntervenantInfo2: " a bien été transmise au Cesu",
    accountPaymentCesuDeclarationConfirmationFormPanelDeclaredAmount: "Le montant net déclaré est de : ",
    accountPaymentCesuDeclarationConfirmationFormPanelPhraseList:
        "Le montant qui vous sera prélevé sur votre compte bancaire sera de :",
    accountPaymentCesuDeclarationConfirmationFormPanelEmployerContribution: "Cotisations Patronales : ",
    accountPaymentCesuDeclarationConfirmationFormPanelEmployeeContribution: "Cotisations Salariales : ",
    accountPaymentCesuDeclarationConfirmationFormPanelEmployeeWittholding: "Prélèvement à la source : ",
    accountPaymentCesuDeclarationConfirmationFormPanelEmployeeTotal: "Soit un total de : ",
    accountPaymentCesuDeclarationConfirmationFormPanelEmployeeTotalToPay:
        "Le montant net à payer déduction faite du prélèvement à la source est de : ",

    accountPaymentCesuDeclarationErrorAfterDate: "La date de fin doit être postérieure ou égale à la date de début.",
    accountPaymentCesuDeclarationErrorPaymentDate:
        "La date de versement doit être après la date de début de la période.",
    accountPaymentCesuDeclarationErrorMax: value => "La valeur saisie ne doit pas dépasser " + value,
    accountPaymentCesuDeclarationErrorNumberOfPaidLeavesMultiple:
        "Veuillez vérifier votre saisie. Exemple : 0.5, 1, 1.5, 2, ...",
    accountPaymentCesuDeclarationErrorOvertime:
        "Pour déclarer des heures majorées à 50%, le nombre d'heures majorées à 25% doit être suppérieur ou égal à 8",
    accountPaymentCesuDeclarationErrorPaidLeaveDates: "Les congés doivent être dans la période d'emploi",

    // ACCOUNT PAYMENT PAJE DECLARATION
    accountPaymentPajeDeclarationLastDeclarationTitle: "Dernière déclaration",
    accountPaymentPajeDeclarationLastDeclarationWage: "Rémunération ",
    accountPaymentPajeDeclarationLastDeclarationWage2: " euros net",
    accountPaymentPajeDeclarationLastDeclarationAutoFillLabel: "Je souhaite préremplir avec ma dernière déclaration",
    accountPaymentPajeDeclarationLastDeclarationNoDeclaration:
        "Aucune déclaration effectuée depuis ces derniers 6 mois.",

    accountPaymentPajeDeclarationPrestationTypeTitle: "Activité salarié",
    accountPaymentPajeDeclarationPrestationTypeParagraph:
        "Le salarié que vous avez sélectionné peut effectuer deux types de prestations. Votre déclaration concerne :",
    accountPaymentPajeDeclarationPrestationTypeAmaLabel:
        "Garde d’enfant de moins de 3 ans effectuée par un(e) assistant(e) maternel(le) agréé(e)",
    accountPaymentPajeDeclarationPrestationTypeGedLabel: "Garde d’enfant à domicile",

    accountPaymentPajeDeclarationPeriodFormPanelTitle: "Période de la prestation",
    accountPaymentPajeDeclarationPeriodFormPanelParagraph1: "Je souhaite déclarer et payer mon salarié : ",
    accountPaymentPajeDeclarationPeriodFormPanelPeriodLabel: "Période *",
    accountPaymentPajeDeclarationPeriodFormPanelPeriodInfo: "Mois durant lequel la prestation a été effectuée.",
    accountPaymentPajeDeclarationPeriodFormPanelFromLabel: "Du *",
    accountPaymentPajeDeclarationPeriodFormPanelToLabel: "Au *",
    accountPaymentPajeDeclarationPeriodFormPanelPaymentDateLabel: "Date de versement *",
    accountPaymentPajeDeclarationPeriodFormPanelPaymentDateInfo: "Date indiquée sur le bulletin de salaire.",

    accountPaymentPajeDeclarationSalaryFormPanelTitle: "Rémunération",
    accountPaymentPajeDeclarationSalaryFormPanelHoursWorkedLabel: "Nombre d'heures effectives *",
    accountPaymentPajeDeclarationSalaryFormPanelActivityDaysNbLabel: "Nombre de jours d'activité *",
    accountPaymentPajeDeclarationSalaryFormPanelHoursWorkedInfo:
        "Inclus les heures d'absence pour congés payés et les heures spécifiques le cas échéant",
    accountPaymentPajeDeclarationSalaryFormPanelNetMonthlyWageLabel: "Salaire net total *",
    accountPaymentPajeDeclarationSalaryFormPanelNetMonthlyWageInfo:
        "Hors indemnités kilométriques, frais de transport et indemnités de licenciement",
    accountPaymentPajeDeclarationSalaryFormPanelAdvancePaymentAmountInfo:
        "L'acompte versé correspond à ce qui a déjà été réglé à l'intervenant",
    accountPaymentPajeDeclarationSalaryFormPanelPaidLeaveDaysLabel: "Nombre de jours de congés payés",
    accountPaymentPajeDeclarationSalaryFormPanelAdvancePaymentAmountLabel: "Valeur de l'acompte",

    accountPaymentPajeDeclarationSalaryComplementTitle: "Indemnités complémentaires",
    accountPaymentPajeDeclarationSalaryComplementAdvancePaymentRadio:
        "Avez-vous des heures supplémentaires à déclarer ?",
    accountPaymentPajeDeclarationSalaryComplementAdvancePaymentNetHourlyWage: "Salaire horaire net d'une heure normale",
    accountPaymentPajeDeclarationSalaryComplementAdvancePaymentOvertime25: "Nombre d'heures supplémentaires à 25 %",
    accountPaymentPajeDeclarationSalaryComplementAdvancePaymentOvertime50: "Nombre d'heures supplémentaires à 50 %",
    accountPaymentPajeDeclarationSalaryComplementSpecificalHoursRadio: "Avez-vous des heures spécifiques à déclarer ?",
    accountPaymentPajeDeclarationSalaryComplementSpecificalHoursWarning1:
        "ATTENTION ! Cette zone ne doit être remplie que par les parents ayant été dans l'obligation de faire garder leur(s) enfant(s) au cours du mois",
    accountPaymentPajeDeclarationSalaryComplementSpecificalHoursWarning2: "entre 22 heures et 6 heures",
    accountPaymentPajeDeclarationSalaryComplementSpecificalHoursWarning3: "et/ou le dimanche et les jours fériés",
    accountPaymentPajeDeclarationSalaryComplementSpecificalHoursWarning4:
        "et parce qu'ils travaillent pendant ces horaires.",
    accountPaymentPajeDeclarationSalaryComplementSpecificalHoursLabel: "Nombre d'heures spécifiques",
    accountPaymentPajeDeclarationSalaryComplementKilometersRadioGED:
        "Avez-vous des indemnités kilométriques, frais de transport ou des indemnités de licenciement à déclarer ?",
    accountPaymentPajeDeclarationSalaryComplementKilometersRadioAMA:
        "Avez-vous des indemnités kilométriques, indemnités de repas, indemnités d'entretien ou des indemnités de rupture à déclarer ?",
    accountPaymentPajeDeclarationSalaryComplementMealRadio:
        "Avez-vous des indemnités kilométriques, indemnités de repas ou des indemnités de rupture à déclarer ?",
    accountPaymentPajeDeclarationSalaryComplementKilometers: "Indemnités kilométriques",
    accountPaymentPajeDeclarationSalaryComplementTransport: "Indemnités de transport",
    accountPaymentPajeDeclarationSalaryComplementMeal: "Indemnités de repas",
    accountPaymentPajeDeclarationSalaryComplementLayOff: "Indemnités de licenciement",
    accountPaymentPajeDeclarationSalaryComplementRupture: "Indemnités de rupture",
    accountPaymentPajeDeclarationSalaryComplementMaintenance: "Indemnités d'entretien",
    accountPaymentPajeDeclarationSalaryComplementOvertimeHoursRadio:
        "Avez-vous des heures majorées ou complémentaires à déclarer ?",
    accountPaymentPajeDeclarationSalaryComplementOvertimeComplementHours: "Nombre d'heures complémentaires",
    accountPaymentPajeDeclarationSalaryComplementOvertimeOvertimeHours: "Nombre d'heures majorées",

    accountPaymentPajeDeclarationChildrenTitle:
        "Enfant(s) gardé(s) - Merci de sélectionner le ou les enfant(s) gardé(s)",
    accountPaymentPajeDeclarationChildrenBorn: " né(e) le ",
    accountPaymentPajeDeclarationChildrenAddChild: "Vous souhaitez renseigner un ou des enfant(s) complémentaire(s)",
    accountPaymentPajeDeclarationChildrenAddChildButton: "Ajouter un enfant",
    accountPaymentPajeDeclarationChildrenPopupContent:
        "Seuls les caractères alphabétiques sont acceptés, ainsi que les espaces et tirets.",
    accountPaymentPajeDeclarationChildrenParagraphIncorrectInfos:
        "Si les informations concernant vos enfants ne sont pas correctes, merci de contacter votre CAF/MSA.",
    accountPaymentPajeDeclarationChildrenWarningStatus: childFullName =>
        "L'enfant " + childFullName + " n'ouvre aucun droit.",
    accountPaymentPajeDeclarationChildrenNoOneFound: "Veuillez sélectionner au moins un enfant.",

    accountPaymentPajeDeclarationConfirmationNotificationSuccessHeader:
        "Votre déclaration a été effectuée avec succès !",
    accountPaymentPajeDeclarationConfirmationNotificationSuccessContent: "Le numéro PAJE de votre salarié est : ",
    accountPaymentPajeDeclarationConfirmationFormPanelTitle: "Déclaration",
    accountPaymentPajeDeclarationConfirmationFormPanelIntervenantInfo1: "Votre déclaration concernant ",
    accountPaymentPajeDeclarationConfirmationFormPanelIntervenantInfo2: " a bien été transmise auprès de Pajemploi",
    accountPaymentPajeDeclarationConfirmationFormPanelDeclaredAmount: "Le montant net déclaré est de : ",
    accountPaymentPajeDeclarationConfirmationFormPanelEmployeeTotalToPay:
        "Le montant net à payer déduction faite du prélèvement à la source est de : ",
    accountPaymentPajeDeclarationConfirmationNotificationHeader: "Votre déclaration a été effectuée avec succès !",
    accountPaymentPajeDeclarationErrorAfterDate: "La date de fin doit être postérieure ou égale à la date de début.",
    accountPaymentPajeDeclarationErrorPaymentDate:
        "La date de versement doit être après la date de début de la période.",
    accountPaymentPajeDeclarationErrorMax: value => "La valeur saisie ne doit pas dépasser " + value,
    accountPaymentPajeDeclarationErrorNumberOfPaidLeavesMultiple:
        "Veuillez vérifier votre saisie. Exemple : 0.5, 1, 1.5, 2, ...",
    accountPaymentPajeDeclarationErrorOvertime:
        "Pour déclarer des heures majorées à 50%, le nombre d'heures majorées à 25% doit être suppérieur ou égal à 8",
    accountPaymentPajeDeclarationErrorPaidLeaveDates: "Les congés doivent être dans la période d'emploi",

    accountPaymentPajeDeclarationPreDeclarationGed: "GARDE A DOMICILE",
    accountPaymentPajeDeclarationPreDeclarationAma: "ASSISTANTE MATERNELLE",
    accountPaymentPajeDeclarationPreDeclarationWorkDonePanelTitle: "Travail effectué",
    accountPaymentPajeDeclarationPreDeclarationEmployedPerson: "Personne employée",
    accountPaymentPajeDeclarationPreDeclarationEmployedPeriod: "Période d'emploi",
    accountPaymentPajeDeclarationPreDeclarationEmployedPeriodDate: (startDate, endDate) =>
        "Du " + startDate + " au " + endDate,
    accountPaymentPajeDeclarationPreDeclarationPaymentDate: "Date de paiement",
    accountPaymentPajeDeclarationPreDeclarationNbHours: "Nombre d'heures effectives",
    accountPaymentPajeDeclarationPreDeclarationTotalNetSalary: "Salaire net total",
    accountPaymentPajeDeclarationPreDeclarationNbPaidLeaves: "Nombre de jours de congés payés",

    accountPaymentPajeDeclarationPreDeclarationComplementPanelTitle: "Indemnités complémentaires",
    accountPaymentPajeDeclarationPreDeclarationHourlyWage: "Salaire horaire net d'une heure normale",
    accountPaymentPajeDeclarationPreDeclarationHourlyWageInfos:
        "Si vous n’avez pas déclaré d’heure majorée ou spécifique, le montant est de 0€",
    accountPaymentPajeDeclarationPreDeclarationOvertime25: "Nombre d'heures majorées à 25 %",
    accountPaymentPajeDeclarationPreDeclarationOvertime50: "Nombre d'heures majorées à 50 %",
    accountPaymentPajeDeclarationPreDeclarationSpecificalHours: "Nombre d'heures spécifiques",
    accountPaymentPajeDeclarationPreDeclarationMileageIndemnity: "Indemnité kilométriques",
    accountPaymentPajeDeclarationPreDeclarationTransportIndemnity: "Indemnité transport",
    accountPaymentPajeDeclarationPreDeclarationLayOffIndemnity: "Indemnité de licenciement",
    accountPaymentPajeDeclarationPreDeclarationRuptureIndemnity: "Indemnité de rupture",

    accountPaymentPajeDeclarationPreDeclarationChildrePanelTitle: "Enfant(s) gardé(s)",

    accountPaymentPajeDeclarationPreDeclarationCalculatedPanelTitle: "Les montants calculés par votre déclaration",
    accountPaymentPajeDeclarationPreDeclarationTotalCotisationsAmount: "Montant des cotisations",
    accountPaymentPajeDeclarationPreDeclarationOdpfCotisationAmount:
        "Montant total des cotisations prises en charge par votre CAF ou MSA",
    accountPaymentPajeDeclarationPreDeclarationEmployerCotisationAmount: "Montant des cotisations restant à prélever",
    accountPaymentPajeDeclarationPreDeclarationTaxableWageAmount: "Salaire net imposable",
    accountPaymentPajeDeclarationPreDeclarationWithholdingPercentage: "Taux de prélèvement",
    accountPaymentPajeDeclarationPreDeclarationWithholdingAmount: "Montant de l'impôt sur le revenu à prélever",
    accountPaymentPajeDeclarationPreDeclarationEmployeeAmountAfterWithholding:
        "Salaire net à payer après prélèvement à la source",
    accountPaymentPajeDeclarationPreDeclarationEmployerAmountToEmployee:
        "Montant que vous devez verser à votre salarié",
    accountPaymentPajeDeclarationPreDeclarationCmgRemunerationAmount: "Montant du CMG Rémunération",
    accountPaymentPajeDeclarationPreDeclarationAmountToPayByPaje: "Montant en votre faveur",
    accountPaymentPajeDeclarationPreDeclarationAmountToTakeByPaje: "Montant restant à votre charge",

    // ACCOUNT PAYMENT CONFIRMATION
    accountPaymentConfirmationDetailsParagraph1: (paymentNumber, paymentDate, intervenantLabel, amount) =>
        "<p>Nous avons bien reçu votre ordre de paiement n° " +
        paymentNumber +
        " le " +
        paymentDate +
        " : " +
        "<ul>" +
        "<li> sur le compte de <b>" +
        intervenantLabel +
        " </b></li>" +
        "<li> pour un montant de <b>" +
        amount +
        " </b></li>" +
        "</ul>" +
        "</p>",
    accountPaymentConfirmationDetailsParagraph2:
        "Un accusé de réception vient de vous être envoyé à votre adresse email : ",

    accountPaymentConfirmationDelayParagraph1:
        "Votre prestataire doit obligatoirement être inscrit à l'espace de remboursement Domiserve pour recevoir un paiement.",
    accountPaymentConfirmationDelayParagraph2:
        "Le délai de paiement est variable en fonction du choix effectué par votre prestataire au moment de son affiliation.",

    accountPaymentConfirmationPDF: "Télécharger le justificatif de paiement à remettre à l'intervenant",

    // ACCOUNT PAYMENT HISTORY
    accountPaymentHistoryDetailsPanelTitle: "Détails du paiement du ",
    accountPaymentHistoryDetailsPanelMainInformations: "Informations principales",
    accountPaymentHistoryDetailsPanelIntervenant: "Intervenant",
    accountPaymentHistoryDetailsPanelPaymentDate: "Date du paiement",
    accountPaymentHistoryDetailsPanelTotalAmount: "Montant net payé",
    accountPaymentHistoryDetailsPanelPaymentStatus: "Statut du paiement",
    accountPaymentHistoryDetailsPanelPaymentDeclaration: "Rappel de la déclaration",
    accountPaymentHistoryDetailsPanelPeriodDeclared: "Période déclarée",
    accountPaymentHistoryDetailsPanelPeriodDeclaredFrom: "Du ",
    accountPaymentHistoryDetailsPanelPeriodDeclaredTo: " au ",
    accountPaymentHistoryDetailsPanelAmountDeclared: "Montant déclaré",
    accountPaymentHistoryDetailsPanelAmountContributions: "Montant des cotisations URSSAF",
    accountPaymentHistoryDetailsPanelAmountWithholding: "Montant du prélèvement à la source",

    employerObligationsParagraph1:
        "En tant que particulier employeur, pensez à déclarer les heures effectuées par votre " +
        "salarié à l'URSSAF afin de vous acquitter des cotisations sociales qui vous incombent.",
    employerObligationsParagraph2:
        "En déclarant votre salarié, vous contribuez au financement de la protection sociale et respectez les droits de votre salarié",
    employerObligationsParagraph3:
        "<p>Pour plus d'information vous pouvez consulter les sites de l'URSSAF : " +
        "<ul>" +
        "<li><a href='http://" +
        CESU_URSSAF_URL +
        "' target='_blank'>" +
        CESU_URSSAF_URL +
        "</a></li>" +
        "<li><a href='http://" +
        PAJ_EMPLOI_URSSAF +
        "' target='_blank'>" +
        PAJ_EMPLOI_URSSAF +
        "</li>",

    // CHECK PAYMENT AMOUNT
    checkPaymentAmountChoiceNoProduct: "Vous n'avez aucun titre CESU.",
    checkPaymentAmountRecap1: "Je souhaite effectuer un paiement à ",
    checkPaymentAmountRecap2: " - Pour un montant de  ",
    checkPaymentAmountConfirmation1: "Voulez-vous vraiment effectuer ce paiement ?",
    checkPaymentAmountConfirmation2:
        "L'opération de \"grattage\" a pour conséquence l'invalidation définitive du titre CESU qui n'est plus éligible pour un remboursement.",
    checkPaymentAmountConfirmation3:
        "Veuillez détruire le titre ainsi invalidé et ne pas le soumettre ou le faire soumettre par un tiers, pour remboursement via tout autre circuit.",

    checkPaymentConfirmationParagraph1: (refundNumber, refundDate) =>
        "Nous avons bien reçu votre ordre de paiement n° " + refundNumber + " le " + refundDate + " : ",
    checkPaymentConfirmationListLine1: "sur le compte de ",
    checkPaymentConfirmationListLine2: "pour un montant de ",
    checkPaymentConfirmationParagraph2:
        "Un accusé de réception vient de vous être adressé sur l'email du destinataire ",
    checkPaymentConfirmationPDF: "Télécharger le justificatif de paiement à remettre à l'intervenant",

    // CHECKBOOK STATEMENT PAGE
    checkbookStatementDate: "Relevé arrêté au ",
    checkbookStatementTitle: "Relevé des transactions",
    checkbookStatementNoAccount: "Vous n'avez aucun titre CESU.",
    checkbookStatementAccontSelectionParagraph: "Visualisez le relevé des Titres CESU ",

    // DECLARATION HISTORY PAGE
    declarationHistoryDeclarationSearchBarTitle: "Critères de recherche",
    declarationHistoryDeclarationDatePlaceholder: "Date de déclaration",
    declarationHistoryDeclarationActivityPlaceholder: "Activité salarié",
    declarationHistoryDeclarationEmployeePlaceholder: "Salarié",
    declarationHistoryDeclarationEmployeeDeclaredPlaceholder: "Salarié déclaré",
    declarationHistoryDeclarationEmployerPlaceholder: "Employeur",
    declarationHistoryDeclarationPeriodPlaceholder: "Période",
    declarationHistoryDeclarationOperationDetailsPlaceholder: "Détail de l'opération",
    declarationHistoryDeclarationDeclarationDetailsPlaceholder: "Détail de la déclaration",
    declarationHistoryDeclarationAmountPayedToEmployeePlaceholder: "Montant payé au salarié",
    declarationHistoryDeclarationDetailPlaceholder: "Détail",
    declarationHistoryDeclarationPeriodFrom: "Période de",
    declarationHistoryDeclarationPeriodTo: "à",
    declarationHistoryDeclarationTableTitle: "Toutes mes déclarations",
    declarationHistoryDeclarationDetailsTitle: "Détail de la déclaration",
    declarationHistoryDeclarationDetailsMainInformations: "Informations principales",
    declarationHistoryDeclarationDetailsDeclarationInformations: "Rappel de la déclaration",
    declarationHistoryDeclarationDetailsDeclaredPeriod: "Période déclarée",
    declarationHistoryDeclarationDetailsAmountDeclared: "Montant déclaré",
    declarationHistoryDeclarationDetailsAmountPayed: "Montant payé",
    declarationHistoryDeclarationDetailsContributionsAmount: "Montant des cotisations URSSAF",
    declarationHistoryDeclarationDetailsWittholdingAmount: "Montant du prélèvement à la source",
    declarationHistoryCesuTableHeader: "Mes déclarations faites auprès de Cesu",
    declarationHistoryPageTableHeader: "Mes déclarations faites auprès de Pajemploi",

    // Images
    logo: "",
    homeHeaderImage: "",

    backToOldWebSite: "Pour revenir sur votre espace, ",

    // HEADER
    headerTitle: "Espace bénéficiaire CESU",
    headerMenuVisualisationChoice: "Choix de visualisation",

    // MENU NAVIGATION
    menuNavMyCesuOrders: "Mes commandes de CESU",
    menuNavMyECesu: "Mon e-CESU",
    menuNavMyPaperCesu: "Mes titres CESU papier",
    menuNavMyDocuments: "Mes documents",

    // HOME PAGE
    homeHeaderTitle: "Bienvenue sur votre espace bénéficiaire",
    homeHeaderParagraph1: "",
    homeHeaderParagraph2:
        "Cet espace vous permet de précommander vos CESU Domiserve, dans un format titre papier ou compte électronique.",
    homeHeaderParagraph3:
        "Si vous avez choisi d’opter pour du Compte CESU Domiserve, cet espace vous permet également de régler vos intervenants au centime près et de consulter le solde restant sur votre compte CESU Domiserve.",
    homeHeaderParagraph4:
        "Le solde de votre Compte est insuffisant ? Vous avez la possibilité de recharger votre compte avec votre carte bancaire en toute sécurité.",
    makeOrEditAnOrderTitle: "Effectuer/Modifier une précommande",
    makeOrEditAnOrderDescription:
        "Une fois votre précommande validée par votre financeur, celle-ci sera traitée par nos services.",
    toPayIntervenantTitle: "Payer mon intervenant",
    toPayIntervenantDescription:
        "Réglez votre intervenant à l'aide de votre Compte CESU en fonction du nombre d'heures réalisées",
    toPayIntervenantSocialDescription:
        "Réglez votre intervenant à l'aide de votre Compte en fonction du nombre d'heures d'aide à domicile réalisées",

    // INTERVENANT SELECTION
    intervenantSelectionIntervenantType: "L'intervenant est : ",
    intervenantSelectionParagraph: "Je souhaite effectuer un paiement à l'attention de ",
    intervenantSelectionPhysicalIntervenant: "Une personne physique (Emploi direct gré à gré)",
    intervenantSelectionMoralIntervenant:
        "Une personne morale (Organisme prestataire, mandataire ou établissement public)",

    // INTERVENANT SEARCHING
    intervenantSearchingPhysicalIntervenantParagraph1:
        "Saisissez le code NAN et la date de naissance de l'intervenant à payer : ",
    intervenantSearchingPhysicalIntervenantNanCodeSubPlaceholder:
        "Saisissez le code (7 chiffres), sans l'étoile ni la clé NAN",
    intervenantSearchingPhysicalIntervenantNanCode:
        "Le code NAN est le numéro d'identifiant de l'intervenant. Il l'a reçu du CRCESU (Centre de Remboursement des CESU) après s'y être affilié",
    intervenantSearchingPhysicalIntervenantAffiliation: "Affiliation d'un intervenant personne physique",
    intervenantSearchingMoralIntervenantAffiliation: "Affiliation d'un intervenant personne morale",
    intervenantSearchingIntervenantAffiliationParagraph1:
        "Si votre intervenant n’est pas encore affilié au CRCESU, il peut le faire sur le site du ",
    intervenantSearchingIntervenantAffiliationParagraph2: "CRCESU",
    intervenantSearchingIntervenantAffiliationParagraph3:
        ". Dès le lendemain, vous pourrez réaliser un paiement CESU à son intention ",
    intervenantSearchingMoralIntervenantParagraph1:
        "Si vous le connaissez, saisissez le code NAN de l'intervenant à payer, sinon précisez uniquement sa raison sociale et le département.",
    intervenantSearchingMoralIntervenantMemberParagraph1: "Attention: ",
    intervenantSearchingMoralIntervenantMemberParagraph2:
        "Le prestataire a besoin d'être adhérent à Domiserve pour pouvoir recevoir le paiement. S'il n'est pas adhérent le paiement sera mis en attente.",
    intervenantSearchingImportantParagraph1:
        "Important, le paiement en Titres CESU dématérialisés s'effectue uniquement auprès des intervenants : personnes physiques.",
    intervenantSearchingImportantParagraph2:
        "Pour le paiement des structures collectives de garde d'enfants, une remise des titres physiques doit être faite directement.",
    intervenantSearchingImportantParagraph3:
        "Pour le règlement dématérialisé auprès d'intervenants personnes morales et physiques, nous vous invitons à transformer vos titres en Compte CESU électronique.",
    // INTERVENANT COMMENT
    intervenantCommentParagraph1:
        "Je souhaite que le commentaire suivant soit envoyé à l'intervenant en même temps que le paiement :",
    intervenantCommentParagraph2: "(Par exemple: en paiement de la facture numéro 123456789)",

    // LOGIN PAGE
    // Texts
    loginCreateAccount1: "Je ne suis pas encore bénéficiaire",
    loginCreateAccount2: "et souhaite m'inscrire à l'espace.",
    loginContact: "Vous souhaitez plus d'informations, ",
    loginContactLink: "contactez-nous",
    loginLeftImage: undefined,
    loginRightImage: undefined,
    loginFooter: undefined,
    loginForgottenPassword: "Mot de passe oublié ?",

    // RESET PASSWORD PAGE
    resetPasswordTokenText: "Cette opération a déjà été réalisée. Lien expiré.",
    resetPasswordSuccessText: "Votre mot de passe a bien été changé. Vous allez être redirigé dans 5 secondes.",

    // VISUALISATION CHOICE PAGE
    visualisationChoiceTopText:
        "Nous vous avons identifié comme bénéficiaire de plusieurs entités. Nous vous invitons à vous connecter sur l'espace bénéficiaire correspondant au financeur de votre choix en sélectionnant l'un des choix suivants :",
    visualisationChoiceBottomText:
        "En cas de difficulté, contactez votre conseiller Domiserve au numéro qui vous a été communiqué dans le cadre de votre dispositif CESU.",

    // CESU ACCOUNT COMPONENT
    cesuAccountsTableTitle: "Mon Compte CESU",
    cesuAccountsTableNoAccount:
        "Votre Compte CESU n’est pas crédité. Un email vous sera envoyé lorsque le plan d’aide aura été versé sur votre Compte.",

    // CESU CHECKS COMPONENT
    cesuChecksTableTitle: "Mes Titres CESU",

    // CESU CONVERSION CHOICE PAGE
    cesuConversionChoiceTitle: "Format de vos CESU à échanger",
    cesuConversionChoiceSelection: "Sélectionnez",
    cesuConversionChoiceNothingToConvert: "Vous n'avez pas de titres ou de comptes pouvant être changés de format",

    cesuConversionAmountAccountSelection: "Sélectionnez le compte CESU que vous souhaitez changer en titre papier",
    cesuConversionAmountNoAccount: "Vous n'avez pas de compte éligible à la transformation",
    cesuConversionAmountAccountForAmount: "Pour un montant de ",
    cesuConversionAmountAccountCheckbookCreationSelection: year =>
        "Veuillez sélectionner la valeur des titres CESU de votre chéquier du <b>millésime " + year + "</b>",
    cesuConversionAmountAccountCheckbookValue: (nbCheckbooks, total) =>
        "Votre chéquier sera constitué de " + nbCheckbooks + " CESU d'une valeur de " + total,

    cesuConversionAmountCheckbookSelection: "Sélectionner vos titres CESU à changer",
    cesuConversionAmountCheckbookTotalByProduct: date =>
        "Total du montant de titres CESU utilisables au " + date + "* : ",
    cesuConversionAmountCheckbookSelectionTotalInfo:
        "*Total des titres émis et non consommés, sous réserve que les titres ne soient pas en cours de remboursement",
    cesuConversionAmountCheckbookTotalSelected: "Montant total des titres sélectionnés:",
    cesuConversionAmountCheckbookAccountValue: (year, total) =>
        "Je souhaite changer mes titres CESU en Compte de <b>millésime " +
        year +
        "</b> pour un montant de <b>" +
        total +
        "</b>",

    // CESU CONVERSION DELIVERY PAGE
    cesuConversionDeliveryAsterisksPargraph1: "* Champs obligatoires",
    cesuConversionDeliveryAsterisksPargraph2: "** Au moins une des 3 zones d'adresse est obligatoire",

    // CESU CONVERSION CONFIRMATION PAGE
    cesuConversionConfirmationParagraph1: "Récapitulatif de votre demande de changement de format de CESU",
    cesuConversionConfirmationParagraph2: "Nous vous remercions de votre confiance.",
    cesuConversionConfirmationParagraph3: "L'équipe relation clients Domiserve",

    cesuConversionConfirmationAccountToCheckParagraph1: (millesime, amount, email) =>
        "<br/>Votre demande de changement de format <b> Compte CESU " +
        millesime +
        " en chéquier CESU " +
        millesime +
        "</b> pour une valeur de <b>" +
        amount +
        "</b> est maintenant terminée. Une email de confirmation vient de vous être envoyé à l'adresse suivante : <b>" +
        email +
        "</b>",
    cesuConversionConfirmationAccountToCheckParagraph2:
        "Vous recevrez votre nouveau Chéquier CESU par courrier à l'adresse indiquée dans un délai de 10 jours.",

    cesuConversionConfirmationCheckToAccountParagraph1: (millesime, amount, email) =>
        "<br/>Votre demande de changement de format de <b>Titres CESU " +
        millesime +
        " en Compte CESU " +
        millesime +
        "</b> pour une valeur de <b>" +
        amount +
        "</b> est maintenant terminée. Un email de confirmation vient de vous être envoyé à l'adresse suivante : <b>" +
        email +
        "</b>",
    cesuConversionConfirmationCheckToAccountParagraph2: millesime =>
        "Vous recevrez un email confirmant l'alimentation de votre Compte CESU " +
        millesime +
        " dans un délai de 3 jours.",

    // MILLESIME TRADE CHOICE
    millesimeTradeChoiceAccount: "Votre compte CESU à échanger",
    millesimeTradeChoiceNoAccount: "Vous ne possédez aucun compte CESU à échanger.",
    millesimeTradeChoiceAccountSelection: "Sélectionnez votre Compte CESU à échanger :",
    millesimeTradeChoiceTradeType: "Choix du type d'échange",
    millesimeTradeChoiceNewCheckbookPanel: "Votre chéquier CESU du nouveau millésime",
    millesimeTradeChoiceNewCheckbookFacialValue: accountMillesime =>
        "Veuillez sélectionner la valeur des titres de votre chéquier du millésime " + (accountMillesime + 1) + " :",
    millesimeTradeChoiceNewCheckbookComposition: "Votre chéquier sera constitué de : ",
    millesimeTradeChoiceNewCheckbookValue: " CESU d'une valeur de ",
    homeTitle: "Prolongez la durée de vie de vos CESU Domiserve !",
    homeOutsidePeriodParagraph1:
        "Le service Domi-Echange est accessible uniquement durant le mois de Février de chaque année.",
    homeOutsidePeriodParagraph2: "Rendez-vous lors de la prochaine période d'échange.",

    // MILLESIME TRADE DELIVERY
    millesimeTradeDeliveryTradeRequest: "Votre demande d'échange",

    // MILLESIME TRADE PAYMENT
    millesimeTradePaymentError: "Erreur lors du calcul des frais",
    millesimeTradePaymentFees: tradeFees => {
        if (tradeFees && tradeFees.feeType === TradeFeeTypes.FORFEIT) {
            return "Les frais pour l'échange de votre Compte CESU s'élèvent à " + tradeFees.totalFees + " € TTC."
        } else if (tradeFees && tradeFees.feeType === TradeFeeTypes.PROPORTIONAL) {
            return (
                "Les frais pour l'échange de votre Compte CESU s'élèvent à un montant égal à " +
                tradeFees.feeRate +
                " % de la valeur totale échangée soit " +
                tradeFees.totalFees +
                " € TTC."
            )
        } else {
            return "Aucun frais à payer"
        }
    },

    // MILLESIME TRADE CONFIRMATION
    millesimeTradeConfirmationResume: "Récapitulatif de votre demande d'échange de millésime",
    millesimeTradeConfirmationIntroduction: (tradeType, oldMillesime, millesime, totalAmount, email, supportType) => {
        let text = ""
        if (tradeType === ProductTypes.CHECKBOOK) {
            text =
                "Votre demande d'échange de Compte CESU " +
                oldMillesime +
                " en chéquier CESU " +
                millesime +
                " d'une valeur totale de " +
                totalAmount +
                (supportType === SupportTypes.BENEFICIARY
                    ? " € est maintenant terminée. Un email de confirmation avec votre facture, vient de vous être adressé à l'adresse "
                    : " € est maintenant terminée. Un email de confirmation vient de vous être adressé à l'adresse ") +
                email +
                "."
        }
        if (tradeType === ProductTypes.ACCOUNT) {
            text =
                "Votre demande d'échange de Compte CESU " +
                oldMillesime +
                " en Compte CESU " +
                millesime +
                " d'une valeur totale de " +
                totalAmount +
                (supportType === SupportTypes.BENEFICIARY
                    ? " € est maintenant terminée. Un email de confirmation avec votre facture, vient de vous être envoyé à l'adresse "
                    : " € est maintenant terminée. Un email de confirmation vient de vous être adressé à l'adresse ") +
                email +
                "."
        }
        return text
    },
    millesimeTradeConfirmationParagraph: (supportType, tradeType, millesime) => {
        let text = ""
        if (supportType === SupportTypes.BENEFICIARY && tradeType === ProductTypes.CHECKBOOK) {
            text =
                "Vous recevrez votre nouveau chéquier CESU par courrier à l'adresse indiquée dans un délai de 10 jours."
        }
        if (supportType === SupportTypes.BENEFICIARY && tradeType === ProductTypes.ACCOUNT) {
            text =
                "Vous recevrez un mail confirmant l'ouverture de votre Compte CESU " +
                millesime +
                " dans un délai de 3 jours ouvrés."
        }
        if (supportType === SupportTypes.CUSTOMER && tradeType === ProductTypes.CHECKBOOK) {
            text =
                "Vous recevrez votre nouveau chéquier CESU courant Mars, par courrier, après validation de votre financeur."
        }
        if (supportType === SupportTypes.CUSTOMER && tradeType === ProductTypes.ACCOUNT) {
            text =
                "Vous recevrez un mail confirmant l'ouverture de votre Compte CESU " +
                millesime +
                " après validation de votre demande par votre financeur."
        }
        return text
    },
    millesimeTradeConfirmationGreetings: "Nous vous remercions de votre confiance.",
    millesimeTradeConfirmationDownloadButton: "Télécharger la facture",

    // ORDER SESSION LIST PAGE
    orderSessionListTitle: "Sessions ouvertes",
    orderSessionListParagraph: "Sélectionnez la session sur laquelle vous souhaitez effectuer une précommande.",

    // ORDER CONTENT PAGE
    orderContentAmountLimit: "Je peux commander jusqu'à $$maxOrder$$ € de CESU",
    orderContentAmountWanted: "Je souhaite commander",
    orderContentAmountCESU: "€ de CESU",
    orderContentAmountCheckbooks: "€ de Titre CESU papier",
    orderContentAmountAccount: "€ de Compte CESU électronique",
    orderContentFixedFacialValue: fixedFacialValue =>
        "La valeur faciale des titres est fixée à " + fixedFacialValue + " €",
    orderContentFixedFacialValues: "Je fixe la valeur faciale des titres à ",
    orderContentReason: "Motif de la commande *",

    orderContentBeneficiaryPart: "Part à ma charge",
    orderContentCustomerPart: "Part financée",

    // ORDER PAYMENT CASES
    paymentCasesParagraph: paymentMode => {
        let text = ""
        switch (paymentMode) {
            case PaymentModes.CREDIT_CARD: {
                text = "Redirection vers notre solution de paiement sécurisée"
                break
            }
            case PaymentModes.CHECK: {
                text =
                    "<p>" +
                    "<b>Important</b>" +
                    "<br/><br/>" +
                    "Pour que votre demande soit traitée dans les délais prévus, nous vous remercions de bien vouloir indiquer vos " +
                    "<b>Nom, prénom</b> au dos du chèque (si le titulaire du chèque est différent), de le libeller à l'ordre de " +
                    "<b>DOMISERVE</b> et de nous l'envoyer par courrier à l'adresse suivante :"
                break
            }
            case PaymentModes.TRANSFER: {
                text =
                    "<b>Important</b>" +
                    "<br/><br/>" +
                    "Pour que votre demande soit traitée dans les délais prévus, nous vous remercions de bien vouloir " +
                    "indiquer les informations suivantes dans le motif de virement :"
                break
            }
            default:
                return ""
        }
        return text
    },

    paymentModes: "Modes de règlement",

    // ORDER CONFIRMATION PAGE
    orderConfirmationIntroduction: (orderType, paymentMode, totalTitleAmount) => {
        switch (orderType) {
            case Order.NEW_ORDER: {
                return (
                    "Nous vous confirmons que votre précommande a bien été enregistrée. " +
                    ((paymentMode === PaymentModes.TRANSFER || paymentMode === PaymentModes.CHECK) &&
                    totalTitleAmount != null &&
                    totalTitleAmount > 0
                        ? "Si vous réglez par chèque ou virement, celle-ci vous sera envoyée sous 10 jours après validation de votre paiement."
                        : "Celle-ci vous sera envoyée (ou créditée) après validation de votre financeur.")
                )
            }
            case Order.EDIT_ORDER: {
                return "La modification de votre précommande a bien été enregistrée."
            }
            case Order.CANCEL_ORDER: {
                return "Nous vous confirmons l'annulation de la précommande suivante :"
            }
            default:
                return ""
        }
    },

    orderConfirmationsTitleOrAccountParagraph: (
        deliveryType,
        deliveryPointLabel,
        totalAccountAmount,
        totalAccountFees,
        totalTitleAmount,
        totalTitleFees,
    ) => {
        let text = ""
        if (totalTitleAmount != null && totalTitleAmount > 0) {
            text +=
                "<p>" +
                "Montant précommandé : <b>" +
                Number(totalTitleAmount).toFixed(2) +
                " €</b>" +
                "<br/>" +
                "Montant à votre charge : <b>" +
                totalTitleFees +
                "  €</b>" +
                "<br/>" +
                "Type de CESU précommandé : <b>Titres CESU (version papier)</b>" +
                "<br/>" +
                "Livraison : <b>" +
                (deliveryType === BeneficiaryDeliveryTypes.OTHER_POINT_DELIVERY
                    ? "A une adresse tierce"
                    : deliveryType === BeneficiaryDeliveryTypes.HOME_DELIVERY
                    ? "A l'adresse du bénéficiaire"
                    : deliveryType === BeneficiaryDeliveryTypes.DELIVERY_POINT_DELIVERY
                    ? "Au point de livraison " + deliveryPointLabel
                    : "") +
                "</b></p>"
        }
        if (totalAccountAmount != null && totalAccountAmount > 0) {
            text +=
                "<p>" +
                "Montant précommandé : <b>" +
                Number(totalAccountAmount).toFixed(2) +
                " €</b>" +
                "<br/>" +
                "Montant à votre charge : <b>" +
                totalAccountFees +
                " €</b>" +
                "<br/>" +
                "Type de CESU précommandé : <b>Compte CESU (version électronique)</b>" +
                "<br/>" +
                "Livraison : <b>Virement sur votre Compte CESU</b>" +
                "</p>"
        }
        return text
    },

    orderConfirmationModificationParagraph: (totalAccountAmount, totalTitleAmount) => {
        if (totalTitleAmount != null && totalTitleAmount > 0) {
            return "Nous vous ferons parvenir vos CESU après validation de votre précommande par votre financeur."
        }
        if (totalAccountAmount != null && totalAccountAmount > 0) {
            return "Votre compte sera alimenté après validation de votre précommande par votre financeur."
        }
    },

    orderConfirmationFinalParagraph: (beneficiaryMail, orderType) => {
        switch (orderType) {
            case Order.NEW_ORDER: {
                return (
                    "Une confirmation de commande vous a été envoyée à l'adresse email que vous avez renseignée : " +
                    "<b>" +
                    beneficiaryMail +
                    "</b>"
                )
            }
            case Order.EDIT_ORDER: {
                return (
                    "Une confirmation de modification de commande vous a été envoyée à l'adresse email que vous avez renseignée : " +
                    "<b>" +
                    beneficiaryMail +
                    "</b>"
                )
            }
            case Order.CANCEL_ORDER: {
                return (
                    "Une confirmation d'annulation de commande vous a été envoyée à l'adresse email que vous avez renseignée : " +
                    "<b>" +
                    beneficiaryMail +
                    "</b>"
                )
            }
            default:
                break
        }
    },
    orderConfirmationTitle: "Confirmation de précommande",

    // ORDER HISTORY
    orderHistoryTitle: "Historique",

    // ACCOUNT PAYMENT HISTORY
    accountPaymentHistoryTitle: "Historique des paiements",

    // DECLARATION HISTORY
    declarationHistoryTitle: "Historique des déclarations",

    // ORDER SESSION LIST
    orderSessionListCancelParagraph: "Êtes-vous certain de vouloir annuler votre commande ? ",

    // REGISTRATION PAGE
    registrationSessionChoicePanelTitle: "Sélection d'une session",
    registrationSessionChoicePanelParagraph1: "Je m'inscris à l'Espace Bénéficiaire.",
    registrationSessionChoicePanelParagraph2: "Pour vous inscrire veuillez choisir une session de précommande.",
    registrationSessionChoicePanelParagraph2RH:
        " Attention, pour vous inscrire, la session de précommande doit être ouverte.",
    registrationSessionChoicePanelParagraph3: "Je souhaite précommander à la session : ",
    registrationSessionChoicePanelNoSession:
        "Aucune session n’a été trouvée. Veuillez vérifier auprès de votre entreprise qu’une session a bien été créée.",

    registrationControlPanelTitle: "Renseignements préalables à l'inscription",
    registrationControlPanelCesuParagraph1: "Nous vous rappelons que le code CESU est constitué de 8 chiffres",
    registrationControlPanelCesuParagraph2: "Il figure sur le talon de chaque chèque papier.",
    registrationControlPanelRGPDParagraph1:
        "Les informations recueillies seront exploitées par nos équipes afin de vous permettre de bénéficier de la prestation attendue. ",
    registrationControlPanelRGPDParagraph2: "Pour plus de détail ",
    registrationControlPanelRGPDParagraph2Mentions: "mentions d'informations RGPD.",

    registrationProfileAutorisationsTitle: "Autorisations",
    registrationProfileActivationCesuTitle: "Activation de votre Espace Bénéficiaire",
    registrationProfileActivationCesuPaymentChoice: "Choix du mode de versement du CESU : *",
    registrationProfileActivationCesuPaymentChoice1: "Titre CESU papier (chéquier)",
    registrationProfileActivationCesuPaymentChoice2: "Compte CESU Internet",
    registrationProfileActivationCesuParaph1:
        "Le Compte CESU Internet vous permet de recevoir le prochain montant de votre plan d'aide sur votre Espace Bénéficiaire, accessible par Internet. Grâce à cet espace, vous pourrez régler votre salarié par un simple virement bancaire.",
    registrationProfileAlreadyRegisteredTitle: "Vous êtes déjà inscrit(e) sur cet espace",
    registrationProfileAlreadyRegisteredStandardContractContent:
        "Souhaitez vous recevoir votre identifiant par email ?",
    registrationProfileAlreadyRegisteredOtherContractContent:
        "Nous vous invitons à cliquer sur « Mot de passe oublié » si vous avez oublié votre mot de passe ou à contacter nos services en cas d’oubli de votre identifiant.",
    registrationProfileAlreadyRegisteredVerificationErrorTitle: "Erreur lors de la vérification d'inscription",
    registrationProfileRecoverLoginBeneficiaryAreaTitle: "Email envoyé",
    registrationProfileRecoverLoginBeneficiaryAreaContent:
        "Un email vient d'être envoyé avec votre identifiant à l'adresse suivante : ",
    registrationProfileRecoverLoginBeneficiaryAreaErrorTitle: "Erreur lors l'envoi de votre identifiant par email",

    registrationPasswordPanelTitle: "Mot de passe",
    registrationPasswordPanelParagraph1: "Créer votre mot de passe",

    registrationConfirmationParagraph1: "Merci, votre inscription à l'Espace Bénéficiaire a bien été prise en compte.",
    registrationConfirmationParagraph2: "Votre identifiant vous a été envoyé à votre adresse mail renseignée : ",
    registrationConfirmationParagraphPostalCase: "Votre identifiant vous a été envoyé par courrier postal.",

    registrationConfirmationAlreadyRegisteredTitle: "Votre profil est déjà enregistré auprès de nos services :",
    registrationConfirmationAlreadyRegisteredParagraph1:
        "Si vous avez perdu votre identifiant merci de contacter le service client par mail à serviceclients@domiserve.com",
    registrationConfirmationAlreadyRegisteredParagraph2:
        "Votre identifiant vous sera renvoyé par courrier à la dernière adresse connue de votre employeur.",

    // SOCIAL ACCOUNT TABLE
    socialAccountTableAmount2: ", soit un montant de ",
    socialAccountTableAmount: "Un montant de ",
    socialAccountTableColumn1: "Financeur",
    socialAccountTableColumn2: "Type de prestation",
    socialAccountTableColumn3: date => "Nombre d'heures restantes au " + date,
    socialAccountTableColumn4: "Solde",
    socialAccountTableColumn5: "Commentaire",
    socialAccountTableInfo1: "Les heures de prestation sont affichées en heures et centièmes.",
    socialAccountTableInfo2: "Par exemple : ",
    socialAccountTableInfo3: "1,5 heure courrespond à 1h30",
    socialAccountTableInfo4: "2,75 heures correspondent à 2h45",
    socialAccountTableInfoTitle: "Votre Plan d'Aide actuellement actif ",
    socialAccountTableMonth: "Plan du mois de ",

    // USER PROFILE PAGE
    // Texts
    mailOffers:
        "J'accepte de recevoir des offres commerciales, par voie électronique, de la part de Domiserve, " +
        "des sociétés du Groupe auquel elle appartient, ses filiales, ses sous-traitants, partenaires et prestataires",
    postalOffersRefusal:
        "Je refuse de recevoir des offres commerciales de la part de Domiserve, des sociétés du Groupe auquel elle appartient," +
        " ses filiales, ses sous-traitants, partenaires et prestataires par courrier postal et via des appels téléphoniques",

    // USER PROFILE CONNECTION INFORMATION
    profileChangingPasswordSuccess: "Votre mot de passe a été modifié avec succès.",

    // USER PROFILE DECLARATION INFORMATIONS
    profileDeclarationInformationTitle: "Vos informations de déclaration",
    profileDeclarationInformationsCESU: "N° CESU Employeur",
    profileDeclarationInformationsPAJE: "N° Pajemploi Employeur",
    profileDeclarationInformationMandateCheckbox1: "Je donne ",
    profileDeclarationInformationMandateCheckbox2: "mandat",
    profileDeclarationInformationMandateCheckbox3:
        " à Domiserve pour transmettre ma déclaration CESU de manière concomitante au paiement du salaire de mon Intervenant",
    profileDeclarationActivationNotificationCesu:
        "Votre demande de notification de mandat CESU a bien été prise en compte.",
    profileDeclarationCancellationNotificationCesu:
        "Votre demande d'annulation de mandat a bien été prise en compte pour le(s) numéro(s) CESU Employeur suivant(s) : ",
    profileDeclarationActivationNotificationPaje:
        "Votre demande de notification de mandat Pajemploi a bien été prise en compte.",
    profileDeclarationCancellationNotificationPaje:
        "Votre demande d'annulation de mandat a bien été prise en compte pour le(s) numéro(s) Pajemploi Employeur suivant(s) : ",

    // VIEW ORDER PAGE
    // Texts
    viewOrderTitle: "Détails de la commande",
    viewOrderDetailsTitle: "Informations principales",
    viewOrderOverviewTitle: "Récapitulatif de la commande",
    viewOrderReasonTitle: "Motif",

    // RGPD
    rgpdTitle: "Protection des données à caractère personnel",
    rgpdParagraphe1:
        "Les données à caractère personnel recueillies font l'objet d'un traitement dont le responsable est Domiserve conformément à la réglementation relative à la protection des données à caractère personnel.",
    rgpdParagraphe2:
        "Elles sont traitées pour utiliser l'espace bénéficiaire CESU de Domiserve. Elles pourront également dans certain cas le nécessitant être traitées pour réaliser des actes de gestion (comme la génération d'attestation fiscale par exemple) ou dans la gestion de contentieux.",
    rgpdParagraphe3:
        "Les données sont traitées en vertu de l'exécution du contrat visant à l'utilisation de l'espace bénéficiaire CESU Domiserve. Ces données seront conservées pendant 10 ans à compter de l'émission du CESU préfinancé.",
    rgpdParagraphe4:
        "Les données à caractère personnel collectées sont obligatoires afin de bénéficier des services accessibles sur l'espace bénéficiaire CESU Domiserve (effectuer un paiement, consulter l'historique des paiements réalisés, consulter le compte CESU). A défaut, vous ne pourrez pas utiliser les services disponibles sur l'espace bénéficiaire CESU Domiserve.",
    rgpdParagraphe5:
        "Dans certains cas, les données concernant la création de votre compte CESU (civilité, nom, prénom, date de naissance, email, adresse) peuvent avoir été collectées auprès de votre financeur.",
    rgpdParagraphe6:
        "Elles sont destinées à Domiserve et pourront être communiquées à ses sous-traitants ou partenaires pour les traitements et finalités cités ci-avant. Elles pourront également être communiquées à toute autorité administrative ou judiciaire habilitée ou plus généralement à tout tiers autorisé, pour satisfaire à ses obligations légales ou réglementaires.",
    rgpdParagraphe7:
        "Vous pouvez à tout moment dans les conditions prévues par la loi, accéder à vos données à caractère personnel, vous opposer pour un motif tenant à votre situation particulière à leur traitement ou vous opposer sans motif au traitement de vos données à caractère personnel dans le cadre des opérations de prospection commerciale, faire rectifier vos données à caractère personnel, demander leur effacement sauf si elles sont nécessaires à l'exécution des services ou au respect d'obligations légales ou pour permettre à Domiserve de constater ou d'exercer ses droits, la limitation de leur traitement, ou leur portabilité pour les données que vous avez fournies et qui sont nécessaires au contrat ou au traitement auquel vous avez consenti. ",
    rgpdParagraphe7b:
        "Vous pouvez à tout moment retirer votre consentement lorsque celui-ci a été préalablement donné. Vous pouvez aussi donner des instructions relatives à la conservation, à l'effacement et à la communication de vos données après votre décès. Ces droits peuvent être exercés, en précisant vos nom, prénom, adresse postale et en joignant une copie recto-verso de votre pièce d'identité, en vous adressant, soit par courrier à Domiserve - Exercice de droits RGPD - 106 avenue Marx Dormoy - 92120 Montrouge soit par mail à ",
    rgpdParagraphe8:
        "Domiserve a désigné un Délégué à la protection des données dont les coordonnées sont : Délégué à la Protection des Données de La Banque Postale - 115, rue de Sèvres - 75275 Paris Cedex 06.",
    rgpdParagraphe9:
        "En cas de difficulté en lien avec la gestion de vos données à caractère personnel, vous avez le droit d'introduire une réclamation auprès de la Commission Nationale de l'Informatique et des Libertés (CNIL).",
}

/**
 * Override default texts for small client.
 */
export const defaultTextsSmallClient = {
    // HOME PAGE
    homeHeaderTitle: "Bienvenue sur votre espace bénéficiaire",
    homeHeaderParagraph1: "",
    homeHeaderParagraph2:
        "Cet espace vous permet de précommander vos CESU Domiserve, dans un format titre papier ou compte électronique.",
    homeHeaderParagraph3:
        "Si vous avez choisi d’opter pour du Compte CESU Domiserve, cet espace vous permet également de régler vos intervenants au centime près et de consulter le solde restant sur votre compte CESU Domiserve.",
    homeHeaderParagraph4:
        "Le solde de votre Compte est insuffisant ? Vous avez la possibilité de recharger votre compte avec votre carte bancaire en toute sécurité.",
}

/**
 * Override default texts for social contract
 */
export const defaultTextsSocialContract = {
    // HOME PAGE
    homeHeaderTitle: "Bienvenue sur votre Compte dématérialisé",
    homeHeaderParagraph1: "",
    homeHeaderParagraph2:
        "Le Compte dématérialisé vous permet de recevoir le montant de votre plan d’aide sur votre Espace Bénéficiaire, accessible par internet.",
    homeHeaderParagraph3: "Grâce à cet espace, vous pourrez régler votre salarié par un simple virement bancaire.",
    homeHeaderParagraph4:
        "Vous pourrez suivre en temps réel l'état de votre compte à l'aide de votre historique des versements et passer des ordres de paiement vers les comptes bancaires de vos intervenants.",
}

let texts = {...defaultTexts}

export function updateGlobalTexts(newTexts) {
    texts = Object.assign(texts, newTexts)
}

export default texts
