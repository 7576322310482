import PropTypes from "prop-types"
import React, {useMemo} from "react"
import {useTheme} from "react-jss"
import {useDispatch, useSelector} from "react-redux"
import {useHistory} from "react-router-dom"
import {LOGO} from "../constants/imageDefault"
import {menuContent} from "../constants/menu/menu"
import {Page} from "../constants/page/pages"
import {PagesArchitecture} from "../constants/page/pagesArchitecture"
import {VisualisationModes} from "../constants/visualisationModes"
import {saveCookiesChoices} from "../shared-components/actions/localStorage/cookies/cookiesActions"
import {disconnectUser, logoutUser} from "../shared-components/common/actions/user/userActions"
import Footer from "../shared-components/components/FooterNew/footerNew"
import Header from "../shared-components/components/Header/header"
import LightHeader from "../shared-components/components/LightHeader/lightHeader"
import ResponsiveLogo from "../shared-components/components/ResponsiveLogo/responsiveLogo"
import SideMenu from "../shared-components/components/SideMenu/sideMenu"
import SideMenuMobile from "../shared-components/components/SideMenuMobile/sideMenuMobile"
import {BENEFICIARY_AREA} from "../shared-components/constants/areaTypes"
import {DOMISERVE_CONTACT_URL} from "../shared-components/constants/links"
import {useIsResponsive} from "../shared-components/hooks/useIsResponsive"
import {useTexts} from "../shared-components/hooks/useTexts"
import ContentLayout from "../shared-components/layouts/contentLayout"
import FixedLayout from "../shared-components/layouts/fixedLayout"
import SideMenuLayout from "../shared-components/layouts/sideMenuLayout"
import {getImageFromRemotePath} from "../shared-components/services/filer"
import {wasAuthenticationBypassed} from "../store/selectors/localStorage/bypassedAuthenticationSelectors"
import {getEnabledFeatures} from "../store/selectors/localStorage/featuresSelectors"
import {getSelectedContract, getVisualisationMode} from "../store/selectors/localStorage/visualisationSelectors"
import {getActualPage} from "../store/selectors/temporaries/pageSelectors"
import colors from "../styles/_colors.module.scss"
import sizes from "../styles/_sizes.module.scss"
import {
    getCesuRhContracts,
    getCesuSocialContracts,
    isContractWittholdingAllowed,
} from "../store/selectors/localStorage/contractsSelectors"
import {
    getUserFullname,
    isFromBackOffice,
    isReadOnly,
    isUserConnected,
} from "../store/selectors/sessionStorage/userSelectors"

const ConnectedLayout = ({children}) => {
    const dispatch = useDispatch()
    const isResponsive = useIsResponsive()
    const history = useHistory()
    const texts = useTexts()
    const theme = useTheme()

    const actualPage = useSelector(state => getActualPage(state))
    const isConnected = useSelector(state => isUserConnected(state))
    const withBypassedAuthentication = useSelector(state => wasAuthenticationBypassed(state))
    const userFullName = useSelector(state => getUserFullname(state))
    const visualisationMode = useSelector(state => getVisualisationMode(state))
    const cesuRhContracts = useSelector(state => getCesuRhContracts(state))
    const cesuSocialContracts = useSelector(state => getCesuSocialContracts(state))
    const isContractWithHoldingAllowed = useSelector(state =>
        isContractWittholdingAllowed(state, getSelectedContract(state)),
    )
    const fromBackOffice = useSelector(state => isFromBackOffice(state))
    const readOnly = useSelector(state => isReadOnly(state))
    const features = useSelector(state => getEnabledFeatures(state))

    const personnalisationLogo = useMemo(() => getImageFromRemotePath(texts.logo, BENEFICIARY_AREA, LOGO), [texts.logo])

    const onContactClick = () => window.open(DOMISERVE_CONTACT_URL)
    const onCookiesClick = () => dispatch(saveCookiesChoices({isModalOpened: true, step: "CONFIGURATION"}))

    // On affiche l'option de choix de visualisation si le choix est possible
    let displayVisualisationChoice
    if (withBypassedAuthentication || actualPage === Page.VISUALISATION_CHOICE) {
        displayVisualisationChoice = false
    } else {
        if (!Array.isArray(cesuRhContracts) || !Array.isArray(cesuSocialContracts)) {
            displayVisualisationChoice = false
        } else {
            const activeCesuRhContracts = cesuRhContracts.filter(contract => contract.contractActivated)
            const activeCesuSocialContracts = cesuSocialContracts.filter(contract => contract.contractActivated)

            displayVisualisationChoice =
                (activeCesuRhContracts.length >= 1 && activeCesuSocialContracts.length >= 1) ||
                (activeCesuRhContracts.length === 0 && activeCesuSocialContracts.length > 1)
        }
    }

    // Les liens avec icône, à droite dans le header (pages connectées)
    const headerButtonLinks = useMemo(
        () => [
            ...(visualisationMode === VisualisationModes.CESU_RH
                ? [
                      {
                          icon: "fa-solid fa-circle-question",
                          label: texts.headerHelp,
                          onClick: () => history.push(PagesArchitecture[Page.HELP].route),
                      },
                  ]
                : []),
        ],
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [history, visualisationMode],
    )

    // Les liens dans le menu popup du header (pages connectées)
    const headerMenuLinks = [
        ...(!withBypassedAuthentication
            ? [
                  {
                      label: texts.headerMyProfile,
                      onClick: () => history.push(PagesArchitecture[Page.USER_PROFILE_MAIN_INFORMATIONS].route),
                  },
              ]
            : []),
        ...(displayVisualisationChoice
            ? [
                  {
                      label: texts.headerMenuVisualisationChoice,
                      onClick: () => history.push(PagesArchitecture[Page.VISUALISATION_CHOICE].route),
                  },
              ]
            : []),
        {
            label: texts.headerLogout,
            onClick: () => {
                dispatch(logoutUser())
                dispatch(disconnectUser())
                history.push(PagesArchitecture[Page.LOGIN].route)
            },
        },
    ]

    // Les éléments du footer (pages connectées)
    const footerItems = [
        ...(!withBypassedAuthentication && visualisationMode
            ? [
                  {
                      label: PagesArchitecture[Page.LEGAL_NOTICE].breadcrumbText,
                      page: Page.LEGAL_NOTICE,
                      route: PagesArchitecture[Page.LEGAL_NOTICE].route,
                  },
              ]
            : []),
        {
            label: PagesArchitecture[Page.RGPD].breadcrumbText,
            page: Page.RGPD,
            route: PagesArchitecture[Page.RGPD].route,
        },
        {
            label: texts.cookiesFooterLabel,
            onClick: onCookiesClick,
            page: null,
            route: PagesArchitecture[actualPage].route,
        },
        {
            label: texts.contactFooterLabel,
            onClick: onContactClick,
            page: null,
            route: PagesArchitecture[actualPage].route,
        },
    ]

    const menuProps = {
        actualPage: actualPage,
        items: menuContent({
            texts,
            visualisationMode,
            isContractWithHoldingAllowed,
            withBypassedAuthentication,
            fromBackOffice,
            readOnly,
            features,
        }),
        onClickLogo: () => history.push(PagesArchitecture[Page.HOME].route),
        pagesArchitecture: PagesArchitecture,
        withFaIcons: true,
    }

    const customColor = useMemo(
        () => (visualisationMode === VisualisationModes.CESU_SOCIAL ? theme.primaryColor : null),
        [theme.primaryColor, visualisationMode],
    )

    return (
        <>
            <FixedLayout
                left={isResponsive ? sizes.spacing00 : sizes.menuNavigationWidth}
                top={isResponsive ? sizes.headerSecondaryMobileHeight : sizes.spacing00}
            >
                <Header
                    backgroundColor={customColor || colors.headerBackgroundColor}
                    buttonLinks={headerButtonLinks}
                    isConnected={isConnected && actualPage !== Page.BYPASS_LOGIN}
                    menuLinks={headerMenuLinks}
                    onConnectClick={() => history.push(PagesArchitecture[Page.LOGIN].route)}
                    textColor={colors.headerTextColor}
                    title={texts.headerTitle}
                    username={userFullName}
                />
            </FixedLayout>

            {isResponsive ? (
                <FixedLayout>
                    <LightHeader
                        backgroundColor={colors.white}
                        centerItem={<ResponsiveLogo src={personnalisationLogo} />}
                        height={sizes.headerSecondaryMobileHeight}
                        leftItem={
                            <SideMenuMobile>
                                <SideMenu {...menuProps} />
                            </SideMenuMobile>
                        }
                    />
                </FixedLayout>
            ) : (
                <SideMenuLayout>
                    <SideMenu srcLogo={personnalisationLogo} {...menuProps} />
                </SideMenuLayout>
            )}

            <ContentLayout
                footer={
                    <Footer
                        backgroundColor={customColor || colors.footerBackgroundColor}
                        items={footerItems}
                        textColor={customColor ? colors.white : colors.footerTextColor}
                    />
                }
                left={isResponsive ? sizes.spacing00 : sizes.menuNavigationWidth}
                top={isResponsive ? sizes.headerMobileTotalHeight : sizes.headerTotalHeight}
            >
                {children}
            </ContentLayout>
        </>
    )
}

ConnectedLayout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default ConnectedLayout
