import {
    SAVE_MILLESIME_TRADE_CONFIRMATION,
    RESET_MILLESIME_TRADE_CONFIRMATION,
} from "../../../actions/localStorage/millesimeTradeConfirmation/millesimeTradeConfirmationActionTypes"

const millesimeTradeConfirmation = (state = {}, action = {}) => {
    switch (action.type) {
        case SAVE_MILLESIME_TRADE_CONFIRMATION:
            return action.formValues
        case RESET_MILLESIME_TRADE_CONFIRMATION:
            return {}
        default:
            return state
    }
}

export default millesimeTradeConfirmation

export const getMillesimeTradeConfirmation = state => state
