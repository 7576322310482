/**
 * Indicate that we save password score from the form into store
 * @type {string}
 */
export const SAVE_PASSWORD_SCORE = 'SAVE_PASSWORD_SCORE';

/**
 * Indicate that we clear password score from the store
 * @type {string}
 */
export const CLEAR_PASSWORD_SCORE = 'CLEAR_PASSWORD_SCORE';
