import React from "react"

import PropTypes from "prop-types"
import {Icon, Loader} from "semantic-ui-react"

const ButtonTertiaryIcon = ({loading, icon, ...props}) =>
    loading ? <Loader active inline size="tiny" /> : !!icon && <Icon className={icon} {...props} />

ButtonTertiaryIcon.defaultProps = {
    icon: null,
    onClick: null,
}

ButtonTertiaryIcon.propTypes = {
    disabled: PropTypes.bool.isRequired,
    icon: PropTypes.string,
    loading: PropTypes.bool.isRequired,
    onClick: PropTypes.func,
}

export default ButtonTertiaryIcon
