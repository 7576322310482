import PropTypes from "prop-types"
import React from "react"
import {Dimmer, Grid, Image, Loader} from "semantic-ui-react"
import "./login.scss"
import LoginFormContainer from "../../components/LoginForm/loginFormContainer"
import ButtonTertiary from "../../shared-components/components/ButtonTertiary/buttonTertiary"
import Register from "../../shared-components/components/Register/register"
import {BENEFICIARY_AREA} from "../../shared-components/constants/areaTypes"
import {getImageFromRemotePath} from "../../shared-components/services/filer"

const LoginScene = (
    {displayRegistrationPart, isFetching, onCreateAccountClick, onNeedHelpClick, onContactUsClick},
    {texts},
) => (
    <div className="login-scene">
        <Dimmer.Dimmable className="login-scene-content">
            <Dimmer active={isFetching} inverted>
                <Loader active={isFetching} />
            </Dimmer>

            <div className="login-scene-content-header"></div>

            <Grid centered columns="equal" divided={displayRegistrationPart} stackable>
                {!displayRegistrationPart && (texts.loginLeftImage || texts.loginRightImage) && (
                    <Grid.Column only="large screen" stretched>
                        {texts.loginLeftImage && (
                            <div style={{display: "flex", flexDirection: "column", justifyContent: "center"}}>
                                <Image src={getImageFromRemotePath(texts.loginLeftImage, BENEFICIARY_AREA)} />
                            </div>
                        )}
                    </Grid.Column>
                )}
                <Grid.Column stretched>
                    <div className="login-scene-content-column">
                        <div className="login-scene-authentication">
                            <LoginFormContainer
                                links={
                                    <ButtonTertiary onClick={onNeedHelpClick}>
                                        {texts.loginForgottenPassword}
                                    </ButtonTertiary>
                                }
                            />
                        </div>
                    </div>
                </Grid.Column>
                {displayRegistrationPart && (
                    <Grid.Column stretched>
                        <div className="login-scene-content-column">
                            <div className="login-scene-register">
                                <Register redirectToRegistration={onCreateAccountClick} />
                                <span className="login-scene-register-contact">
                                    {texts.loginContact}
                                    <ButtonTertiary onClick={onContactUsClick}>{texts.loginContactLink}</ButtonTertiary>
                                    .
                                </span>
                            </div>
                        </div>
                    </Grid.Column>
                )}
                {!displayRegistrationPart && (texts.loginLeftImage || texts.loginRightImage) && (
                    <Grid.Column only="large screen" stretched>
                        {texts.loginRightImage && (
                            <div style={{display: "flex", flexDirection: "column", justifyContent: "center"}}>
                                <Image src={getImageFromRemotePath(texts.loginRightImage, BENEFICIARY_AREA)} />
                            </div>
                        )}
                    </Grid.Column>
                )}
            </Grid>
        </Dimmer.Dimmable>

        {texts.loginFooter && (
            <Image className="login-scene-footer" src={getImageFromRemotePath(texts.loginFooter, BENEFICIARY_AREA)} />
        )}
    </div>
)

LoginScene.contextTypes = {
    texts: PropTypes.object,
}

LoginScene.propTypes = {
    displayRegistrationPart: PropTypes.bool.isRequired,
    isFetching: PropTypes.bool.isRequired,
    onContactUsClick: PropTypes.func.isRequired,
    onCreateAccountClick: PropTypes.func.isRequired,
    onNeedHelpClick: PropTypes.func.isRequired,
}

export default LoginScene
