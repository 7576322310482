import {
    SAVE_CHECK_PAYMENT_CONFIRMATION,
    RESET_CHECK_PAYMENT_CONFIRMATION,
} from "../../../actions/localStorage/checkPaymentConfirmation/checkPaymentConfirmationActionTypes"

const checkPaymentConfirmation = (state = {}, action = {}) => {
    switch (action.type) {
        case SAVE_CHECK_PAYMENT_CONFIRMATION:
            return action.values
        case RESET_CHECK_PAYMENT_CONFIRMATION:
            return {}
        default:
            return state
    }
}

export default checkPaymentConfirmation

export const getCheckPaymentConfirmation = state => state
