import React from "react"

import PropTypes from "prop-types"
import {useSelector} from "react-redux"

import {isUserConnected} from "../store/selectors/sessionStorage/userSelectors"
import ConnectedLayout from "./connectedLayout"
import DisconnectedLayout from "./disconnectedLayout"

const NavigationLayout = ({children}) => {
    const isConnected = useSelector(state => isUserConnected(state))

    return isConnected ? (
        <ConnectedLayout>{children}</ConnectedLayout>
    ) : (
        <DisconnectedLayout>{children}</DisconnectedLayout>
    )
}

NavigationLayout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default NavigationLayout
