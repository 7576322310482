import PropTypes from "prop-types"
import React from "react"
import Alert from "../Alert/alert"
import "./checkBrowser.scss"

/**
 * Display an Alert if the browser is not optimal.
 * Based on https://lancedikson.github.io/bowser/docs/
 */
const CheckBrowser = ({isValidBrowser}) =>
    isValidBrowser === false && (
        <Alert
            className="check-browser-alert"
            content="Pour une utilisation optimale de notre site, nous vous recommandons d'utiliser Mozilla Firefox (version 56 ou supérieure) ou Google Chrome (version 61 ou supérieure)."
            header="Navigateur internet"
            type="warning"
        />
    )

CheckBrowser.propTypes = {
    /**
     * Tell the component to display nor its message.
     */
    isValidBrowser: PropTypes.bool.isRequired,
}

export default CheckBrowser
