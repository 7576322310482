export const getInfoMessageIcon = type => {
    switch (type) {
        case "success":
            return "fa-regular fa-check-circle"
        case "warning":
            return "fa-regular fa-exclamation-circle"
        case "error":
            return "fa-regular fa-exclamation-triangle"
        case "info":
            return "fa-regular fa-info-circle"
        default:
            return undefined
    }
}
