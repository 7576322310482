import debounce from "lodash.debounce"
import {useCallback, useEffect, useState} from "react"

const getWidth = () => window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth

export function useCurrentWidth() {
    let [width, setWidth] = useState(getWidth())

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedHandleResize = useCallback(
        debounce(() => setWidth(getWidth()), 200),
        [],
    )

    useEffect(() => {
        window.addEventListener("resize", debouncedHandleResize)
        return () => {
            window.removeEventListener("resize", debouncedHandleResize)
        }
    }, [debouncedHandleResize])

    return width
}

export function useIsResponsive() {
    const width = useCurrentWidth()
    return width < 768
}

export function useIsMobile() {
    const width = useCurrentWidth()
    return width < 480
}
