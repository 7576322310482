import PropTypes from "prop-types"
import React from "react"
import "./fixedLayout.scss"
import sizes from "../../styles/_sizes.module.scss"

/**
 * Layout permettant d'intégrer un composant en position fixe dans la page.
 * Les propriétés top, left, bottom et right permettent de définir le positionnement du layout
 */
const FixedLayout = ({left, top, right, bottom, children}) => {
    return (
        <div className="fixed-layout" style={{top, left, right, bottom}}>
            {children}
        </div>
    )
}

FixedLayout.propTypes = {
    bottom: PropTypes.string,
    children: PropTypes.node.isRequired,
    left: PropTypes.string,
    right: PropTypes.string,
    top: PropTypes.string,
}

FixedLayout.defaultProps = {
    left: sizes.spacing00,
    right: sizes.spacing00,
    top: sizes.spacing00,
    bottom: undefined,
}

export default FixedLayout
