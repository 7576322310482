import React from "react"

import PropTypes from "prop-types"
import {Button as SemanticButton} from "semantic-ui-react"

import ButtonTertiaryIcon from "./buttonTertiaryIcon"

import "./buttonTertiary.scss"

const ButtonTertiary = ({
    children,
    actionType,
    className,
    disabled,
    loading,
    icon,
    iconPosition,
    withoutPadding,
    ...props
}) => {
    return (
        <div
            className={
                "button-tertiary" + (disabled || loading ? " disabled" : "") + (withoutPadding ? " no-padding" : "")
            }
        >
            {iconPosition === "left" && (
                <ButtonTertiaryIcon loading={loading} icon={icon} disabled={disabled} {...props} />
            )}
            <SemanticButton
                className={className ? className : ""}
                disabled={disabled || loading}
                {...props}
                type={actionType}
            >
                {children}
            </SemanticButton>
            {iconPosition === "right" && (
                <ButtonTertiaryIcon loading={loading} icon={icon} disabled={disabled} {...props} />
            )}
        </div>
    )
}
ButtonTertiary.defaultProps = {
    actionType: undefined,
    className: null,
    disabled: false,
    icon: null,
    iconPosition: "left",
    loading: false,
    onClick: null,
    withoutPadding: false,
}

ButtonTertiary.propTypes = {
    actionType: PropTypes.oneOf(["button", "reset", "submit"]),
    children: PropTypes.string.isRequired,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    icon: PropTypes.string,
    iconPosition: PropTypes.oneOf(["left", "right"]),
    loading: PropTypes.bool,
    onClick: PropTypes.func,
    withoutPadding: PropTypes.bool,
}

export default ButtonTertiary
