import {
    SAVE_REGISTRATION_CONTROL,
    RESET_REGISTRATION_CONTROL,
} from "../../../actions/localStorage/registrationControl/registrationControlActionTypes"

const registrationControl = (state = {}, action = {}) => {
    switch (action.type) {
        case SAVE_REGISTRATION_CONTROL:
            return action.values
        case RESET_REGISTRATION_CONTROL:
            return {}
        default:
            return state
    }
}

export default registrationControl

export const getRegistrationControl = state => state
