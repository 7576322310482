/**
 * Indicate that the user try to change his password
 */
export const CHANGE_PASSWORD_REQUEST = "CHANGE_PASSWORD_REQUEST"
/**
 * Indicate that the user successfully change his password
 */
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS"
/**
 * Indicate that the user failed to change his password
 */
export const CHANGE_PASSWORD_FAILURE = "CHANGE_PASSWORD_FAILURE"

/**
 * Indicate that we launched a request to connect a user.
 * @type {string}
 */
export const CONNECT_USER_REQUEST = "CONNECT_USER_REQUEST"

/**
 * Indicate that we connected successfully the user.
 * @type {string}
 */
export const CONNECT_USER_SUCCESS = "CONNECT_USER_SUCCESS"

/**
 * Indicate that the user connection failed.
 * @type {string}
 */
export const CONNECT_USER_FAILURE = "CONNECT_USER_FAILURE"

/**
 * Indicate that the user has to disconnect.
 * @type {string}
 */
export const DISCONNECT_USER = "DISCONNECT_USER"

/**
 * Indicate that we want to start the recover login/password process
 * @type {String}
 */
export const RECOVER_LOGIN_PASSWORD_REQUEST = "RECOVER_LOGIN_PASSWORD_REQUEST"
/**
 * Indicate that we successfully start the recover login/password process
 * @type {String}
 */
export const RECOVER_LOGIN_PASSWORD_SUCCESS = "RECOVER_LOGIN_PASSWORD_SUCCESS"
/**
 * Indicate that we failed to start the recover login/password process
 * @type {String}
 */
export const RECOVER_LOGIN_PASSWORD_FAILURE = "RECOVER_LOGIN_PASSWORD_FAILURE"

/**
 * Indicate that we bypassed authentication
 * @type {String}
 */
export const SET_BYPASSED_AUTHENTICATION = "SET_BYPASSED_AUTHENTICATION"
