export const CountryKeys = {
    DE: "DE",
    AD: "AD",
    AT: "AT",
    BE: "BE",
    BG: "BG",
    CY: "CY",
    VA: "VA",
    HR: "HR",
    DK: "DK",
    ES: "ES",
    EE: "EE",
    FI: "FI",
    FR: "FR",
    GI: "GI",
    GR: "GR",
    HU: "HU",
    IE: "IE",
    IS: "IS",
    IT: "IT",
    LV: "LV",
    LI: "LI",
    LT: "LT",
    LU: "LU",
    MT: "MT",
    MC: "MC",
    NO: "NO",
    NL: "NL",
    PL: "PL",
    PT: "PT",
    CZ: "CZ",
    RO: "RO",
    GB: "GB",
    SM: "SM",
    SK: "SK",
    SI: "SI",
    SE: "SE",
    CH: "CH",
}
/**
 * Formats des IBAN de la zone ZEPA (sans les espaces) :
 * <p>
 * Allemagne : DEkk BBBB BBBB CCCC CCCC CC
 * Andorre : ADkk BBBB SSSS CCCC CCCC CCCC
 * Autriche : ATkk BBBB BCCC CCCC CCCC
 * Belgique : BEkk BBBC CCCC CCKK
 * Bulgarie : BGkk BBBB SSSS DDCC CCCC CC
 * Chypre : CYkk BBBS SSSS CCCC CCCC CCCC CCCC
 * Cité du Vatican : VAkk BBBC CCCC CCCC CCCC CC
 * Croatie : HRkk BBBB BBBC CCCC CCCC C
 * Danemark : DKkk BBBB CCCC CCCC CC
 * Espagne : ESkk BBBB SSSS KKCC CCCC CCCC
 * Estonie : EEkk BBSS CCCC CCCC CCCK
 * Finlande : FIkk BBBB BBCC CCCC CK
 * France : FRkk BBBB BSSS SSCC CCCC CCCC CKK
 * Gibraltar : GIkk BBBB CCCC CCCC CCCC CCC
 * Grèce : GRkk BBBS SSSC CCCC CCCC CCCC CCC
 * Hongrie : HUkk BBBS SSSC CCCC CCCC CCCC CCCK
 * Irlande : IEkk BBBB SSSS SSCC CCCC CC
 * Islande : ISkk BBBB SSCC CCCC NNNN NNNN NN
 * Italie : ITkk KBBB BBSS SSSC CCCC CCCC CCC
 * Lettonie : LVkk BBBB CCCC CCCC CCCC C
 * Liechtenstein : LIkk BBBB BCCC CCCC CCCC C
 * Lituanie : LTkk BBBB BCCC CCCC CCCC
 * Luxembourg : LUkk BBBC CCCC CCCC CCCC
 * Malte : MTkk BBBB SSSS SCCC CCCC CCCC CCCC CCC
 * Monaco : MCkk BBBB BSSS SSCC CCCC CCCC CKK
 * Norvège : NOkk BBBB CCCC CCK
 * Pays-Bas : NLkk BBBB CCCC CCCC CC
 * Pologne : PLkk BBBS SSSK CCCC CCCC CCCC CCCC
 * Portugal : PTkk BBBB SSSS CCCC CCCC CCCK K
 * République Tchèque : CZkk BBBB CCCC CCCC CCCC CCCC
 * Roumanie : ROkk BBBB CCCC CCCC CCCC CCCC
 * Royaume-Uni : GBkk BBBB SSSS SSCC CCCC CC
 * Saint-Marin : SMkk KBBB BBSS SSSC CCCC CCCC CCC
 * Slovaquie : SKkk BBBB CCCC CCCC CCCC CCCC
 * Slovénie : SIkk BBSS SCCC CCCC CKK
 * Suède : SEkk BBBC CCCC CCCC CCCC CCCC
 * Suisse : CHkk BBBB BCCC CCCC CCCC C
 */
export const codesIbanMap = {
    [CountryKeys.DE]: {debutCodeBanque: 4, finCodeBanque: 12, debutCodeGuichet: 0, finCodeGuichet: 0,},
    [CountryKeys.AD]: {debutCodeBanque: 4, finCodeBanque: 8, debutCodeGuichet: 8, finCodeGuichet: 12,},
    [CountryKeys.AT]: {debutCodeBanque: 4, finCodeBanque: 9, debutCodeGuichet: 0, finCodeGuichet: 0,},
    [CountryKeys.BE]: {debutCodeBanque: 4, finCodeBanque: 7, debutCodeGuichet: 0, finCodeGuichet: 0,},
    [CountryKeys.BG]: {debutCodeBanque: 4, finCodeBanque: 8, debutCodeGuichet: 8, finCodeGuichet: 12,},
    [CountryKeys.CY]: {debutCodeBanque: 4, finCodeBanque: 7, debutCodeGuichet: 7, finCodeGuichet: 12,},
    [CountryKeys.VA]: {debutCodeBanque: 4, finCodeBanque: 7, debutCodeGuichet: 0, finCodeGuichet: 0,},
    [CountryKeys.HR]: {debutCodeBanque: 4, finCodeBanque: 11, debutCodeGuichet: 0, finCodeGuichet: 0,},
    [CountryKeys.DK]: {debutCodeBanque: 4, finCodeBanque: 8, debutCodeGuichet: 0, finCodeGuichet: 0,},
    [CountryKeys.ES]: {debutCodeBanque: 4, finCodeBanque: 8, debutCodeGuichet: 8, finCodeGuichet: 12,},
    [CountryKeys.EE]: {debutCodeBanque: 4, finCodeBanque: 6, debutCodeGuichet: 6, finCodeGuichet: 8,},
    [CountryKeys.FI]: {debutCodeBanque: 4, finCodeBanque: 10, debutCodeGuichet: 0, finCodeGuichet: 0,},
    [CountryKeys.FR]: {debutCodeBanque: 4, finCodeBanque: 9, debutCodeGuichet: 9, finCodeGuichet: 14,},
    [CountryKeys.GI]: {debutCodeBanque: 4, finCodeBanque: 8, debutCodeGuichet: 0, finCodeGuichet: 0,},
    [CountryKeys.GR]: {debutCodeBanque: 4, finCodeBanque: 7, debutCodeGuichet: 7, finCodeGuichet: 11,},
    [CountryKeys.HU]: {debutCodeBanque: 4, finCodeBanque: 7, debutCodeGuichet: 7, finCodeGuichet: 11,},
    [CountryKeys.IE]: {debutCodeBanque: 4, finCodeBanque: 8, debutCodeGuichet: 8, finCodeGuichet: 14,},
    [CountryKeys.IS]: {debutCodeBanque: 4, finCodeBanque: 8, debutCodeGuichet: 8, finCodeGuichet: 10,},
    [CountryKeys.IT]: {debutCodeBanque: 5, finCodeBanque: 10, debutCodeGuichet: 10, finCodeGuichet: 15,},
    [CountryKeys.LV]: {debutCodeBanque: 4, finCodeBanque: 8, debutCodeGuichet: 0, finCodeGuichet: 0,},
    [CountryKeys.LI]: {debutCodeBanque: 4, finCodeBanque: 9, debutCodeGuichet: 0, finCodeGuichet: 0,},
    [CountryKeys.LT]: {debutCodeBanque: 4, finCodeBanque: 9, debutCodeGuichet: 0, finCodeGuichet: 0,},
    [CountryKeys.LU]: {debutCodeBanque: 4, finCodeBanque: 7, debutCodeGuichet: 0, finCodeGuichet: 0,},
    [CountryKeys.MT]: {debutCodeBanque: 4, finCodeBanque: 8, debutCodeGuichet: 8, finCodeGuichet: 13,},
    [CountryKeys.MC]: {debutCodeBanque: 4, finCodeBanque: 9, debutCodeGuichet: 9, finCodeGuichet: 14,},
    [CountryKeys.NO]: {debutCodeBanque: 4, finCodeBanque: 8, debutCodeGuichet: 0, finCodeGuichet: 0,},
    [CountryKeys.NL]: {debutCodeBanque: 4, finCodeBanque: 8, debutCodeGuichet: 0, finCodeGuichet: 0,},
    [CountryKeys.PL]: {debutCodeBanque: 4, finCodeBanque: 7, debutCodeGuichet: 7, finCodeGuichet: 11,},
    [CountryKeys.PT]: {debutCodeBanque: 4, finCodeBanque: 8, debutCodeGuichet: 8, finCodeGuichet: 12,},
    [CountryKeys.CZ]: {debutCodeBanque: 4, finCodeBanque: 8, debutCodeGuichet: 0, finCodeGuichet: 0,},
    [CountryKeys.RO]: {debutCodeBanque: 4, finCodeBanque: 8, debutCodeGuichet: 0, finCodeGuichet: 0,},
    [CountryKeys.GB]: {debutCodeBanque: 4, finCodeBanque: 8, debutCodeGuichet: 8, finCodeGuichet: 14,},
    [CountryKeys.SM]: {debutCodeBanque: 5, finCodeBanque: 10, debutCodeGuichet: 10, finCodeGuichet: 15,},
    [CountryKeys.SK]: {debutCodeBanque: 4, finCodeBanque: 8, debutCodeGuichet: 0, finCodeGuichet: 0,},
    [CountryKeys.SI]: {debutCodeBanque: 4, finCodeBanque: 6, debutCodeGuichet: 6, finCodeGuichet: 9,},
    [CountryKeys.SE]: {debutCodeBanque: 4, finCodeBanque: 7, debutCodeGuichet: 0, finCodeGuichet: 0,},
    [CountryKeys.CH]: {debutCodeBanque: 4, finCodeBanque: 9, debutCodeGuichet: 0, finCodeGuichet: 0,},
}

export const getBankCodeFromIBAN = iban => {
    if (!!iban) {
        const country = iban.substring(0, 2).toUpperCase()

        if (country in codesIbanMap) {
            return iban.substring(codesIbanMap[country].debutCodeBanque, codesIbanMap[country].finCodeBanque)
        }
    }
    return null
}

export const getBoxCodeFromIBAN = iban => {
    if (!!iban) {
        const country = iban.substring(0, 2).toUpperCase()

        if (country in codesIbanMap) {
            return iban.substring(codesIbanMap[country].debutCodeGuichet, codesIbanMap[country].finCodeGuichet)
        }
    }
    return null
}
