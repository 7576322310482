import PropTypes from "prop-types"
import React from "react"
import {Field, Form, reduxForm} from "redux-form"
import {Form as SemanticForm, Icon, Input} from "semantic-ui-react"
import "./login.scss"
import {useTexts} from "../../hooks/useTexts"
import {required} from "../../services/validation"
import ButtonPrimary from "../ButtonPrimary/buttonPrimary"
import PasswordField from "../PasswordField/passwordField"
import SemanticFormField from "../SemanticFormField/semanticFormField"

const Login = ({errorMessage, handleSubmit, isFetching, links, onSubmit}) => {
    const texts = useTexts()

    return (
        <SemanticForm as={Form} className="login" onSubmit={handleSubmit(onSubmit)}>
            <p className="login-question">
                <Icon className="fa-solid fa-caret-right" />
                <b>{texts.sharedLoginParagraph}</b>
                {texts.sharedLoginParagraph2}
            </p>

            {errorMessage && <div className="login-error-message">{errorMessage}</div>}

            <Field
                as={Input}
                component={SemanticFormField}
                fluid
                htmlLabel={<label>{texts.sharedLoginIdentifiant}</label>}
                name="login"
                validate={required}
            />

            <PasswordField
                as={Input}
                component={SemanticFormField}
                fluid
                htmlLabel={<label>{texts.passwordPlaceholder}</label>}
                name="password"
                validate={required}
                withFaIcons={true}
            />

            <ButtonPrimary actionType="submit" disabled={isFetching} fluid loading={isFetching}>
                {texts.sharedLoginButton}
            </ButtonPrimary>

            {links && <div className="login-links">{links}</div>}
        </SemanticForm>
    )
}

Login.propTypes = {
    errorMessage: PropTypes.string,
    handleSubmit: PropTypes.func.isRequired,
    isFetching: PropTypes.bool.isRequired,
    links: PropTypes.node,
    onSubmit: PropTypes.func.isRequired,
}

Login.defaultProps = {
    errorMessage: undefined,
    links: undefined,
}

export default reduxForm({form: "loginForm"})(Login)
