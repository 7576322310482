import PropTypes from "prop-types"
import React from "react"
import "./contentLayout.scss"
import sizes from "../../styles/_sizes.module.scss"

/**
 * Layout permettant de positionner le contenu principal de la page
 */
const ContentLayout = ({left, top, footer, children}) => {
    return (
        <div className="content-layout" style={{paddingLeft: left, paddingTop: top}}>
            <div className="content-layout-main">{children}</div>
            {footer && <div className="content-layout-footer">{footer}</div>}
        </div>
    )
}

ContentLayout.defaultProps = {
    footer: null,
    left: sizes.menuNavigationWidth,
    top: sizes.headerHeight,
}

ContentLayout.propTypes = {
    children: PropTypes.node.isRequired,
    footer: PropTypes.node,
    left: PropTypes.string,
    top: PropTypes.string,
}

export default ContentLayout
