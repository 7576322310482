import PropTypes from "prop-types"
import React from "react"
import {Button as SemanticButton, Icon as SemanticIcon} from "semantic-ui-react"
import "./iconButton.scss"

export const ICON_BUTTON_VARIANTS = {
    DEFAULT: "default",
    ACTION: "action",
    ACTION_WARNING: "action-warning",
    DARK_BLUE_SQUARE: "dark-blue-square",
}

const IconButton = ({icon, variant, ...props}) => (
    <SemanticButton className={`icon-button variant-${variant}`} type="button" {...props}>
        <SemanticIcon name={icon} />
    </SemanticButton>
)

IconButton.propTypes = {
    icon: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    variant: PropTypes.oneOf(Object.values(ICON_BUTTON_VARIANTS)),
}

IconButton.defaultProps = {
    variant: ICON_BUTTON_VARIANTS.DEFAULT,
}

export default IconButton
