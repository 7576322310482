import PropTypes from "prop-types"
import React, {useEffect, useState} from "react"
import {useSelector} from "react-redux"
import {Transition} from "semantic-ui-react"
import "./sideMenuMobile.scss"
import FixedLayout from "../../layouts/fixedLayout"
import getActualPage from "../../store/temporaries/page/pageReducer"
import IconButton, {ICON_BUTTON_VARIANTS} from "../IconButton/iconButton"

const SideMenuMobile = ({children}) => {
    const actualPage = useSelector(state => getActualPage(state))

    const [isOpen, setIsOpen] = useState(false)

    // On ferme le menu lorsque l'utilisateur clique sur un item
    useEffect(() => {
        setIsOpen(false)
    }, [actualPage])

    return (
        <>
            <IconButton
                icon={isOpen ? "fa-regular fa-xmark" : "fa-regular fa-bars"}
                onClick={() => {
                    setIsOpen(!isOpen)
                }}
                style={{zIndex: 999}}
                variant={ICON_BUTTON_VARIANTS.DARK_BLUE_SQUARE}
            />

            <FixedLayout>
                <Transition animation="fade down" duration={300} visible={isOpen}>
                    <div className="side-menu-mobile">{children}</div>
                </Transition>
            </FixedLayout>
        </>
    )
}

SideMenuMobile.propTypes = {
    children: PropTypes.node.isRequired,
}

export default SideMenuMobile
