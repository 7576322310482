import {menuContentType} from "../../shared-components/constants/menuContentType"
import {Page} from "../page/pages"
import {PagesArchitecture} from "../page/pagesArchitecture"
import {VisualisationModes} from "../visualisationModes"

const isFeatureEnabled = (page, readOnly, features) =>
    (!PagesArchitecture[page].featureFlag ||
        (PagesArchitecture[page].featureFlag && features.includes(PagesArchitecture[page].featureFlag))) &&
    (!PagesArchitecture[page].hiddenInReadOnlyMode || !(PagesArchitecture[page].hiddenInReadOnlyMode && readOnly))

/**
 * Retourne la liste d'items du menu en fonction du mode de visualisation SOCIAL ou RH
 */
export const menuContent = ({
    features,
    fromBackOffice,
    isContractWithHoldingAllowed,
    readOnly,
    texts,
    visualisationMode,
    withBypassedAuthentication,
}) => {
    const homepageItem = {
        type: menuContentType.SIMPLE,
        page: Page.HOME,
        icon: "fa-regular fa-house",
    }
    const accountPaymentChoiceItem = {
        type: menuContentType.SIMPLE,
        page: Page.ACCOUNT_PAYMENT_CHOICE,
        activeOnPages: [
            Page.ACCOUNT_PAYMENT_AMOUNT_OVERVIEW,
            Page.ACCOUNT_PAYMENT_AMOUNT,
            Page.ACCOUNT_PAYMENT_CESU_DECLARATION,
            Page.ACCOUNT_PAYMENT_CESU_IDENTIFICATION,
            Page.ACCOUNT_PAYMENT_CONFIRMATION,
            Page.ACCOUNT_PAYMENT_ORGANIZATION,
            Page.ACCOUNT_PAYMENT_PAJE_DECLARATION,
            Page.ACCOUNT_PAYMENT_PAJE_IDENTIFICATION,
        ],
    }
    const declarationHistoryItem = {
        type: menuContentType.SIMPLE,
        page: Page.DECLARATION_HISTORY,
        activeOnPages: [],
    }

    // MENU EN MODE CESU SOCIAL
    if (visualisationMode === VisualisationModes.CESU_SOCIAL) {
        return [
            homepageItem,
            {
                ...accountPaymentChoiceItem,
                icon: "fa-regular fa-euro-sign",
                hidden: !isFeatureEnabled(Page.ACCOUNT_PAYMENT_CHOICE, readOnly, features),
            },
            {
                page: Page.TRANSACTIONS_HISTORY,
                type: menuContentType.SIMPLE,
                icon: "fa-regular fa-timer",
                activeOnPages: [],
                hidden: !isFeatureEnabled(Page.TRANSACTIONS_HISTORY, readOnly, features),
            },
            {
                ...declarationHistoryItem,
                icon: "fa-regular fa-timer",
                hidden:
                    !isContractWithHoldingAllowed || !isFeatureEnabled(Page.DECLARATION_HISTORY, readOnly, features),
            },
        ]
    }

    if ((withBypassedAuthentication && !fromBackOffice && readOnly === undefined) || !visualisationMode) {
        return []
    }

    // MENU EN MODE CESU RH
    return [
        homepageItem,
        {
            type: menuContentType.ACCORDION,
            icon: "fa-regular fa-cart-shopping",
            name: texts.menuNavMyCesuOrders,
            page: Page.ORDER_SESSION_LIST,
            subItems: [
                {
                    type: menuContentType.SIMPLE,
                    page: Page.ORDER_SESSION_LIST,
                    activeOnPages: [
                        Page.ORDER_INFORMATION,
                        Page.ORDER_CONTENT,
                        Page.ORDER_CONFIRMATION,
                        Page.ORDER_DELIVERY,
                        Page.ORDER_PAYMENT,
                        Page.ORDER_PROFILE,
                    ],
                    hidden: fromBackOffice || !isFeatureEnabled(Page.ORDER_SESSION_LIST, readOnly, features),
                },
                {
                    type: menuContentType.SIMPLE,
                    page: Page.MILLESIME_TRADE_CHOICE,
                    activeOnPages: [
                        Page.MILLESIME_TRADE_CONFIRMATION,
                        Page.MILLESIME_TRADE_DELIVERY,
                        Page.MILLESIME_TRADE_PAYMENT,
                    ],
                    hidden: !isFeatureEnabled(Page.MILLESIME_TRADE_CHOICE, readOnly, features),
                },
                {
                    type: menuContentType.SIMPLE,
                    page: Page.CESU_CONVERSION_CHOICE,
                    activeOnPages: [
                        Page.CESU_CONVERSION_AMOUNT,
                        Page.CESU_CONVERSION_CONFIRMATION,
                        Page.CESU_CONVERSION_DELIVERY,
                    ],
                    hidden: fromBackOffice || !isFeatureEnabled(Page.CESU_CONVERSION_CHOICE, readOnly, features),
                },
                {
                    type: menuContentType.SIMPLE,
                    page: Page.ORDER_HISTORY,
                    activeOnPages: [],
                    hidden: fromBackOffice || !isFeatureEnabled(Page.ORDER_HISTORY, readOnly, features),
                },
            ],
            activeOnPages: [],
        },
        {
            type: menuContentType.ACCORDION,
            icon: "fa-regular fa-laptop-mobile",
            name: texts.menuNavMyECesu,
            page: Page.ACCOUNT_STATEMENT,
            subItems: [
                {
                    type: menuContentType.SIMPLE,
                    page: Page.ACCOUNT_STATEMENT,
                    activeOnPages: [],
                    hidden: fromBackOffice || !isFeatureEnabled(Page.ACCOUNT_STATEMENT, readOnly, features),
                },
                {
                    ...accountPaymentChoiceItem,
                    hidden: fromBackOffice || !isFeatureEnabled(Page.ACCOUNT_PAYMENT_CHOICE, readOnly, features),
                },
                {
                    type: menuContentType.SIMPLE,
                    page: Page.ACCOUNT_PAYMENT_HISTORY,
                    activeOnPages: [],
                    hidden: fromBackOffice || !isFeatureEnabled(Page.ACCOUNT_PAYMENT_HISTORY, readOnly, features),
                },
                {
                    ...declarationHistoryItem,
                    hidden:
                        fromBackOffice ||
                        !isContractWithHoldingAllowed ||
                        !isFeatureEnabled(Page.DECLARATION_HISTORY, readOnly, features),
                },
            ],
            activeOnPages: [],
        },
        {
            type: menuContentType.ACCORDION,
            icon: "fa-regular fa-money-check-pen",
            name: texts.menuNavMyPaperCesu,
            page: Page.CHECKBOOK_STATEMENT,
            subItems: [
                {
                    type: menuContentType.SIMPLE,
                    page: Page.CHECKBOOK_STATEMENT,
                    activeOnPages: [],
                    hidden: fromBackOffice || !isFeatureEnabled(Page.CHECKBOOK_STATEMENT, readOnly, features),
                },
                {
                    type: menuContentType.SIMPLE,
                    page: Page.CHECK_PAYMENT_CHOICE,
                    activeOnPages: [Page.CHECK_PAYMENT_AMOUNT, Page.CHECK_PAYMENT_CONFIRMATION],
                    hidden: fromBackOffice || !isFeatureEnabled(Page.CHECK_PAYMENT_CHOICE, readOnly, features),
                },
                {
                    type: menuContentType.SIMPLE,
                    page: Page.CHECK_PAYMENT_HISTORY,
                    activeOnPages: [],
                    hidden: fromBackOffice || !isFeatureEnabled(Page.CHECK_PAYMENT_HISTORY, readOnly, features),
                },
            ],
            activeOnPages: [],
        },
        {
            type: menuContentType.ACCORDION,
            icon: "fa-regular fa-file",
            name: texts.menuNavMyDocuments,
            page: Page.TAX_DOCUMENT,
            subItems: [
                {
                    type: menuContentType.SIMPLE,
                    page: Page.TAX_DOCUMENT,
                    activeOnPages: [],
                    hidden: !isFeatureEnabled(Page.TAX_DOCUMENT, readOnly, features),
                },
            ],
            activeOnPages: [],
        },
    ]
}
