import {
    SAVE_CESU_CONVERSION_DELIVERY,
    RESET_CESU_CONVERSION_DELIVERY,
} from "../../../actions/localStorage/cesuConversionDelivery/cesuConversionDeliveryActionTypes"

const cesuConversionDelivery = (state = {}, action = {}) => {
    switch (action.type) {
        case SAVE_CESU_CONVERSION_DELIVERY:
            return action.data
        case RESET_CESU_CONVERSION_DELIVERY:
            return {}
        default:
            return state
    }
}

export default cesuConversionDelivery

export const getCesuConversionDelivery = state => state
