import {
    SAVE_MILLESIME_TRADE_DELIVERY,
    RESET_MILLESIME_TRADE_DELIVERY,
} from "../../../actions/localStorage/millesimeTradeDelivery/millesimeTradeDeliveryActionTypes"

const millesimeTradeDelivery = (state = {}, action = {}) => {
    switch (action.type) {
        case SAVE_MILLESIME_TRADE_DELIVERY:
            return action.formValues
        case RESET_MILLESIME_TRADE_DELIVERY:
            return {}
        default:
            return state
    }
}

export default millesimeTradeDelivery

export const getMillesimeTradeDelivery = state => state
