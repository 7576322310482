import {EDIT_ORDER_TYPE, RESET_ORDER_TYPE} from "./orderTypeActionTypes"

export const editOrderType = formValues => {
    return {
        type: EDIT_ORDER_TYPE,
        data: formValues,
    }
}

export const resetOrderType = {
    type: RESET_ORDER_TYPE,
}
