/**
 * Feature flags available
 */
export const Features = {
    // VIRTUALS FEATURES
    CONVERT_SUB_MENU: "afficherSousMenuChangerFormat",
    DISPLAY_CHECKS: "afficherMesTitres",
    MAKE_ORDER_SUB_MENU: "afficherSousMenuEffectuerPrecommande",
    TRADE_SUB_MENU: "afficherSousMenuEchangerCesu",
    DECLARATION_TAB_IN_PROFILE: "afficherOngletProfileDeclaration",
    LOGIN: "connexionEspaceBeneficiaireParContrat",
    OPENED: "ouvertureEspaceBeneficiaireParContrat",
    CESU_RH_MESSAGE: "afficherCommunicationCESURH",
    ACTIVE_TAX_DOCUMENT_SUB_MENU: "afficherSousMenuAttestationFiscale",
}
