import {EDIT_CONTENT, RESET_CONTENT} from "../../../actions/localStorage/orderContent/orderContentActionTypes"

const orderContent = (state = {}, action = {}) => {
    switch (action.type) {
        case EDIT_CONTENT:
            return action.data
        case RESET_CONTENT:
            return {}
        default:
            return state
    }
}

export default orderContent

export const getOrderContent = state => state
