import {combineReducers} from "redux"
import {
    EDIT_COMMUNICATION_INFORMATIONS,
    EDIT_COMPLEMENTARY_INFORMATIONS,
    RESET_COMPLEMENTARY_INFORMATIONS,
    RESET_COMMUNICATION_INFORMATIONS,
} from "../../../actions/localStorage/orderProfile/orderProfileActionTypes"

const orderProfile = (state = {}, action = {}) => {
    const communicationInformations = (state = "", action = {}) => {
        switch (action.type) {
            case EDIT_COMMUNICATION_INFORMATIONS:
                return action.communicationInformations
            case RESET_COMMUNICATION_INFORMATIONS:
                return {}
            default:
                return state
        }
    }
    const complementaryInformations = (state = "", action = {}) => {
        switch (action.type) {
            case EDIT_COMPLEMENTARY_INFORMATIONS:
                return action.complementaryInformations
            case RESET_COMPLEMENTARY_INFORMATIONS:
                return {}
            default:
                return state
        }
    }

    return combineReducers({
        communicationInformations,
        complementaryInformations,
    })
}

export default orderProfile

export const getOrderProfile = state => state
