import {CHANGE_ACTUAL_TAB} from "../../../actions/temporaries/tabBar/tabBarActionTypes"

/**
 * Tab bar reducer.
 * Stock in store the selected tab.
 */
const tabBar = (state = {actualTab: 0}, action = {}) => {
    switch (action.type) {
        case CHANGE_ACTUAL_TAB:
            return {
                actualTab: action.actualTab,
            }

        default:
            return state
    }
}

export default tabBar

/**
 * Return the actual tab selected in the tab bar.
 */
export const getActualTab = state => state.actualTab
