import {
    SAVE_REGISTRATION_CONFIRMATION,
    RESET_REGISTRATION_CONFIRMATION,
} from "../../../actions/localStorage/registrationConfirmation/registrationConfirmationActionTypes"

const registrationConfirmation = (state = {}, action = {}) => {
    switch (action.type) {
        case SAVE_REGISTRATION_CONFIRMATION:
            return action.values
        case RESET_REGISTRATION_CONFIRMATION:
            return {}
        default:
            return state
    }
}

export default registrationConfirmation

export const getRegistrationConfirmation = state => state
