/**
 * Build part of URL like this : param1=value1,param2=value2, param3=value3
 * @param searchParams Array of objects {fieldName: param, value: value}
 * Use object with defined values
 */
export const buildUrlSearchParams = searchParams => {
    let url = ""

    searchParams.forEach((paramObject, index) => {
        if (paramObject.value !== undefined && paramObject.value !== "" && paramObject.value !== null) {
            url += paramObject.fieldName + "=" + paramObject.value + (index < searchParams.length ? ";" : "")
        }
    })

    return url
}

export const buildFromSearchValues = (objectFields, searchFormValues) => {
    let searchParams = []
    for (const key of Object.keys(objectFields)) {
        searchParams.push({fieldName: objectFields[key], value: searchFormValues[key]})
    }

    return buildUrlSearchParams(searchParams)
}
