import PropTypes from "prop-types"
import React, {useEffect, useRef, useState} from "react"
import {Menu, Popup, Ref} from "semantic-ui-react"
import "./headerMenuPopup.scss"
import {useIsResponsive} from "../../hooks/useIsResponsive"
import {useTexts} from "../../hooks/useTexts"
import HeaderLink from "../HeaderLink/headerLink"

const HeaderMenuPopup = ({isConnected, menuLinks, textColor, username}) => {
    const isResponsive = useIsResponsive()
    const texts = useTexts()
    const [isOpen, setIsOpen] = useState(false)
    const menuLinkRef = useRef()
    const popupRef = useRef()

    // useEffect pour écouter les clics en dehors de la popin et la fermer
    useEffect(() => {
        function handleClickOutside(event) {
            if (isOpen && popupRef.current && !popupRef.current.contains(event.target)) {
                setIsOpen(false)
            }
        }

        document.addEventListener("mousedown", handleClickOutside)
        return () => {
            document.removeEventListener("mousedown", handleClickOutside)
        }
    }, [isOpen, isConnected])

    return (
        <>
            {isOpen && isResponsive && <div className="header-menu-popup-background" />}

            <Ref innerRef={menuLinkRef}>
                <HeaderLink
                    icon="fa-solid fa-circle-user"
                    label={isConnected && username !== "undefined" ? username : texts.headerLogin}
                    onClick={() => setIsOpen(!isOpen)}
                    ref={menuLinkRef}
                    textColor={textColor}
                    withArrowIcon={!isResponsive}
                />
            </Ref>

            <Ref innerRef={popupRef}>
                <Popup
                    basic
                    className="header-menu-popup"
                    context={menuLinkRef}
                    offset={[0, -10]}
                    open={isOpen}
                    position="bottom right"
                >
                    <Menu secondary vertical>
                        {isResponsive && isConnected && username !== "undefined" && (
                            <Menu.Item content={username} header />
                        )}
                        {menuLinks.map(linkProps => (
                            <Menu.Item
                                key={`header-menu-popup-item-${linkProps.label}`}
                                onClick={() => {
                                    setIsOpen(false)
                                    linkProps.onClick()
                                }}
                            >
                                {linkProps.label}
                            </Menu.Item>
                        ))}
                    </Menu>
                </Popup>
            </Ref>
        </>
    )
}

HeaderMenuPopup.propTypes = {
    isConnected: PropTypes.bool,
    menuLinks: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            onClick: PropTypes.func,
        }),
    ),
    textColor: PropTypes.string.isRequired,
    username: PropTypes.string,
}

HeaderMenuPopup.defaultProps = {
    isConnected: false,
    menuLinks: [],
    username: undefined,
}

export default HeaderMenuPopup
