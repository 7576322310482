/**
 * Format a number to a string representing euros.
 */
export const fromNumberToEuros = number =>
    Number(number).toLocaleString("fr-FR", {
        style: "currency",
        currency: "EUR",
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    })
