import {
    SAVE_ACCOUNT_PAYMENT_AMOUNT,
    RESET_ACCOUNT_PAYMENT_AMOUNT,
} from "../../../actions/localStorage/accountPaymentAmount/accountPaymentAmountActionTypes"

const accountPaymentAmount = (state = {}, action = {}) => {
    switch (action.type) {
        case SAVE_ACCOUNT_PAYMENT_AMOUNT:
            return action.values
        case RESET_ACCOUNT_PAYMENT_AMOUNT:
            return {}
        default:
            return state
    }
}

export default accountPaymentAmount

export const getAccountPaymentAmount = state => state
