import {
    SAVE_CHECK_PAYMENT_AMOUNT,
    RESET_CHECK_PAYMENT_AMOUNT,
} from "../../../actions/localStorage/checkPaymentAmount/checkPaymentAmountActionTypes"

const checkPaymentAmount = (state = {}, action = {}) => {
    switch (action.type) {
        case SAVE_CHECK_PAYMENT_AMOUNT:
            return action.values
        case RESET_CHECK_PAYMENT_AMOUNT:
            return {}
        default:
            return state
    }
}

export default checkPaymentAmount

export const getCheckPaymentAmount = state => state
