import PropTypes from "prop-types"
import React from "react"
import "./sideMenuLayout.scss"
import sizes from "../../styles/_sizes.module.scss"

/**
 * Layout permettant d'intégrer le composant SideMenu
 * La propriété left permet de définir le positionnement du Menu
 */
const SideMenuLayout = ({left, children}) => {
    return (
        <div className="side-menu-layout" style={{left}}>
            {children}
        </div>
    )
}

SideMenuLayout.defaultProps = {
    left: sizes.spacing00,
}

SideMenuLayout.propTypes = {
    children: PropTypes.node.isRequired,
    left: PropTypes.string,
}

export default SideMenuLayout
