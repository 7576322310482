import {
    SAVE_ACCOUNT_PAYMENT_PAJE_IDENTIFICATION,
    RESET_ACCOUNT_PAYMENT_PAJE_IDENTIFICATION,
} from "../../../actions/localStorage/accountPaymentPajeIdentification/accountPaymentPajeIdentificationActionTypes"

const accountPaymentPajeIdentification = (state = {}, action = {}) => {
    switch (action.type) {
        case SAVE_ACCOUNT_PAYMENT_PAJE_IDENTIFICATION:
            return action.values
        case RESET_ACCOUNT_PAYMENT_PAJE_IDENTIFICATION:
            return {}
        default:
            return state
    }
}

export default accountPaymentPajeIdentification

export const getAccountPaymentPajeIdentification = state => state
