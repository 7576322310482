import PropTypes from "prop-types"
import React from "react"
import {Grid} from "semantic-ui-react"
import "./header.scss"
import {useIsResponsive} from "../../hooks/useIsResponsive"
import HeaderLink from "../HeaderLink/headerLink"
import HeaderMenu from "../HeaderMenu/headerMenu"

/**
 * Retourne le composant Header
 * @param {String} backgroundColor La couleur de fond du header
 * @param {Array} buttonLinks Liens avec icône, à droite dans le header
 * @param {Boolean} isConnected Informe si l'utilisateur est connecté
 * @param {Array} menuLinks Liens dans le menu popup du header
 * @param {Function} onConnectClick La méthode appelée par le bouton "Se connecter"
 * @param {String} subtitle Le sous-titre
 * @param {String} textColor La couleur du texte
 * @param {String} title Le titre
 * @param {String} username Le nom de l'utilisateur connecté
 */

const Header = ({
    backgroundColor,
    buttonLinks,
    isConnected,
    menuLinks,
    onConnectClick,
    subtitle,
    textColor,
    title,
    username,
}) => {
    const isResponsive = useIsResponsive()

    const textStyle = {color: textColor}

    return (
        <header className="header-component" style={{backgroundColor}}>
            <Grid>
                <Grid.Row className="header-component-row">
                    <Grid.Column className="header-component-column" verticalAlign="middle">
                        {isResponsive ? (
                            <h4 style={textStyle}>{title}</h4>
                        ) : (
                            <>
                                <h2 style={textStyle}>{title}</h2>
                                {subtitle && <h4 style={textStyle}>{subtitle}</h4>}
                            </>
                        )}
                    </Grid.Column>
                    <Grid.Column className="header-component-column" verticalAlign="middle">
                        <Grid.Row className="header-component-links">
                            {buttonLinks.map(linkProps => (
                                <HeaderLink
                                    {...linkProps}
                                    key={`header-link-${linkProps.label}`}
                                    textColor={textColor}
                                />
                            ))}
                            <HeaderMenu
                                isConnected={isConnected}
                                menuLinks={menuLinks}
                                onConnectClick={onConnectClick}
                                textColor={textColor}
                                username={username}
                            />
                        </Grid.Row>
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        </header>
    )
}

Header.propTypes = {
    backgroundColor: PropTypes.string.isRequired,
    buttonLinks: PropTypes.arrayOf(
        PropTypes.shape({
            icon: PropTypes.string,
            label: PropTypes.string,
            onClick: PropTypes.func,
        }),
    ),
    isConnected: PropTypes.bool,
    menuLinks: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            onClick: PropTypes.func,
        }),
    ),
    onConnectClick: PropTypes.func,
    subtitle: PropTypes.string,
    textColor: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    username: PropTypes.string,
}

Header.defaultProps = {
    buttonLinks: [],
    isConnected: false,
    menuLinks: [],
    onConnectClick: undefined,
    subtitle: undefined,
    username: undefined,
}

export default Header
