import PropTypes from "prop-types"
import React from "react"
import {Icon} from "semantic-ui-react"
import "./headerLink.scss"
import {useIsResponsive} from "../../hooks/useIsResponsive"

const HeaderLink = ({icon, label, textColor, withArrowIcon, ...props}) => {
    const isResponsive = useIsResponsive()

    return (
        <div className="header-link-container" style={{color: textColor}} {...props}>
            {icon && <Icon className={icon} />}
            {label && !(icon && isResponsive) && <span className="header-link-container-label">{label}</span>}
            {withArrowIcon && (
                <span>
                    <Icon className="fa-solid fa-caret-down caret-icon" />
                </span>
            )}
        </div>
    )
}

HeaderLink.propTypes = {
    icon: PropTypes.string,
    label: PropTypes.string,
    onClick: PropTypes.func.isRequired,
    textColor: PropTypes.string.isRequired,
    withArrowIcon: PropTypes.bool,
}

HeaderLink.defaultProps = {
    icon: undefined,
    label: undefined,
    withArrowIcon: false,
}

export default HeaderLink
